import { useEffect, useState } from "react";
import axios from "axios";
import React from "react";
import './Social.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { BASE_URL } from "../../helper";
import { cookieFetcher } from "../../helper";
import Swal from "sweetalert2";


export default function Social() {
    const [formData, setFormData] = useState({
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            facebook: {
                url: "https://www.facebook.com/"+formData.facebook,
                count: 1,
            },
            instagram: {
                url: "https://www.instagram.com/"+formData.instagram,
                count: 1,
            },
            twitter: {
                url: "https://www.twitter.com/"+formData.twitter,
                count: 1,
            },
            linkedin: {
                url: "https://www.linkedin.com/"+formData.linkedin,
                count: 1,
            },
        };

        try {
            const response = await axios.put(`${BASE_URL}/social-link`, payload,
                {
                    headers: { 'Authorization': `Bearer ${cookieFetcher('token')}` },
                    mode: "cors",
                    cache: 'default'
                }
            );
            console.log('Response:', response.data);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Catalog Status updated successfully!',
                showConfirmButton: false,
                timer: 1000,
                // timerProgressBar: true,

                showClass: {
                    popup: `
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
                },
                hideClass: {
                    popup: `
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
                },
            })
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const fetchSocialLinks = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/social-link/${cookieFetcher('username')}`, {
                    headers: { 'Authorization': `Bearer ${cookieFetcher('token')}` },
                    mode: 'cors',
                    cache: 'default'
                });
                const data = response.data;

                setFormData({
                    facebook: data.facebook.url || '',
                    instagram: data.instagram.url || '',
                    twitter: data.twitter.url || '',
                    linkedin: data.linkedin.url || '',

                });
            } catch (error) {
                console.error('Error fetching social links:', error);
            }
        };

        fetchSocialLinks();
    }, []);


    return (
        <div className='socialMediaForm'>
            <div className='socialMediaFormComponent'>
                <div className='socialMediaTitle'>Social Media Links</div>
                <p className="text-gray-600 mb-6" style={{ textAlign: 'start' }}>Connect with customers and grow your online presence.</p>
                <div
                    className='socialMediaInputs'
                    style={{ display: 'flex', gap: '15px' }}
                >
                    <div className='socialMeidaInputLeft'>
                        <div className='socialMediaInput'>
                            <div className="d-flex align-items-center" style={{ gap: '5px' }}>

                                <i className="fab fa-facebook-f text-lg text-blue-600"></i>
                                <div className='inputLabel'>Facebook</div>
                            </div>
                            <div className='inputAndBar' style={{ display: 'flex' }}>
                                <div className='bar'>facebook.com/</div>
                                <input
                                    type="url"
                                    name="facebook"
                                    value={formData.facebook}
                                    onChange={handleChange}
                                    placeholder="Enter Facebook URL"
                                    className="flex-grow p-2 border border-gray-300 rounded"
                                />
                            </div>
                        </div>
                        <div className='socialMediaInput'>
                            <div className="d-flex align-items-center" style={{ gap: '5px' }}>

                                <i className="fab fa-linkedin-in text-lg text-blue-700"></i>
                                <div className='inputLabel'>LinkedIn</div>
                            </div>
                            <div className='inputAndBar' style={{ display: 'flex' }}>
                                <div className='bar'>linkedin.com/</div>
                                <input
                                    type="url"
                                    name="linkedin"
                                    value={formData.linkedin}
                                    onChange={handleChange}
                                    placeholder="Enter LinkedIn URL"
                                    className="flex-grow p-2 border border-gray-300 rounded"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='socialMeidaInputRight'>
                        <div className='socialMediaInput'>
                            <div className="d-flex align-items-center" style={{ gap: '5px' }}>

                                <i className="fab fa-instagram text-lg text-pink-500"></i>
                                <div className='inputLabel'>Instagram</div>
                            </div>
                            <div className='inputAndBar' style={{ display: 'flex' }}>
                                <div className='bar'>instagram.com/</div>

                                <input
                                    type="url"
                                    name="instagram"
                                    value={formData.instagram}
                                    onChange={handleChange}
                                    placeholder="Enter Instagram URL"
                                    className="flex-grow p-2 border border-gray-300 rounded"
                                />
                            </div>
                        </div>
                        <div className='socialMediaInput'>
                            <div className="d-flex align-items-center" style={{ gap: '5px' }}>

                                <i className="fab fa-twitter text-lg text-blue-400"></i>
                                <div className='inputLabel'>Twitter</div>
                            </div>
                            <div className='inputAndBar' style={{ display: 'flex' }}>
                                <div className='bar'>twitter.com/</div>
                                <input
                                    type="url"
                                    name="twitter"
                                    value={formData.twitter}
                                    onChange={handleChange}
                                    placeholder="Enter Twitter URL"
                                    className="flex-grow p-2 border border-gray-300 rounded"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cancelAndSaveButton' style={{ marginTop: '15px' }}>
                    <div className='cancelButton' onClick={() => {
                        window.location.href = "/settings"
                    }}>Cancel</div>
                    <div className='saveButton' style={{ backgroundColor: '#009EF7' }} onClick={handleSubmit}>Save Changes</div>
                </div>
            </div>

        </div>
    );
}