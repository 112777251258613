import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,

} from "reactstrap";
import Swal from "sweetalert2";
import { BASE_URL, cookieFetcher } from "../../helper";
import axios from "axios";
import './Dropdown.css'

function OrdersDropdown({ status, id, updateOrders }) {
  const fetcher = async () => {
    const response = await axios.get(`${BASE_URL}/order/vendor/list/`, {
      // params: {
      //   page: currentPage,
      //   limit: limit,
      //   search: search,
      //   sort: sort,
      //   sortBy: sortBy
      // },
      headers: {
        'Authorization': `Bearer ${cookieFetcher('token')}` 
      },
      mode: "cors",
      cache: 'default'
    });
    
    console.log("updated",response);
    if (response.status === 200) {
      updateOrders(response.data);
    }
  };

  const statusChanger = async (endpoint) => {
    console.log("about to",endpoint,id);
    try {
      const res = await axios.patch(
        `${BASE_URL}/order/status/${id}`,
        { type: `${endpoint}` }, // This is your JSON payload
        {
          headers: {
            'Authorization': `Bearer ${cookieFetcher("token")}`,
            'Content-Type': 'application/json' // Optional, but good practice to specify content type
          }
        }
      );
      console.log("result",res);
      Swal.fire({
        icon: "success",
        text: res.data.message,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });

      // if (response.status === 200) {
      //   await fetcher(); // Fetch updated orders
      //   window.location.reload(); // Reload the page after successful status change
      // } else {
      //   throw new Error(`Failed to ${endpoint} the order: ${response.message}`);
      // }
    } catch (error) {
      console.error("Error changing order status:", error);
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: error.message,
      });
    }
  };

  const accept = () => {
    statusChanger("accepted");
  };

  const ship = () => {
    statusChanger("shipped");
  };

  const deliver = () => {
    statusChanger("delivered");
  };

  const cancel = () => {
    const cancelling = async (notes) => {
      try {
        const response1 = await axios.patch(
          `${BASE_URL}/order/status/${id}`,
          { type: "cancelled" }, // This is your JSON payload
          {
            headers: {
              'Authorization': `Bearer ${cookieFetcher("token")}`,
              'Content-Type': 'application/json' // Optional, but good practice to specify content type
            }
          }
        );

        if (response1.status === 200) {
          const res2 = await fetch(`${BASE_URL}/customer/order/note/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${cookieFetcher("token")}`,
            },
            body: JSON.stringify({ note: notes }),
          });

          const response2 = await res2.json();
          if (response2.status === 200) {
            await fetcher(); // Fetch updated orders
            window.location.reload(); // Reload the page after successful cancellation and note addition
          }
        } else {
          throw new Error(`Failed to cancel the order: ${response1.message}`);
        }
      } catch (error) {
        console.error("Error cancelling order:", error);
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: error.message,
        });
      }
    };

    Swal.fire({
      title: "Enter a note for the customer:",
      input: "text",
      inputLabel: "Note",
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Please enter a note!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const note = result.value;
        cancelling(note);
      }
    });
  };

  return (
    <UncontrolledDropdown >
      <DropdownToggle caret className="px-2 py-0 bg-dark orderdropdown-toggle">
        <button className="text-white text-sm" >{status}</button>
      </DropdownToggle>
      <DropdownMenu className="bg-dark orderdropdown-menu" >
        {status === "Accepted" && (
          <>
            <DropdownItem className="orderdropdown-item text-white px-1" onClick={ship}>Ship</DropdownItem>
            <DropdownItem className="orderdropdown-item text-white px-1" onClick={deliver}>Deliver</DropdownItem>
            <DropdownItem className="orderdropdown-item text-white px-1" onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
        
        {status === "Delivered" && (
          <>
            <DropdownItem className="orderdropdown-item text-white" onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
        {status === "Shipped" && (
          <>
            <DropdownItem className="orderdropdown-item text-white" onClick={deliver}>Deliver</DropdownItem>
            <DropdownItem className="orderdropdown-item text-white" onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
        {status === "Pending" && (
          <>
            <DropdownItem className="orderdropdown-item text-white hover:text-black;" onClick={accept}>Accept</DropdownItem>
            <DropdownItem className="orderdropdown-item text-white" onClick={ship}>Ship</DropdownItem>
            <DropdownItem className="orderdropdown-item text-white" onClick={deliver}>Deliver</DropdownItem>
            <DropdownItem className="orderdropdown-item text-white" onClick={cancel}>Cancel</DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default OrdersDropdown;
