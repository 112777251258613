import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { BASE_URL, cookieFetcher, loginChecker } from '../../helper'
import OrdersDropdown from '../../components/Dropdowns/OrdersDropdown'
import { useHref, useNavigate } from 'react-router-dom'
import ListLoader from '../../components/FormLoader/ListLoader'
// import ListLoader from '../../components/FormLoader/ListLoader'
import { BsSearch } from 'react-icons/bs'
import ReactPaginate from 'react-paginate';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'

function Homeorder() {
  const [orderLimit, setOrderLimit] = useState(10)
  const [orders, setOrders] = useState([])
  const [vendorId, setVendorId] = useState(cookieFetcher('vendorId'))
  const [listLoader, setListLoader] = useState(false)
  const [allOrders, setAllOrders] = useState([])
  const [filterName, setFilterName] = useState('All')
  const [allCount, setAllCount] = useState(0)
  const [pendingCount, setPendingCount] = useState(0)
  const [acceptedCount, setAcceptedCount] = useState(0)
  const [shippedCount, setShippedCount] = useState(0)
  const [dCount, setDCount] = useState(0)
  const [cCount, setCCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0); // Current page number, starting from 0
  



  
  function countStatusObjects(arr) {
    // Initialize counters for each status
    let pendingCount = 0
    let deliveredCount = 0
    let cancelCount = 0
    let shippedCount = 0
    let acceptCount = 0

    

    for (const obj of arr) {
      if (obj.pending_status) {
        pendingCount++
      }
      if (obj.deliver_status) {
        deliveredCount++
      }
      if (obj.cancel_status) {
        cancelCount++
      }
      if (obj.shipped_status) {
        // console.log(`Shipping_status${obj.shipped_status}`);
        shippedCount++
      }
      if (obj.accept_status) {
        acceptCount++
      }
    }

    setPendingCount(pendingCount)
    setDCount(deliveredCount)
    setCCount(cancelCount)
    setShippedCount(shippedCount)
    setAcceptedCount(acceptCount)
  }


 
  const navigate = useNavigate()
  const filterOrders = (status) => {
    if (status === 'cancelled') {
      let toBeReturn = allOrders.map((item) => {
        if (item.cancel_status === true) {
          return item
        }
      })
      toBeReturn = toBeReturn.filter(function (element) {
        return element !== undefined
      })
      setFilterName('Cancel')
      return toBeReturn
    }
    if (status === 'pending') {
      let toBeReturn = allOrders.map((item) => {
        if (item.pending_status === true) {
          return item
        }
      })
      toBeReturn = toBeReturn.filter(function (element) {
        return element !== undefined
      })
      console.log(toBeReturn)
      setFilterName('Pending')
      return toBeReturn
    }
    if (status === 'accepted') {
      let toBeReturn = allOrders.map((item) => {
        if (item.accept_status === true) {
          return item
        }
      })
      toBeReturn = toBeReturn.filter(function (element) {
        return element !== undefined
      })
      setFilterName('Accept')
      return toBeReturn
    }
    if (status === 'delivered') {
      let toBeReturn = allOrders.map((item) => {
        if (item.deliver_status === true) {
          return item
        }
      })
      toBeReturn = toBeReturn.filter(function (element) {
        return element !== undefined
      })
      setFilterName('Deliver')
      return toBeReturn
    }
    if (status === 'shipped') {
      let toBeReturn = allOrders.map((item) => {
        if (item.shipped_status === true) {
          return item
        }
      })
      toBeReturn = toBeReturn.filter(function (element) {
        return element !== undefined
      })
      setFilterName('Ship')
      return toBeReturn
    }
  }
  
    // Effect to fetch orders when component mounts
    // useEffect(() => {
    //   loginChecker();
    //   fetcher();
    // }, []);

    // Memoized paginated orders
  const pageCount = Math.ceil(orders.length / orderLimit);
  const paginatedOrders = orders.slice(currentPage * orderLimit, (currentPage + 1) * orderLimit);
  
   // Function to handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };




  useEffect(() => {
    loginChecker()
    const fetcher = async () => {
      setListLoader(true)
      const res = await fetch(
        `${BASE_URL}/customer/order/get_all_order/${vendorId}`
      )
      const response = await res.json()
      console.log(response.data)
      if (response.data) {
        countStatusObjects(response.data)
        setOrders(response.data)
        setAllCount(response.data.length)
        setAllOrders(response.data)
      }
      setListLoader(false)
    }
    fetcher()
  }, [vendorId])
  
    // Slice 
    const firstTenOrders = orders.slice(0, 10);
    // console.log(firstTenOrders)

  function formatDateFromMongoDB(createdAt) {
    // Convert the MongoDB date string to a JavaScript Date object
    const date = new Date(createdAt)

    // Get day, month, and year components
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Note: Month is 0-based, so we add 1
    const year = date.getFullYear()

  

    

    // Create the formatted date string in "DD/MM/YYYY" format
    const formattedDate = `${day}/${month}/${year}`

    return formattedDate
  }
  // search option by date and customer name
  const searchObjectsByField = (searchString, arrayOfObjects) => {
    const trimmedString = searchString.trim();
    console.log(`Trimmed search string: ${trimmedString}`);
    if (trimmedString.length === 0) {
      setOrders(allOrders);
    } else {
      const searchLowerCase = trimmedString.toLowerCase();
      const searchedObjects = arrayOfObjects.filter((obj) => {
        const customerName = obj.shipping_name ? obj.shipping_name.toLowerCase() : '';
         // const custId=obj. ? obj.shipping_name.toLowerCase() : '';
        const orderId = obj._id ? obj._id.toString() : ''; // Adjust this line if there's a specific date field
        const customerNameMatches = customerName.includes(searchLowerCase);
        const Order = orderId.includes(searchLowerCase);
        return customerNameMatches || Order;
      });
      setOrders(searchedObjects);
    }
  };
  


  return (
    <>
      {!listLoader ? (
        <div className='ordersMain'>
          <div className='d-flex justify-content-between'>
          <div className='omTopLeft'>
                <div className='oSearch'>
                  <div className='searchIcon'>
                  <BsSearch size={25}></BsSearch>
                    
                  </div>
                  <div className='searchText'>
                    <input
                       onChange={(e) => {
                        searchObjectsByField(e.target.value,allOrders)
                      }}
                      type='text'
                      placeholder='Search Product'
                    ></input>
                  </div>
                </div>
              </div>
            <div className='orderFilterList'>
              <div
                className={
                  filterName !== 'All'
                    ? 'orderFilterListButton'
                    : 'orderListButtonActive'
                }
                onClick={() => {
                  setFilterName('All')
                  setOrders(allOrders)
                }}
              >
                <span>
                  All
                </span>

                <div className='countShower'>{allCount}</div>
              </div>
              <div
                className={
                  filterName !== 'Pending'
                    ? 'orderFilterListButton'
                    : 'orderListButtonActive'
                }
                onClick={() => {
                  const array = filterOrders('pending')
                  setOrders(array)
                }}
              >
                Pending
                <div className='countShower'>{pendingCount}</div>
              </div>
              <div
                className={
                  filterName !== 'Accept'
                    ? 'orderFilterListButton'
                    : 'orderListButtonActive'
                }
                onClick={() => {
                  const array = filterOrders('accepted')
                  setOrders(array)
                }}
              >
                Accepted
                <div className='countShower'>{acceptedCount}</div>
              </div>
              <div
                className={
                  filterName !== 'Ship'
                    ? 'orderFilterListButton'
                    : 'orderListButtonActive'
                }
                onClick={() => {
                  const array = filterOrders('shipped')
                  setOrders(array)
                }}
              >
                Shipped
                <div className='countShower'>{shippedCount} </div>
              </div>
              <div
                className={
                  filterName !== 'Deliver'
                    ? 'orderFilterListButton'
                    : 'orderListButtonActive'
                }
                onClick={() => {
                  const array = filterOrders('delivered')
                  setOrders(array)
                }}
              >
                Delivered
                <div className='countShower'>{dCount}</div>
              </div>
              <div
                className={
                  filterName !== 'Cancel'
                    ? 'orderFilterListButton'
                    : 'orderListButtonActive'
                }
                onClick={() => {
                  const array = filterOrders('cancelled')
                  setOrders(array)
                
                }}
              >
                Cancelled
                <div className='countShower'>{cCount}</div>
              </div>
            </div>


            <div className='omTopRight'>
              {/* <UncontrolledDropdown>
                <DropdownToggle className='filetrBtn d-flex align-items-center'>

                  <span class="material-symbols-outlined">
                    filter_alt
                  </span>
                  Filter
                </DropdownToggle>
                <DropdownMenu className="filter_dropdown">
                  <DropdownItem

                  >
                    Sort By Date
                  </DropdownItem>
                  <DropdownItem

                  >
                    Sort By Name
                  </DropdownItem>
                  <DropdownItem

                  >
                    Sort By Price
                  </DropdownItem>
                  <DropdownItem

                  >
                    Sort By Status
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </div>
          </div>
          <Table
            style={{
              fontFamily: 'Poppins", sans-serif',
              marginTop: '20px',
            }}
            responsive
          >
            <thead>

           

              <tr>
                <th>#</th>
                <th>ORDER ID</th>
                <th>DATE</th>
                <th>CUSTOMER NAME</th>
                <th>COUNT</th>

                <th>TOTAL</th>
                {/* <th>STATUS</th> */}
                <th>ACTION</th>
              </tr>
            </thead>
            <tbody>
  {paginatedOrders.slice(0, 10).map((item, index) => (
    <tr key={index}>
      <th scope='row'>{(currentPage - 0) * orderLimit + index + 1}</th>
      <td
        className='darkData darkDataClick'
        onClick={() => {
          navigate(`/orders/${item._id}`)
        }}
        style={{ cursor: 'pointer' }}
      >
        {item?._id}
      </td>
      <td className='darkData'>
        {formatDateFromMongoDB(item.createdAt)}
      </td>
      <td className='darkData'>{item?.shipping_name}</td>
      <td className='lightData'>{item?.product.length}</td>
      <td className='lightData'>{item?.grand_total}</td>
      {/* Render action dropdown based on item status */}
      <td className='coloredData'>
        {item.accept_status && (
          <OrdersDropdown
            status={'Accepted'}
            id={item._id}
            setOrders={setOrders}
          />
        )}
        {item.cancel_status && (
          <OrdersDropdown
            status={'Cancelled'}
            id={item._id}
            setOrders={setOrders}
          />
        )}
        {item.deliver_status && (
          <OrdersDropdown
            status={'Delivered'}
            id={item._id}
            setOrders={setOrders}
          />
        )}
        {item.shipped_status && (
          <OrdersDropdown
            status={'Shipped'}
            id={item._id}
            setOrders={setOrders}
          />
        )}
        {item.pending_status && (
          <OrdersDropdown
            status={'Pending'}
            id={item._id}
            setOrders={setOrders}
          />
        )}
      </td>
    </tr>
  ))}
</tbody>
            {/* <tbody>
              
              {orders.map((item, index) => {
                return (
                  
                  <tr>
                    <th scope='row'>{index + 1}</th> */}
                    {/* <td>{item.orderId}</td> */}
                    {/* <td
                      className='darkData darkDataClick'
                      onClick={() => {
                        navigate(`/orders/${item._id}`)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {item?._id}
                    </td>
                    <td className='darkData'>
                      {formatDateFromMongoDB(item.createdAt)}
                    </td>
                    <td className='darkData'>{item?.shipping_name}</td>
                    <td className='lightData'>{item?.product.length}</td>

                    <td className='lightData'>{item?.grand_total}</td> */}

                    
                    {/* {item.accept_status && (
                  <td style={{ color: 'green' }}>Accepted</td>
                )}
                {item.cancel_status && (
                  <td style={{ color: 'red' }}>Cancelled</td>
                )}
                {item.deliver_status && (
                  <td style={{ color: 'blue' }}>Delivered</td>
                )}
                {item.shipped_status && (
                  <td style={{ color: 'gray' }}>On the Way</td>
                )}
                {item.pending_status && (
                  <td style={{ color: 'yellow' }}>Pending</td>
                )} */}
                    {/* {item.accept_status && (
                      <td className='coloredData'>
                        <OrdersDropdown className="sdfds"
                          status={'Accepted'}
                          id={item._id}
                          setOrders={setOrders}
                        ></OrdersDropdown>
                      </td>
                    )}
                    {item.cancel_status && (
                      <td className='coloredData'>
                        <OrdersDropdown
                          status={'Cancelled'}
                          id={item._id}
                          setOrders={setOrders}
                        ></OrdersDropdown>
                      </td>
                    )}
                    {item.deliver_status && (
                      <td className='coloredData'>
                        <OrdersDropdown
                          status={'Delivered'}
                          id={item._id}
                          setOrders={setOrders}
                        ></OrdersDropdown>
                      </td>
                    )}
                    {item.shipped_status && (
                      <td className='coloredData'>
                        <OrdersDropdown
                          status={'Shipped'}
                          id={item._id}
                          setOrders={setOrders}
                        ></OrdersDropdown>
                      </td>
                    )}
                    {item.pending_status && (
                      <td className='coloredData'>
                        <OrdersDropdown
                          status={'Pending'}
                          id={item._id}
                          setOrders={setOrders}
                        ></OrdersDropdown>
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody> */}
          </Table>
          {/* <ReactPaginate
  previousLabel={'< Previous'}
  nextLabel={'Next >'}
  breakLabel={'..........'}
  pageCount={pageCount}
  marginPagesDisplayed={2}
  pageRangeDisplayed={3}
  onPageChange={handlePageChange}
  containerClassName={'pagination flex justify-center mt-4'}
  pageClassName={'mx-1'}
  pageLinkClassName={'py-2 px-4 border rounded text-blue-500 hover:bg-gray-200'}
  activeClassName={'bg-blue-500 text-white'}
  previousLinkClassName={'py-2 px-4 border rounded mr-2 text-blue-500 hover:bg-gray-200'}
  nextLinkClassName={'py-2 px-4 border rounded ml-2 text-blue-500 hover:bg-gray-200'}
  disabledClassName={'text-gray-400 cursor-not-allowed'}
/> */}


        </div>
      ) : (
        // <ListLoader></ListLoader>
        <ListLoader rows={10} cols={7}></ListLoader>
      )}
    </>
  )
}

export default Homeorder
