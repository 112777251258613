import { BASE_URL, cookieFetcher } from '../../helper'
import Swal from 'sweetalert2'
import 'animate.css/animate.min.css';
import axios from 'axios';

const profileEditor = async (
  profileImage,
  username,
  phoneNumber,
  businessName,
  firstName,
  lastName,
  email,
  addLine1,
  addLine2,
  city,
  state,
  country,
  zip,
  categories,
  selectedTitle,
  about


) => {

  
  const selectedIds = categories
    .filter(cat => selectedTitle.includes(cat.title))
    .map(cat => cat._id);
  console.log("selectedIds", selectedIds);

  const fullName = `${firstName} ${lastName}`.trim();
  console.log("fullname", fullName);

  const formdata = new FormData()

  //formdata.append('username', username)
  //formdata.append('phone', phoneNumber)
  formdata.append('business_name', businessName)
  selectedIds.forEach((id, index) => formdata.append(`shop_category_id[${index}]`, id));
  // formdata.append("shop_category_id", selectedIds);
  //formdata.append("acc_category", selectedTitle);
  formdata.append('username_lowercase', username.toLowerCase())
  formdata.append('photo', profileImage)
  formdata.append('full_name', fullName)
  formdata.append('email', email)
  formdata.append('address_line1', addLine1)
  formdata.append('address_line2', addLine2)
  formdata.append('country', country)
  formdata.append('city', city)
  formdata.append('state', state)
  formdata.append('zip', zip)
  formdata.append('about',about)

  // Log formdata as JSON
  console.log("formdata contents:");
  formdata.forEach((value, key) => console.log(`${key}: ${value}`));

  try {
    const response = await axios.patch(`${BASE_URL}/profile/shop`, formdata, {
      headers: {
        'Authorization':
          `Bearer ${cookieFetcher('token')}`,
      },
      // mode: "cors",
      // cache: "default",
      
    })
    console.log("response",response);
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
              title: 'Success',
        text: 'Changes updated successfully!',
        showConfirmButton:false,
        timer: 1000,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Something has gone wrong',
        text: 'Failed to update values.',
      });
    }
  } catch (error) {
    if (error.response) {
      console.log("Error response:", error.response.data);
    } else {
      console.log("Error:", error.message);
    }
  }
  
  
}
// }
export default profileEditor
