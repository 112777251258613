import React, { useState, useRef } from 'react';
import './SignUp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import Warning from '../../components/Warning/Warning';
import { Helmet } from 'react-helmet';
import { BASE_URL, checkPresence, cookieFetcher, loginChecker } from '../../helper';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

// const getLastTenDigits = (mobileNumber) => {
//   return mobileNumber.slice(-10);
// };

function SignUp() {
  const inputRef = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bName, setBName] = useState("");
  const [uName, setUName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [shopCategoryIds, setShopCategoryIds] = useState([]);
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uNameWarning, setUNameWarning] = useState(false);
  const [bNameWarning, setBNameWarning] = useState(false);
  const [shopCategories, setShopCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const token = cookieFetcher("refreshToken");
  const location = useLocation();
  let { mobile } = location.state || {};
  // if (mobile) {
  //   mobile = "+" + mobile;
  // }
  //console.log("phone",mobile);

  

  useEffect(() => {
    
    const fetchShopCategories = async () => {
      try {
        console.log("cookie phone",cookieFetcher('phone'));
        console.log("phone from login",mobile);
        const response = await fetch(`${BASE_URL}/shop_category/shop`);
        const responseProfile = await fetch(
          `${BASE_URL}/vendor/visitor/${cookieFetcher("username")}`
        );
        const profileInfo = await responseProfile.json();
        console.log("profile ", profileInfo.data);
        //loginChecker();
        const presence=await checkPresence(mobile);
        if (presence){
          window.location.href = "/";
        }
        const data = await response.json();
        setShopCategories(data);
        if (mobile) {
          setMobile(mobile);
        }
        else {
          setMobile(profileInfo.data.phone);
        }
        console.log("shop cat", data);
      } catch (error) {
        console.error("Error fetching shop categories:", error);
      }
    };

    fetchShopCategories();
  }, []);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onPhotoChange = (e) => {
    if (e.target && e.target.files) {
      setPhoto(e.target.files[0]);
    }
  };

  const onDeletePhoto = () => {
    setPhoto(null);
  };

  const handleCategoryChange = (e) => {
    const options = Array.from(e.target.options);
    const selectedValues = options
      .filter((option) => option.selected)
      .map((option) => option.value);
    setShopCategoryIds(selectedValues);
  };

  const handleCategorySelect = (category) => {
    setShopCategoryIds([...shopCategoryIds, category._id]);
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleRemoveCategory = (categoryId) => {
    setShopCategoryIds(shopCategoryIds.filter((id) => id !== categoryId));
  };

  const profileSubmitter = async () => {
    const values = {
      phone: Mobile,
      username: uName,
      shop_category_id: shopCategoryIds,
      business_name: bName,
      photo,
      first_name: firstName,
      surname_name: lastName,
    };
    console.log("hi", values);

    if (bName.trim() === "") {
      setBNameWarning(true);
    }
    if (uName.trim() === "") {
      setUNameWarning(true);
    }
    if (bName.trim() === "" || uName.trim() === "") {
      return;
    }


    console.log("submission",values);
    const formdata = new FormData();
    formdata.append("phone", Mobile);
    formdata.append("username", uName);
    shopCategoryIds.forEach((id,index) => formdata.append(`shop_category_id[${index}]`, id));
    formdata.append("business_name", bName);
    formdata.append("photo", photo);
    formdata.append("first_name", firstName);
    formdata.append("surname_name", lastName);

    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/auth/shop/signup`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formdata,
        }
      );
      

      const res = await response.json();
      console.log("response after loggin ",res);
      setLoading(false);
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Business Profile Created',
          text: 'Sign in again to continue',
          confirmButtonText: 'OK',
          // Optional: You can add additional configuration such as custom buttons or actions
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect to the sign-in page or handle other logic here
            window.location.href = "/home";
          }
      })
     } else {
        Swal.fire({
          icon: "error",
          title: "Vendor profile already exists!",
          text: `${res.err}`,
        });
        console.error(`Error: ${response.status}`, res);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Something went wrong!",
        text: `${error.message}`,
      });
      console.error("Error:", error);
    }
  };

  return (
    <div className="signupPage bg-gray-100 min-h-screen flex items-center justify-center">
      <Helmet title="Sign Up - Create your business account" />
      <div className="signUpForm bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <div className="signUpFormTitle">
          <h4 className="text-xl font-semibold">
            Create Your Business Profile
          </h4>
        </div>
        <div className="signUpFormContent space-y-2">
          <div className="signUpPhoto flex justify-center">
            <div className="signUpPhotoHolder relative bg-white border border-gray-300 shadow-sm rounded-xl p-2 flex flex-col items-center">
              <div className="relative">
                <img
                  src={
                    photo
                      ? URL.createObjectURL(photo)
                      : "https://ipfs.filebase.io/ipfs/QmfMyWSPdhgPmF8b3oMh6aQZrnJKT5VREFL2dHAC6U2vgV"
                  }
                  className="h-24 w-24 rounded-full object-cover cursor-pointer"
                  onClick={onButtonClick}
                  alt="Profile"
                />
                <div className="absolute bottom-0 left-2 right-2 flex justify-center items-center space-x-12 opacity-70">
                  <div className="signUpdeleteIcon">
                    <FontAwesomeIcon
                      icon={faTrash}
                      color="red"
                      style={{
                        background: "black",
                        padding: "5px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={onDeletePhoto}
                    />
                  </div>

                  <div className="signUpEditIcon">
                    <FontAwesomeIcon
                      icon={faEdit}
                      color="#009ef7"
                      style={{
                        background: "black",
                        padding: "5px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      onClick={onButtonClick}
                    />
                    <input
                      type="file"
                      ref={inputRef}
                      style={{ display: "none" }}
                      onChange={onPhotoChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="signUpFormContentLine flex space-x-1">
            <div className="signUpInputs ">
              <label className="block mb-1">First Name</label>
              <input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="signUpInputs ">
              <label className="block mb-1">Last Name</label>
              <input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="signUpInputs ">
              <label className="block mb-1">Mobile Number</label>
              <input
                value={Mobile}
                readOnly
                placeholder="Mobile Number"
                className="w-full p-2 border rounded bg-gray-300"
              />
            </div>
          </div>
          <div className="signUpFormContentLine flex space-x-1">
            <div className="signUpInputs">
              <label className="block mb-1">Business Name</label>
              <input
                value={bName}
                onChange={(e) => {
                  if (bName.trim().length > 0) {
                    setBNameWarning(false);
                  }
                  setBName(e.target.value);
                }}
                placeholder="Business Name"
                className="w-full p-2 border rounded"
              />
              {bNameWarning && <Warning type="businessName" />}
            </div>
            <div className="signUpInputs">
              <label className="block mb-1">Username</label>
              <input
                value={uName}
                onChange={(e) => {
                  if (uName.trim().length > 0) {
                    setUNameWarning(false);
                  }
                  setUName(e.target.value);
                }}
                placeholder="Username"
                className="w-full p-2 border rounded"
              />
              {uNameWarning && <Warning type="username" />}
            </div>
          </div>
          <div className="">
  <div className="signUpFormContentLine flex space-x-4">
    <div className="signUpInputs flex-1">
      <label className="block mb-1">Select Shop Categories</label>
      <div className="relative">
        <div className="relative">
          <button
            onClick={handleDropdownToggle}
            className="w-full p-1 border rounded flex justify-between items-center"
          >
            {shopCategoryIds.length > 0
              ? shopCategories
                  .filter((cat) => shopCategoryIds.includes(cat._id))
                  .map((cat) => cat.title)
                  .join(", ")
              : ""}
            <span className="ml-2">&#9662;</span>
          </button>
          {isDropdownOpen && (
            <div className="absolute bg-white border border-gray-300 rounded mt-1 w-full z-10  overflow-y-auto" style={{maxHeight:'120px'}}>
              {shopCategories.map((category) => (
                <div
                  key={category._id}
                  onClick={() => handleCategorySelect(category)}
                  className="p-1 hover:bg-gray-200 cursor-pointer"
                >
                  {category.title}
                </div>
              ))}
            </div>
          )}
        </div>
        { (
          <div className="mt-2">
            {shopCategoryIds.map((id) => {
              const category = shopCategories.find(
                (cat) => cat._id === id
              );
              return (
                <span
                  key={id}
                  className="inline-block mr-2 my-1 px-2 py-1 bg-gray-200 text-gray-800 rounded"
                >
                  {category.title}
                  <span
                    onClick={() => handleRemoveCategory(id)}
                    className="ml-2 cursor-pointer text-red-500"
                  >
                    &#10005;
                  </span>
                </span>
              );
            })}
          </div>
        )}
      </div>
    </div>
  </div>
</div>

          <div className="signUpSubmit mt-4">
            <button
              className={`w-full py-2 rounded text-white ${
                loading ? "bg-gray-400" : "bg-blue-500"
              }`}
              onClick={profileSubmitter}
              disabled={loading}
            >
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
