import withNavigation from "../../withNavigation";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
//import { checkAuthed, handleDelete } from "../../../utils";
import { BsJustify, BsSearch } from "react-icons/bs";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL, cookieFetcher, loginChecker } from "../../helper";
//import { API_URL } from "../../../utils";
import _ from "lodash";
//import Switch from "@material-ui/core/Switch";
//import { APP_URL, VENDOR_PANEL_URL } from "../../../utils";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar/Sidebar";
import OrdersHeader from "../Orders/OrdersHeader";
import Footer from "../../components/Footer/Footer";
import { Switch } from "@mui/material";
import ProductsDropDown from "../../components/Dropdowns/ProductsDropDown";
import "./Products.css";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
// const API_URL = "https://betabackend.lofaz.in/api/";

const ViewProfileButton = ({
  viewUrl,
  handleEdit,
  handleDelete,
  vendorDetailsUrl,
  handleGuestLogin,
}) => (
  <div className="container">
    <div className="d-flex flex-row justify-center" style={{ gap: "7px" }}>
      <a
        className="btn btn-dark p-2"
        href={viewUrl}
        target="_blank"
        rel="noreferrer"
        title="View Store"
      >
        <span class="material-symbols-outlined font-bold">open_in_new</span>
      </a>

      <Link
        className="btn btn-dark p-2"
        to={vendorDetailsUrl}
        target="_blank"
        rel="noreferrer"
        title="View Details"
      >
        <i className="fas fa-eye" />
      </Link>
      <button
        className="btn btn-dark p-2"
        title="Guest Login"
        onClick={handleGuestLogin}
      >
        <i className="fas fa-sign-in-alt" />
      </button>
      <button
        className="btn btn-dark p-2"
        onClick={handleEdit}
        title="Add Note"
      >
        <i className="fas fa-sticky-note" />
      </button>
      <button className="btn btn-dark p-2" onClick={handleEdit} title="Edit">
        <i className="fas fa-pen" />
      </button>
      <button
        className="btn btn-danger p-2"
        onClick={handleDelete}
        title="Delete"
      >
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);

export const getProductTableConfig = async (
  currentPage,
  limit,
  search = "",
  sort = "createdAt",
  sortBy = -1
) => {
  try {
    const response = await axios.get(`${BASE_URL}/product/shop/v1`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy,
      },
      headers: {
        'Authorization': `Bearer ${cookieFetcher('token')}`,
      },
      mode: "cors",
      cache: "default",
    });
    loginChecker()
    const data = response.data;

    console.log("Data fetched correctly", data);
    return data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    return { data: [], total_shop: 0 };
  }
};

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: "",
      sort: -1,
      sortBy: "createdAt",
      featuredOnly: false,
      sidebar: true,
      shouldRedirect: false,

      // searchBy: 'title', // Default searchBy field set to 'title' (Products Name)
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  async componentDidMount() {
   loginChecker()
    const { currentPage, limit, search, sort, sortBy } = this.getURLParams();
    window.history.pushState(null, '', '?');
    this.setState({ currentPage, limit, search, sort, sortBy }, () => {
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  }

  fetchData = async (
    currentPage,
    limit,
    search = "",
    sort = 1,
    sortBy = "createdAt"
  ) => {
    try {
      const data = await getProductTableConfig(
        currentPage,
        limit,
        search,
        sortBy,
        sort
      );
      console.log("fetch", data);
      if (data) {
        this.setState({
          data: this.state.featuredOnly
            ? data.data.filter(
              (item) => item.activate === this.state.featuredOnly
            )
            : data.data,
          totalRows: data.total_shop,
          fetched: true,
        });
      }
      console.log("data", this.state.data);
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  };

  updateURLParams = () => {
    const { currentPage, limit, search, sort, sortBy } = this.state;
    const queryParams = new URLSearchParams({
      page: currentPage,
      limit,
      search,
      sort,
      sortBy,
    }).toString();
    //console.log("ss", queryParams);
    this.props.handleRedirect(`?${queryParams}`);
  };

  getURLParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log("ss", searchParams);
    const currentPage = parseInt(searchParams.get("page")) || 1;
    const limit = parseInt(searchParams.get("limit")) || 10;
    const search = searchParams.get("search") || "";
    const sort = parseInt(searchParams.get("sortBy")) || -1;
    const sortBy = searchParams.get("sort") || "createdAt";

    return { currentPage, limit, search, sort, sortBy };
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("on page change:", currentPage, limit, search, sort, sortBy);
      this.fetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  handleRedirect = () => {
    this.setState({ shouldRedirect: true });
  };

  handleSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      console.log("fetch params", currentPage, limit, search, sort, sortBy);
      this.debouncedFetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  toggleFeaturedFilter = () => {
    this.setState((prevState) => ({
      featuredOnly: !prevState.featuredOnly,
    }));
  };
  toggleSidebar = () => {
    this.setState((prevState) => ({
      sidebar: !prevState.sidebar,
    }));
  };

  handleToggle = async (data,id) => {
    const newStatus = !data.status;
    console.log("new",newStatus);
    console.log("id",id);
    try {
      // Update the status state
     
  
      this.setState(
        {
          status: newStatus,
        },
        async () => {
          // Make the API call after updating the state
          const response = await axios.patch(
            `${BASE_URL}/product/shop/status/${id}`,
            {
              status: newStatus,
            },
            {
              headers: {
                'Authorization': `Bearer ${cookieFetcher('token')}`,
              },
            }
          );
          console.log("res",response);
          if (response.status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Product Status updated successfully!',
              showConfirmButton: false,
              timer: 1000,
              // timerProgressBar: true,
              
              showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
              },
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                this.componentDidMount()
              }
            });
          }
          
          
        }
      );
     
    } catch (error) {
      console.log("error updating status", error);
      this.setState({status: !newStatus})
    }
  };

  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sort, sortBy } = this.state;
      this.fetchData(currentPage, limit, search, sort, sortBy);
      this.updateURLParams();
    });
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);

    this.setState(
      (prevState) => {
        const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
        return { sort: column, sortBy: newSort, currentPage: 1 };
      },
      () => {
        console.log(
          `Sorting by ${this.state.sort} in order ${this.state.sortBy}`
        );
        this.fetchData(
          this.state.currentPage,
          this.state.limit,
          this.state.search,
          this.state.sortBy,
          this.state.sort
        );
        this.updateURLParams();
      }
    );
  };

  columns = [
    {
      name: "#",
      cell: (row, index) => (
        <p className="text-center">
          {(this.state.currentPage - 1) * this.state.limit + index + 1}
        </p>
      ),
      width: "60px",
    },
    {
      name: "IMG",
      cell: (row) => (
        <div
          style={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
           <a href={`/products/edit/${row._id}`} className="no-underline hover:underline text-black" >
          <img
            src={
              row && row.featured_img
                ? row.featured_img
                : "https://lofazweb.s3.ap-south-1.amazonaws.com/default/logo.png"
            }
            style={{
              width: "50px",
              marginRight: "10px",
              // transition: "transform 0.8s ease-in-out", // Smooth transition
            }}
          // className="zoom-image"
          // alt={row && row.title ? row.title : "Product image"}
          />
          </a>
        </div>
      ),
      width: "100px", // Adjust width as needed

    },

    {
      name: "PRODUCT NAME",
      cell: (row) => (
        <div
          style={{ display: "flex", alignItems: "center", textAlign: "start" }}
        >
          <a href={`/products/edit/${row._id}`} className="no-underline hover:underline text-black" >
          <p className="font-semibold" style={{ margin: 0 }}>
            {row && row.title ? row.title : ""}
          </p>
          </a>
        </div>
      ),
      width: "120px", // Adjust width as needed
      sortable: true,
      sortField: "title",
    },
    {
      name: "CATEGORY",
      cell: (data) => {
        //console.log(data);
        return (
          <div style={{ display: "flex", alignItems: "center", textAlign: "start" }}>
            <p className="font-semibold" style={{ margin: 0 }}>
            {data.category ? data.category.title : ""}
          </p>
          </div>
        );
      },
      width: "150px",
      sortField: "category.title",
      sortable: true,
    },
    {
      name: "STOCK",
      cell: (data) => {
        //console.log(data);
        return (
          <p className="font-semibold" style={{margin:0}}>
            {data && data.stock ? data.stock : "0"}
          </p>
        );
      },
      width:"70px",
      sortField: "stock",
      sortable: true,
    },
    {
      name: "PRICE(INR)",
      cell: (data) => (
        <div>
          {/* Conditionally render MRP if it exists */}
          {data.mrp && (
            <p className="line-through text-gray-500 font-semibold mb-0">
              ₹{data.mrp}
            </p>
          )}
          {/* Display sales price */}
          <p className="mb-0 font-semibold">
            ₹{data.sales_price ? data.sales_price : ""}
          </p>
        </div>
      ),
      width:"120px",
      sortField: "sales_price",
      sortable: true,
    },
    // {
    //   name: "DISCOUNT",
    //   cell: (data) => {
    //     const percentageOff = ((data.mrp - data.sales_price) / data.mrp) * 100;
    //     //console.log(data);
    //     return (
    //       <p className="font-semibold text-center text-blue-500">
    //         {percentageOff.toFixed(2)}% off
    //       </p>
    //     );
    //   },
    //   width: "100px",
    //   // sortField: "category.title",
    //   // sortable: true,
    // },
    {
      name: "DATE CREATED",
      selector: (row) => {
        const date = new Date(row.createdAt);
        const options = {
          weekday: "short",
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        };
        const formattedDate = date
          .toLocaleString("en-US", options)
          .replace(",", "");
        return <p className="font-semibold" style={{margin:0}}>{formattedDate}</p>;
      },
      //onClick: () => this.handleSortClick('createdAt'),
      sortField: "createdAt",
      sortable: true,
      width: "180px",
      // sortFunction: {this.handleSortClick('createdAt')}}
    },
    {
      name: "STATUS",
      cell: (data) => {
        //console.log(data);
        return (
          <div className="d-flex align-items-start justify-center" >
            
            <Switch
              checked={data.status}
              onChange={() => this.handleToggle(data,data._id)}
              color="primary"
              
            />
           {
            data.status?  (<p className=" text-green-700 text-center ml-2" style={{marginTop:'7px'}}>
            Active
          </p>)
          :(<p className="text-red-700 text-center ml-2" style={{marginTop:'7px'}}>
            Inactive
          </p>)
           }

            
          </div>
        );
      },
      // sortField: "active",
      // sortable: true,
      // width:"70px"
    },
    {
      name: "ACTIONS",
      cell: (row) => (
        <ProductsDropDown
          product={row} // Pass the product data
          fetchStatus={this.state.fetched} // or another relevant state
          setFetchStatus={(status) => this.setState({ fetched: status })}
        />
      ),
      // width:"60px"
    },
    // {
    //   name: "STATUS",
    //   selector: (row) => {
    //     const date = new Date(row.updatedAt);
    //     const options = {
    //       weekday: "short",
    //       month: "short",
    //       day: "2-digit",
    //       year: "numeric",
    //       hour: "numeric",
    //       minute: "2-digit",
    //       hour12: true,
    //     };
    //     const formattedDate = date
    //       .toLocaleString("en-US", options)
    //       .replace(",", "");
    //     return formattedDate;
    //   },
    //   minWidth: "200px",
    //   //onClick: () => this.handleSortClick('createdAt'),
    //   sortField: "updatedAt",
    //   sortable: true,
    //   // sortFunction: {this.handleSortClick('createdAt')}}
    //   minWidth: "200px",
    // },
    // {
    //   name: "View Profile",
    //   cell: (row) => (
    //     <ViewProfileButton
    //       // viewUrl={APP_URL + row.username}
    //       vendorDetailsUrl={`/vendor/${row._id}`}
    //       handleDelete={() => handleDelete(row._id, "Vendor")}
    //       handleGuestLogin={() => {
    //         const date = new Date();
    //         const url =
    //           VENDOR_PANEL_URL +
    //           "login?guest=" +
    //           encodeURIComponent(
    //             CryptoJS.AES.encrypt(
    //               row && row.phone,
    //               process.env.REACT_APP_GUEST_SECRET +
    //                 String(Number(date.getSeconds()) > 30 ? 0 : 1) +
    //                 date.getMinutes() +
    //                 date.getHours() +
    //                 date.getDay() +
    //                 date.getMonth() +
    //                 date.getFullYear()
    //             ).toString()
    //           );
    //         window.open(url, "_blank");
    //       }}
    //     />
    //   ),
    //   minWidth: "300px",
    // },
    // {
    //   name: "Actions",
    //   cell: (row) => {
    //     const handleToggle = () => {
    //       const newActivateState = !row?.activate;
    //       this.updateActivate(newActivateState, row?._id);
    //     };
    //     //not using handleToggle since featured bhi toggle hota dono ka same API field hai
    //     return (
    //       <div>
    //         <Toggle
    //           defaultChecked={true}
    //           color="primary"
    //           name="activate-switch"
    //           inputProps={{ "aria-label": "activate switch" }}
    //         />
    //         {/* {row?.activate ? <span>Inactive</span> : <span>Active</span>} */}
    //       </div>
    //     );
    //   },
    //   minWidth: "200px",
    // },
  ];

  // handleStatusChange = async (id, newStatus) => {
  //   // Make API call to update the status on the server
  //   try {
  //     await axios.patch(`${API_URL}catalog/${id}`, { active: newStatus }, {
  //       headers: authHeader(),
  //     });
  //     const updatedData = this.state.data.map(item =>
  //       item._id === id ? { ...item, active: newStatus } : item
  //     );
  //     this.setState({ data: updatedData });
  //   } catch (error) {
  //     console.error("Error updating catalog status:", error);
  //   }
  // };
  customStyles = {
    headCells: {
      style: {
        display: "flex",
        justifyContent: "center", // Center align header text horizontally
        alignItems: "center", // Center align header text vertically
        textAlign: "center", // Ensure text is centered
        padding: "0", // Optional: Remove padding if necessary
        height: "100%", // Ensure full height for centering
      },
    },
    cells: {
      style: {
        display: "flex",
        justifyContent: "center", // Center align cell text horizontally
        alignItems: "center", // Center align cell text vertically
        textAlign: "center", // Ensure text is centered
        padding: "5px", // Adjust padding if necessary
      },
    },
  };

  render() {
    const { data, fetched, currentPage, search, limit, totalRows } = this.state;
    const { history } = this.props;
    // // Apply the filtering here based on featuredOnly state
    // const filteredData = this.state.featuredOnly ? data.filter(item => item.activate===this.state.featuredOnly) : data;
    // console.log("filter",filteredData);
    console.log("order", data);

    // Conditional rendering based on whether data has been fetched
    // if (!fetched) {
    //   return (
    //     <>
    //       {/* Replace with your actual loading component */}
    //       <div class="d-flex align-items-center justify-content-center vh-100">
    //         <div class="spinner-border text-body" role="status">
    //           <span class="sr-only"></span>
    //         </div>
    //       </div>
    //     </>
    //   );
    // }

    return (
      <>
        <div className="productsPage d-flex">
          <Helmet
            title="Products"
            link={{
              rel: "icon",
              type: "image/png",
              href: cookieFetcher("photo"),
            }}
          ></Helmet>
          <Sidebar
            sidebar={this.state.sidebar}
            page={"products"}
            product={true}
          ></Sidebar>
          <div className="productsMain" style={{ width: "100vw" }}>
            <OrdersHeader
              sidebar={this.state.sidebar}
              setSidebar={this.toggleSidebar}
            ></OrdersHeader>
            <div className="productsMainTop">
              <div className="productsMainSec">
                <div className="productsMainSecButton d-flex justify-content-between">
                  <div className="onTopLeft d-flex align-items-center justify-content-center m-4">
                    <div
                      className="onSearch d-flex align-items-center"
                      style={{ gap: "5px" }}
                    >
                      <div className="search-container flex-grow" style={{width:"400px"}}>
                        <input
                          type="text"
                          className="search-input"
                          aria-label="Search Product"
                          placeholder="Search by Product/Category"
                          value={search}
                          onChange={this.handleSearchChange}
                        />
                        <BsSearch className="search-icon" size={20} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="onTopRight d-flex align-items-center justify-content-center m-4"
                    style={{ gap: "5px" }}
                  >
                    <UncontrolledDropdown>
                      <DropdownToggle className="filetrBtn d-flex align-items-center btn">
                        {/* <TiFilter
                      style={{
                        marginTop: '0px',
                        background: 'rgba(0,0,0,0)',
                        color: 'white',
                        fontSize: '23px',
                        
                        
                      }}
                      
                    ></TiFilter> */}
                        <span class="material-symbols-outlined">
                          filter_alt
                        </span>
                        Filter
                      </DropdownToggle>
                      <DropdownMenu className="filter_dropdown">
                      <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: -1,
                                sort: "status",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort by Active Catalogs
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "status",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort by Hidden Catalogs
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "createdAt",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Date (Oldest date)
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: -1,
                                sort: "createdAt",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Date (Newest date)
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: false,
                                // nameSorted: true,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "title",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Product Name(A-Z)
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: false,
                                // nameSorted: true,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: -1,
                                sort: "title",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Product Name(Z-A)
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: -1,
                                sort: "sales_price",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Price (high to low)
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            this.setState(
                              {
                                // dateSorted: true,
                                // nameSorted: false,
                                // priceSorted: false,
                                // statusSorted: false,
                                sortBy: 1,
                                sort: "sales_price",
                              },
                              () =>
                                this.fetchData(
                                  this.state.currentPage,
                                  this.state.limit,
                                  this.state.search,
                                  this.state.sortBy,
                                  this.state.sort
                                )
                            );
                          }}
                        >
                          Sort By Price (low to high)
                        </DropdownItem>
                        
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <div
                      className="addProductButton p-2 rounded-lg"
                      style={{
                        background: "#009ef7",
                        border: "none",
                        // paddingLeft: "20px",
                        // paddingRight: "20px",
                        // paddingTop: "10px",
                        // paddingBottom: "10px",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "14px",
                        //borderRadius: "10px",
                      }}
                    >
                      <button
                        style={{
                          background: "#009ef7",
                          border: "none",
                          color: "white",
                        }}
                        onClick={() => this.props.handleRedirect("/products/new")}
                      >
                        Add Product
                      </button>
                    </div>
                  </div>
                </div>

                <DataTable
                  title=""
                  {...getProductTableConfig(history)}
                  data={data}
                  columns={this.columns}
                  pagination
                  paginationServer
                  paginationPerPage={limit} // Make sure this matches your 'limit' state
                  paginationRowsPerPageOptions={[10, 15, 20, 30, 50, 100]} // Options for rows per page
                  paginationTotalRows={totalRows} // Total rows count
                  onChangePage={this.handlePageChange}
                  onChangeRowsPerPage={this.handleRowsPerPageChange}
                  sortServer // Indicate that sorting should be handled on the server side
                  onSort={(column, direction) =>
                    this.handleSortClick(column.sortField)
                  }
                  customStyles={this.customStyles}
                // previousLabel={"< Previous"}
                // nextLabel={"Next >"}
                />
              </div>
            </div>

            <Footer></Footer>
          </div>
        </div>
      </>
    );
  }
}

export default withNavigation(Products);
