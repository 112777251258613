import React, { useState } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faShare, faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import ShareModal from './ShareModal'; // Import the ShareModal component
import axios from 'axios';
import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { APP_URL } from '../../helper';


function ProductsDropDown({ product, fetchStatus, setFetchStatus }) {
  // console.log('product dropdown', product.url);
  const [showShareModal, setShowShareModal] = useState(false);

  const shareUrl = `https://lofaz.in/test23/category/lodaz,test2/product/${product.url}`;

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  const closeModal = () => {
    setShowShareModal(false);
  };

  const deleting = async (product) => {
    console.log("product", product);
    try {

      const response = await axios.delete(`${BASE_URL}/vendor/catalog/product/${product._id}`, {
        headers: {
          'Authorization': `Bearer ${cookieFetcher('token')}`,
        },
      });

      // Check if the response status is OK
      if (response.status === 200) {
        // Toggle the fetch status to refresh the data
        setFetchStatus(!fetchStatus);
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: 'Product has been successfully deleted.',
        });
        window.location.reload()
      } else {
        // Handle non-200 responses
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.message || 'An unexpected error occurred.',
        });
      }
    } catch (error) {
      // Handle errors during the request
      console.error('Error deleting product:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'An error occurred while deleting the product. Please try again.',
      });
    }
  };

  const productDeleter = async (product) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: `Are you sure you want to delete ${product.title}?`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleting(product)
      }
    })
  }

  return (
    <div className="d-flex align-items-center">
      <div className="ml-2 text-gray-700 hover:text-black" style={{ cursor: 'pointer' }} onClick={() => {
        window.location.href = `/products/edit/${product._id}`;
      }}>
        <span class="material-symbols-outlined" style={{ cursor: 'pointer' }}>
          edit
        </span>
      </div>
      <div className="ml-2" >
        <a href={product ? `${APP_URL}/${cookieFetcher('username')}/category/${product.category.title}/product/${product.url}` : ""} target="_blank" className="font-semibold text-center text-gray-700 hover:text-black">
          <span class="material-symbols-outlined">
            visibility
          </span>
        </a>
      </div>
      <div className="ml-2 text-gray-700 hover:text-black" style={{ cursor: 'pointer' }} onClick={handleShareClick}>
        <span class="material-symbols-outlined">
          share_windows
        </span>
      </div>
      <div className="ml-2 text-gray-700 hover:text-black" style={{ cursor: 'pointer' }} onClick={() => {
              productDeleter(product)
            }}>
        <span class="material-symbols-outlined" >
          delete
        </span>
      </div>
      {/* <UncontrolledDropdown className='catalogActionsDropdown'> */}
        {/* <DropdownToggle
          style={{ background: 'white', color: '#7e8299', border: 'none' }}
        > */}
          {/* <h4>&#x22EE; </h4> */}
        {/* </DropdownToggle> */}
        {/* <DropdownMenu className='custom-dropdown-menu'>
          <DropdownItem
            style={{ display: 'flex', gap: '10px' }}
            onClick={() => {
              window.location.href = `/products/edit/${product._id}`;
            }}
          >
            <FontAwesomeIcon
              style={{ marginLeft: '15px', marginTop: '3px' }}
              icon={faPenToSquare}
            ></FontAwesomeIcon>
            Edit
          </DropdownItem>
          <DropdownItem style={{ display: 'flex', gap: '10px' }}
            onClick={handleShareClick}>
            {' '}
            <FontAwesomeIcon
              style={{ marginLeft: '15px', marginTop: '3px' }}
              icon={faShare}

            ></FontAwesomeIcon>
            Share
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              productDeleter(product)
            }}
            style={{ display: 'flex', gap: '10px' }}
          >
            {' '}
            <FontAwesomeIcon
              style={{ marginLeft: '15px', marginTop: '3px' }}
              icon={faTrash}
            ></FontAwesomeIcon>
            Delete
          </DropdownItem>
        </DropdownMenu> */}

        {showShareModal && (
          <ShareModal shareUrl={shareUrl} onClose={closeModal} />
        )}
      {/* </UncontrolledDropdown> */}
    </div>
    // <UncontrolledDropdown>
    //   <DropdownToggle
    //     style={{
    //       background: 'rgb(249, 249, 249)',
    //       color: 'rgb(126, 130, 153)',
    //       border: 'none',
    //       fontSize: '12px',
    //       fontWeight: '600',
    //     }}
    //     caret
    //   >

    //   </DropdownToggle>
    //   <DropdownMenu>
    //     <DropdownItem
    //       onClick={() => {
    //         window.location.href = `/products/edit/${product._id}`;
    //       }}
    //       style={{ display: 'flex', gap: '7px' }}
    //     >
    //       <FontAwesomeIcon icon={faEdit} />
    //       Edit
    //     </DropdownItem>
    //     <DropdownItem
    //       onClick={() => {
    //         productDeleter(product)
    //         // Implement product deletion logic here
    //       }}
    //       style={{ display: 'flex', gap: '7px' }}
    //     >
    //       <FontAwesomeIcon icon={faTrash} />
    //       Delete
    //     </DropdownItem>
    //     <DropdownItem
    //       onClick={handleShareClick}
    //       style={{ display: 'flex', gap: '7px' }}
    //     >
    //       <FontAwesomeIcon icon={faShare} />
    //       Share
    //     </DropdownItem>
    //   </DropdownMenu>

    //   {showShareModal && (
    //     <ShareModal shareUrl={shareUrl} onClose={closeModal} />
    //   )}
    // </UncontrolledDropdown>
  );
}

export default ProductsDropDown;
