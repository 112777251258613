import React from 'react'

function Warning({ type }) {
  if (type === 'name') {
    return (
      <div style={{ fontSize: '10px', color: 'red', textAlign: 'start' }}>
        *Name is a mandatory field.
      </div>
    )
  }
  if (type === 'mrp') {
    return (
      <div style={{ fontSize: '10px', color: 'red', textAlign: 'start' }}>
        *Price is a mandatory field.
      </div>
    )
  }
  if (type === 'mrpAndSales') {
    return (
      <div style={{ fontSize: '10px', color: 'red', textAlign: 'start' }}>
        *MRP cannot be less than Sales Price. Cost Per Item cannot be more than
        MRP.
      </div>
    )
  }
  if (type === 'username') {
    return (
      <div style={{ fontSize: '10px', color: 'red', textAlign: 'start' }}>
        *Username is a mandatory field
      </div>
    )
  }
  if (type === 'businessName') {
    return (
      <div style={{ fontSize: '10px', color: 'red', textAlign: 'start' }}>
        *Business name is a mandatory field
      </div>
    )
  } else {
    return (
      <div style={{ fontSize: '8px', color: 'red', textAlign: 'start' }}>
        *A few mandaotry fields are empty or other fields are incorrect.
      </div>
    )
  }
}

export default Warning
