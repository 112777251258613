import { fa4, faCheck, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import { cookieFetcher } from '../../helper'
import { FaWhatsapp } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

function TimelineComponent({catalogs,productView}) {
  const navigate=useNavigate();

  const handleClick = () => {
    navigate('/addCatalog');
  };

  const handleClickprod = () => {
    navigate('/addProduct');
  };

  return (
    <VerticalTimeline lineColor='#1e1e2d' layout='2-columns'>
      <VerticalTimelineElement
        style={{ margin: '0px' }}
        className='vertical-timeline-element--work'
        contentStyle={{
          background: '#1e1e2d',
          color: '#fff',
          paddingTop: '10px',
          paddingBottom: '10px',
          margin: '0px',
          // float: 'right',
        }}
        contentArrowStyle={{
          borderRight: '7px solid  #1e1e2d',
        }}
        // date='2011 - present'
        iconStyle={{
          background: '#18CE0F',
          color: '#fff',
          border: '#1e1e2d solid',
        }}
        icon={<FontAwesomeIcon icon={faCheck} />}
      >
        {/* <h3 className='vertical-timeline-element-title'>
        Creative Director
      </h3> */}
        <h6
          style={{ textAlign: 'end' }}
          className='vertical-timeline-element-subtitle'
        >
          Store Creation Completed!
        </h6>
        <div
          style={{
            textAlign: 'right',
            marginTop: '10px',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
          }}
        >
          You have successfully created your own store.
          <br></br>
          This is your store link:
          <span
            onClick={() => {
              window.open(
                `https://lofaz.com/${cookieFetcher('username')}`,
                '_blank'
              )
            }}
            style={{
              cursor: 'pointer',
              color: '#13a40c',
              textDecoration: 'underline',
              marginLeft: '4px',
            }}
          >
            {' '}
            {`https://lofaz.com/${cookieFetcher('username')}`}
          </span>
        </div>
        {/* <p>
        Creative Direction, User Experience, Visual Design, Project
        Management, Team Leading
      </p> */}
      </VerticalTimelineElement>
      <VerticalTimelineElement
        style={{ margin: '0px' }}
        className='vertical-timeline-element--work'
        contentStyle={{
          background: '#1e1e2d',
          color: '#fff',
          paddingTop: '10px',
          paddingBottom: '10px',
          margin: '0px',
          // float: 'right',
        }}
        contentArrowStyle={{
          borderRight: '7px solid  #1e1e2d',
        }}
        // date='2011 - present'
        iconStyle={{
          background: '#18CE0F',
          color: '#fff',
          border: '#1e1e2d solid',
        }}
        icon={<FontAwesomeIcon icon={faCheck} />}
      >
        {/* <h3 className='vertical-timeline-element-title'>
        Creative Director
      </h3> */}
        <h6
          className='vertical-timeline-element-subtitle'
          style={{ textAlign: 'start' }}
        >
          {catalogs} Catalogs Added!
        </h6>
        <div
          className=''
          style={{
            textAlign: 'left',
            marginTop: '10px',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
          }}
        >
          We always encourage you to add more catalogs.
        </div>
        <div
          style={{
            marginTop: '10px',
            background: '#13a40c',
            textAlign: 'left',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '5px',
            paddingBottom: '5px',
            borderRadius: '5px',
            width: 'fit-content',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
            cursor:'pointer'
          }}
          className='createButtonComp'
          onClick={
            handleClick
          }
        >
          <FontAwesomeIcon
            style={{ marginRight: '7px' }}
            icon={faPlus}
          ></FontAwesomeIcon>
          New Catalog
        </div>
        {/* <p>
        Creative Direction, User Experience, Visual Design, Project
        Management, Team Leading
      </p> */}
      </VerticalTimelineElement>
      <VerticalTimelineElement
        style={{ margin: '0px' }}
        className='vertical-timeline-element--work'
        contentStyle={{
          background: '#1e1e2d',
          color: '#fff',
          paddingTop: '10px',
          paddingBottom: '10px',
          margin: '0px',
          // float: 'right',
        }}
        contentArrowStyle={{
          borderRight: '7px solid  #1e1e2d',
        }}
        // date='2011 - present'
        iconStyle={{
          background: '#18CE0F',
          color: '#fff',
          border: '#1e1e2d solid',
        }}
        icon={<FontAwesomeIcon icon={faCheck} />}
      >
        {/* <h3 className='vertical-timeline-element-title'>
        Creative Director
      </h3> */}
        <h6
          style={{ textAlign: 'right' }}
          className='vertical-timeline-element-subtitle'
        >
          {productView} Products Added!
        </h6>
        <div
          className=''
          style={{
            textAlign: 'right',
            marginTop: '10px',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
          }}
        >
          We always encourage you to add more products.
        </div>

        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'end',
            textAlign: 'right',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '5px',
            paddingBottom: '5px',
            borderRadius: '5px',
            cursor:'pointer'
          }}
          className='createButtonComp'
          onClick={handleClickprod}
        >
          <div
            style={{
              background: '#13a40c',
              width: 'fit-content',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
              borderRadius: '5px',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '19px',
            }}
          >
            <FontAwesomeIcon
              style={{ marginRight: '7px' }}
              icon={faPlus}
            ></FontAwesomeIcon>
            New Product
          </div>
        </div>

        {/* <p>
        Creative Direction, User Experience, Visual Design, Project
        Management, Team Leading
      </p> */}
      </VerticalTimelineElement>
      <VerticalTimelineElement
        style={{ margin: '0px' }}
        className='vertical-timeline-element--work'
        contentStyle={{
          background: '#1e1e2d',
          color: '#fff',
          paddingTop: '10px',
          paddingBottom: '10px',
          margin: '0px',
          // float: 'right',
        }}
        contentArrowStyle={{
          borderRight: '7px solid  #1e1e2d',
        }}
        // date='2011 - present'
        iconStyle={{
          background: 'white',
          color: '#black',
          border: '#1e1e2d solid',
        }}
        icon={
          <FontAwesomeIcon
            icon={fa4}
            color='black
      '
          />
        }
      >
        {/* <h3 className='vertical-timeline-element-title'>
        Creative Director
      </h3> */}
        <h6
          style={{ textAlign: 'left' }}
          className='vertical-timeline-element-subtitle'
        >
          Share Link on WhatsApp
        </h6>
        <div
          style={{
            textAlign: 'left',
            marginTop: '10px',
            fontSize: '12px',
            fontWeight: '500',
            lineHeight: '19px',
          }}
        >
          Your customers can visit your online store and place orders from this
          link.
        </div>
        <div
          style={{
            marginTop: '10px',
            background: '#13a40c',
            textAlign: 'left',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '5px',
            paddingBottom: '5px',
            borderRadius: '5px',
            width: 'fit-content',
            fontSize: '13px',
            fontWeight: '500',
            lineHeight: '19px',
          }}
          className='createButtonComp'
        >
          <FaWhatsapp style={{ marginRight: '7px' }}></FaWhatsapp>
          Share
        </div>

        {/* <p>
        Creative Direction, User Experience, Visual Design, Project
        Management, Team Leading
      </p> */}
      </VerticalTimelineElement>
    </VerticalTimeline>
  )
}

export default TimelineComponent
