import React, { useEffect } from "react";
import OrdersHeader from "../Orders/OrdersHeader";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faCreditCard,
  faEnvelope,
  faMobile,
  faTruck,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { BASE_URL, cookieFetcher } from "../../helper";
import Swal from "sweetalert2";
import { Table } from "reactstrap";

function formatDateToDDMMYYYY(createdAtC) {
  const createdAt = new Date(createdAtC);
  const day = String(createdAt.getDate()).padStart(2, "0");
  const month = String(createdAt.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = String(createdAt.getFullYear());

  return `${day}/${month}/${year}`;
}

function OrderDetailsMain({ sidebar, setSidebar, order, orderId,subtotal,shippingrate,date,status,name,phone,quantity,mrp,sprice,statusDate,productTitle,add1,add2,city,state,country,zip,prodImg,url }) {
  
  const [customer, setCustomer] = useState();

  useEffect(() => {
    console.log("props",order,orderId,subtotal,shippingrate,date,status,name,phone,quantity,mrp,sprice,statusDate,productTitle,add1,add2,city,state,country,zip,prodImg,url);
    // Check if order and order.shipping_details are defined
    // if (!order || !order.shipping_details || !order.shipping_details.cust_id) {
    //   return; // Exit early if cust_id is undefined
    // }

    // Convert cust_id to string if needed

    // const fetcher = async () => {
    //   console.log(OrderDetail, "orderdetails");
    //   try {
    //     console.log(`${order.shipping_details.cust_id}`);
    //     const response = await fetch(
    //       `${BASE_URL}/customer/orders_details/${order.shipping_details._id}`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${cookieFetcher("token")}`,
    //         },
    //         method: "GET",
    //       }
    //     );
    //     const res = await response.json();
    //     console.log("response",res);
    //     console.log(res);
    //     if (res.status === 200) {
    //       setCustomer(res.data);
    //     } else {
    //       // Handle error case
    //       Swal.fire({
    //         icon: "error",
    //         title: "Failed!",
    //         text: `${res.status}: ${res.message}`,
    //       });
    //     }
    //   } catch (error) {
    //     console.error("Fetch error:", error);
    //     // Handle fetch error here
    //     Swal.fire({
    //       icon: "error",
    //       title: "Failed!",
    //       text: "Error fetching data. Please try again later.",
    //     });
    //   }
    // };

    // fetcher();
  }, [order]);
  
  return (
    <div className="orderDetailsMain">
      <div className="orderDetailsMainTop">
        <OrdersHeader sidebar={sidebar} setSidebar={setSidebar}></OrdersHeader>
        <div className="ordersMainSec">
          <div className="ordersMainSecComponent ordersMainSecComponent1">
            <div className="leftComponent squareComponent">
              <div className="squareComponentTitle">
                Order Details #({orderId})
              </div>
              <div className="squareComponentContent">
                <div className="listItem listItem1">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faCalendarDays} />
                    Date Added
                  </div>
                  <div className="listItemRight">
                    {order &&
                      formatDateToDDMMYYYY(date)}
                  </div>
                </div>
                <div className="listItem listItem2">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faEnvelope} />
                    Invoice ID
                  </div>
                  <div className="listItemRight">
                    #{orderId}
                  </div>
                </div>
                <div className="listItem listItem3">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faTruck} />
                    Order Status
                  </div>
                  <div className="listItemRight">
                    {status}
                  </div>
                </div>
              </div>
            </div>
            <div className="rightComponent squareComponent">
              <div className="squareComponentTitle">Customer Details</div>
              <div className="squareComponentContent">
                <div className="listItem">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faUser} />
                    Customer
                  </div>
                  <div
                    style={{ display: "flex", gap: "10px" }}
                    className="listItemRight"
                  >
                    {customer && customer.photo ? (
                      <img
                        src={customer.photo}
                        width="30px"
                        height="30px"
                      ></img>
                    ) : (
                      <FontAwesomeIcon
                        style={{ marginTop: "3px" }}
                        icon={faUser}
                      ></FontAwesomeIcon>
                    )}
                    {name}
                  </div>
                </div>
                <div className="listItem">
                  {/* <div className="listItemLeft">
                    <FontAwesomeIcon icon={faEnvelope} />
                    Email
                  </div> */}
                  <div className="listItemRight">
                    {/* {name  } */}
                  </div>
                </div>
                <div className="listItem">
                  <div className="listItemLeft">
                    <FontAwesomeIcon icon={faMobile} />
                    Phone
                  </div>
                  <div className="listItemRight">
                    +91{phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ordersMainSecComponent ordersMainSecComponent2 squareComponentTable">
            <div className="squareComponentTitle">
              Order #{orderId}
            </div>
            <div className="squareComponentContent ">
              <Table responsive>
                <thead>
                  <th>PRODUCT</th>
                  <th>QUANTITY</th>
                  <th>MRP</th>
  <th>SALES PRICE</th>
                  <th>TOTAL</th>
                </thead>
                <tbody>
                 
                        <tr>
                          <td
                            style={{ display: "flex", gap: "10px" }}
                            className="tableRow"
                          >
                            <div>
                              {" "}
                              <img
                                src={prodImg}
                                width="45px"
                                height="45px"
                                style={{ borderRadius: "7px" }}
                              ></img>
                            </div>
                            <div>
                              <div
                                style={{
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  fontWeight: "600",
                                  color: "#7e8299",
                                }}
                              >
                                {" "}
                                <a href={url} target="_blank" style={{color:'black'}}>{productTitle}</a>
                              </div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "500",
                                  color: "#a1a5b7",
                                  lineHeight: "19px",
                                }}
                              >
                                
                                {order &&
                                  `Last Updated: ${formatDateToDDMMYYYY(
                                    statusDate
                                  )}`}
                              </div>
                            <div>
                              {status==='cancelled' ? (
                                <h6>Cancelled Reason - <i class="fa fa-xin" aria-hidden="true"></i></h6>
                              ) : 
                              null}
                            </div>
                            </div>

                          </td>
                          <td className="tableRow tableRow2">{quantity}</td>
                          <td className="tableRow tableRow2">{mrp}</td>
                           <td className="tableRow tableRow2">{sprice}</td>
                          <td className="tableRow tableRow2">{subtotal}</td>
                        </tr>
                      
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="tableRow tableRow2">Subtotal</td>
                    <td className="tableRow tableRow2">{subtotal}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="tableRow tableRow2">Shipping Rate</td>
                    <td className="tableRow tableRow2">
                      {shippingrate} {shippingrate === 0 ? '(free)' : ''}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="tableRow">Grand Total</td>
                    <td className="tableRow">
                     {subtotal+shippingrate}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="ordersMainSecComponent ordersMainSecComponent3">
            <div className="leftComponent squareComponent">
              
              <div className="squareComponentTitle"><FontAwesomeIcon icon={faCreditCard} />{" "}Billing Address</div>
              <div className="squareComponentContent squareComponentContent2">
                <div className="squareComponentConLeft">
                  {add1}
                  <br></br>
                  {add2}
                  <br></br>
                  {city}
                  <br></br>
                  {state}
                  <br></br>
                  {country}
                  <br></br>
                  {zip}
                </div>
                <div className="squareComponentConRight">
                  
                </div>
              </div>{" "}
            </div>
            <div className="rightComponent squareComponent">
            
            {order && order.shipping_details && order.shipping_details.shipping_address1 ? (
        <div className="squareComponent">
          <div className="squareComponentTitle">
            <FontAwesomeIcon icon={faTruck} /> Shipping Address
          </div>
          <div className="squareComponentContent squareComponentContent2">
            <div className="squareComponentConLeft">
              {order?.shipping_details?.shipping_address1}
              <br />
              {order?.shipping_details?.shipping_address2}
              <br />
              {order?.shipping_details?.shipping_city}
              <br />
              {order?.shipping_details?.shipping_state}
              <br />
              {order?.shipping_details?.shipping_country}
              <br />
              {order?.shipping_details?.shipping_zip}
            </div>
            <div className="squareComponentConRight">
              {/* Additional content for right side if needed */}
            </div>
          </div>
        </div>
      ) : (
        ``
      )}
              
            </div>
          </div>
        </div>
      </div>
      <div className="orderDetailsMainBottom">
        <Footer></Footer>
      </div>
    </div>
  );
}

export default OrderDetailsMain;
