// import React from 'react'
// import './Setting.css'
// import OrdersHeader from '../Orders/OrdersHeader'
// import Footer from '../../components/Footer/Footer'
// import Sidebar from '../../components/Sidebar/Sidebar'
// import { useState } from 'react'
// import NavigationBoxSettings from '../../components/NavigationBox/NavigationBoxSettings'
// import EditProfileForm from '../../components/EditProfileForm/EditProfileForm'
// import { useEffect } from 'react'
// import { cookieFetcher, loginChecker } from '../../helper'
// import { Helmet } from 'react-helmet'

// function Setting() {
//   const [sidebar, setSidebar] = useState(true)

//   useEffect(() => {
//     loginChecker()
//   }, [])

//   return (
//     <div className='settingsPage'>
//       <Helmet
//         title='Settings'
//         link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
//       ></Helmet>
//       <Sidebar sidebar={sidebar} page={'settings'}></Sidebar>
//       <div className='settingsMain'>
//         <div className='settingsMainTop'>
//           <OrdersHeader
//             sidebar={sidebar}
//             setSidebar={setSidebar}
//           ></OrdersHeader>
//           {/* <div>Settings Main Section</div> */}
//           <div className='settingsMainSection'>
//             <EditProfileForm type={'profile'}></EditProfileForm>
//           </div>
//         </div>
//         <div className='settingsMainBottom'>
//           <Footer></Footer>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Setting







import React, { useState, useEffect } from 'react';
import './Setting.css';
import OrdersHeader from '../Orders/OrdersHeader';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import EditProfileForm from '../../components/EditProfileForm/EditProfileForm';
import NavigationBoxSettings from '../../components/NavigationBox/NavigationBoxSettings';
import { cookieFetcher, loginChecker } from '../../helper';
import { Helmet } from 'react-helmet';

function Setting() {
  const [sidebar, setSidebar] = useState(true);

  useEffect(() => {
    // Make sure loginChecker function is defined and does not cause any errors
    loginChecker();
  }, []);

  return (
    <div className='settingsPage'>
      <Helmet
        title='Settings'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      />
      {/* Check if Sidebar component is correctly implemented */}
      <Sidebar sidebar={sidebar} page={'settings'} />
      <div className='settingsMain'>
        <div className='settingsMainTop'>
          {/* Check if OrdersHeader component is correctly implemented */}
          <OrdersHeader sidebar={sidebar} setSidebar={setSidebar} />
          {/* Check if EditProfileForm component is correctly implemented */}
          <div className='settingsMainSection'>
            <EditProfileForm type={'profile'} />
          </div>
        </div>
        {/* Check if Footer component is correctly implemented */}
        <div className='settingsMainBottom'>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Setting;
