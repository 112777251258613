import React, { Component } from 'react';
import DataTable from "react-data-table-component";
import axios from "axios";
import OrdersDropdown from '../../components/Dropdowns/OrdersDropdown'
import { BASE_URL, cookieFetcher } from '../../helper';
import _ from 'lodash';
import withNavigation from '../../withNavigation';
import 'animate.css';
import { BsSearch } from 'react-icons/bs';

const generateWhatsAppUrl = (phone) => {
  const message = encodeURIComponent(
    "Thank you for your order! Your order is currently pending and will be processed shortly. We appreciate your patience and will notify you with updates soon."
  );
  return `https://wa.me/${phone}?text=${message}`;
};

const ViewOrderButton = ({ handleDelete }) => (
  <div className="container">
    <div className="d-flex justify-center" >
      <button className="btn btn-danger p-2" onClick={handleDelete}>
        <i className="fas fa-trash" />
      </button>
    </div>
  </div>
);



export const getOrderTableConfig = async (currentPage, limit, search = '', sort = 'createdAt', sortBy = -1) => {
  try {
    const response = await axios.get(`${BASE_URL}/order/vendor/list/`, {
      params: {
        page: currentPage,
        limit: limit,
        search: search,
        sort: sort,
        sortBy: sortBy
      },
      headers: {
        'Authorization': `Bearer ${cookieFetcher('token')}` 
      },
      mode: "cors",
      cache: 'default'
    });
    const data = response.data;

    console.log("Data fetched correctly", data);
    return data;
  }
  catch (error) {
    console.error('Error fetching orders:', error);
    return { orders: [], totalCount: 0 };
  }
};

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      currentPage: 1,
      limit: 10, // Initial rows per page
      totalRows: 0,
      fetched: false,
      search: '',
      sort: -1,
      sortBy: 'createdAt',
      filterStatus: null, // State to manage showing orders by status
      isExpanded:false,
      orders:[],
      pendingCount:0,
      acceptedCount:0,
      deliveredCount:0,
      shippedCount:0,
      cancelledCount:0,
      allCount:0,
      animate: false
    };

    this.debouncedFetchData = _.debounce(this.fetchData, 300);
  }

  countStatus(arr) {
    console.log("arr", arr);

    let pendingCount = 0;
    let deliveredCount = 0;
    let cancelCount = 0;
    let shippedCount = 0;
    let acceptCount = 0;

    // Iterate over each order in the array
    for (const item of arr) {
        // Ensure the order and status array exist and have at least one status
        if (item?.order?.status?.length > 0) {
            // Access the last status item
            const lastStatus = item.order.status[item.order.status.length - 1];

            // Increment counts based on the type of the last status
            switch (lastStatus.type) {
                case "pending":
                    pendingCount++;
                    break;
                case "delivered":
                    deliveredCount++;
                    break;
                case "cancelled":
                    cancelCount++;
                    break;
                case "shipped":
                    shippedCount++;
                    break;
                case "accepted":
                    acceptCount++;
                    break;
                default:
                    // Handle unexpected status types if necessary
                    break;
            }
        }
    }

    // Update state after processing all items
    this.setState({
        allCount: arr.length,
        pendingCount: pendingCount,
        deliveredCount: deliveredCount,
        acceptedCount: acceptCount,
        shippedCount: shippedCount,
        cancelledCount: cancelCount
    });
}


  async componentDidMount() {
    //const { currentPage, limit, search, sort, sortBy } = this.getURLParams();
    const { currentPage, limit, search, sort, sortBy } = this.state;
    
    this.setState({ currentPage, limit, search, sort, sortBy }, () => {
      this.fetchData(currentPage, limit, search, sort, sortBy);
    });
  }

  fetchData = async (currentPage, limit, search = '', sort = 1, sortBy = 'createdAt') => {
    try {
      const data = await getOrderTableConfig(currentPage, limit, search, sortBy, sort);
      console.log("fetched", data);
      this.setState({
        orderData: this.state.filterStatus ? data.filter(item => item.order.status[item.order.status.length-1]?.type === this.state.filterStatus) : data,
        totalRows: data.length,
        fetched: true
      });
      //this.setState({orderData:data})
      this.countStatus(data)
    } catch (error) {
      console.log("Error occurred while fetching orders:", error);
    }
  }

  updateOrders = (newOrders) => {
    this.setState({ orders: newOrders });
  };

  // updateURLParams = () => {
  //   const { currentPage, limit, search, sort, sortBy } = this.state;
  //   const queryParams = new URLSearchParams({
  //     page: currentPage,
  //     limit,
  //     search,
  //     sort,
  //     sortBy
  //   }).toString();
  //   this.props.history.push(`?${queryParams}`);
  // };



  // getURLParams = () => {
  //   const searchParams = new URLSearchParams(this.props.location.search);
  //   const currentPage = parseInt(searchParams.get('page')) || 1;
  //   const limit = parseInt(searchParams.get('limit')) || 10;
  //   const search = searchParams.get('search') || '';
  //   const sort = searchParams.get('sort') || 'createdAt';
  //   const sortBy = parseInt(searchParams.get('sortBy')) || -1;
  //   return { currentPage, limit, search, sort, sortBy };
  // }

  setFilterStatus = (status) => {
    this.setState({
      filterStatus: status,
      currentPage: 1,
      animate: true 
    }, () => {
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
      setTimeout(() => this.setState({ animate: false }), 1500);
    });
  };

  toggleExpand = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  };

  togglePendingFilter = () => {
    this.setState(prevState => ({
      showPending: !prevState.showPending,
      currentPage: 1
    }), () => {
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
  };

  handleSearchChange = (e) => {
    let search = e.target.value;
    this.setState({ search }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
    //this.updateURLParams();
  };

  handleSortClick = (column) => {
    //console.log("Sorting by", column);
    this.setState(prevState => {
      const newSort = prevState.sort === column ? prevState.sortBy * -1 : 1;
      return { sort: column, sortBy: newSort, currentPage: 1 };
    }, () => {
      console.log(`Sorting by ${this.state.sort} in order ${this.state.sortBy}`);
      this.fetchData(this.state.currentPage, this.state.limit, this.state.search, this.state.sortBy, this.state.sort);
    });
    //this.updateURLParams();
    console.log("hi", this.state.currentPage, this.state.limit, this.state.search, this.state.sort, this.state.sortBy);
  }

    


  customSort = (rowA, rowB) => {
    const typeA = rowA.status[0]?.type === "pending" ? 1 : 0;
    const typeB = rowB.status[0]?.type === "pending" ? 1 : 0;
    return typeB - typeA; // This will prioritize "pending" types
  };


  handleRowsPerPageChange = (newPerPage) => {
    this.setState({ currentPage: 1, limit: newPerPage }, () => {
      const { currentPage, limit, search, sortBy, sort } = this.state;
      //console.log(limit);
      this.fetchData(currentPage, limit, search, sortBy, sort);
    });
   // this.updateURLParams();
  };

  handleOrderClick = (orderId) => {
    this.props.handleRedirect(`/orders/${orderId}`);
  };

  columns = [
    { name: "SL", cell: (row, index) => <p>{(this.state.currentPage - 1) * this.state.limit + index + 1}</p>, width: '50px' },
    
    {
      name: "Order ID",
      cell: (row) => {
        const orderId = row && row.order ? row.order._id : '';
        const trimmedOrderId = orderId.length > 10 ? `${orderId.substring(0, 13)}...` : orderId;
    
        return (
          <p
            className='font-semibold cursor-pointer hover:underline'
            onClick={() => this.handleOrderClick(row.order._id)}
          >
            {trimmedOrderId}
          </p>
        );
      },
      width: '150px'
    },
    {
      name: "Order Created at",
      cell: (data) => {
        const date = new Date(data?.order?.createdAt);
        const options = {
          weekday: 'short',
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        };
        const formattedDate = date.toLocaleString('en-US', options)
          .replace(",", "");
        return <p className='font-semibold'>{formattedDate}</p>;
      },
      minWidth: '220px',
      sortField: 'order.createdAt',
      sortable: true,
    },

    // {
    //   name: "Product Name",
    //   cell: (row) => {
    //     //console.log(data);
    //     return (<p>{row.order_items && row.order_items.length > 0 ? row.order_items[0].product.title : ''}</p>)
    //   }, width: '250px'
    // },

    // {
    //   name: "Product Desc",
    //   cell: (row) => {
    //     let desc = row.order_items && row.order_items.length > 0 ? row.order_items[0].product.desc : '';
    //     // Truncate the description to 100 characters and add an ellipsis if necessary
    //     desc = desc.length > 70 ? `${desc.substring(0, 70)}...` : desc;
    //     //console.log(data);
    //     return (<p style={{ maxWidth: '450px', overflowWrap: 'break-word', textAlign: 'center', marginLeft:'20px', marginRight:'20px', marginTop: '5px', marginBottom: '5px' }}>{desc}</p>)
    //   }, width: '400px'
    // },
    // {
    //   name: "Vendor",
    //   cell: (row) => (
    //     <div className='d-flex items-center justify-center' >
    //       <ol>
    //         <ul>
    //           <li>
    //             <p>{row.shop && row.shop.business_name ? row.shop.business_name : ''}</p>
    //           </li>
    //           <li>
    //             <p>Contact: {row.shop && row.shop.phone ? row.shop.phone : ''}</p>
    //           </li>
    //         </ul>
    //       </ol>
    //     </div>
    //   ),
    //   minWidth: '300px'
    // },
    {
      name: "Customer",
      cell: (row) => {
        //const vendorId = row["vendor_has_customer_id"]["customer_id"]["profile_id"]["name"];
        //console.log(row);
        return (
         
                  <p className='font-semibold'>{row && row.order && row.order.vendor_has_customer_id && row.order.vendor_has_customer_id.customer_id ? `${row.order.vendor_has_customer_id.customer_id.profile_id.name.first} ${row.order.vendor_has_customer_id.customer_id.profile_id.name.last}` : ''}</p>
               
        );
      },
      minWidth: '230px',
      sortable:true,
      sortField:'row.order.vendor_has_customer_id.customer_id.profile_id.name.first'
    },
     {
      name: "Quantity",
      cell: (row) => (
        
              <p className='font-semibold text-center'>{row && row.order_item ? row.order_item[0].quantity : '0'}</p>
            
      ),
      minWidth: '50px',
      sortable:true,
      sortField:'order_item[0].quantity'
    },
    // {
    //   name: "Shipping Location",
    //   cell: (row) => {
    //     const shippingLocation = row["shipping_location"];
    //     return (
    //       <p>{shippingLocation?.city || "N/A"}, {row["shipping_location"]["state"] ? row["shipping_location"]["state"] : ''}, {row["shipping_location"]["country"] ? row["shipping_location"]["country"] : ''}</p>
    //     );
    //   },
    //   minWidth: '200px',
    // },
    // {
    //   name: "Shipping Charge",
    //   cell: (row) => (
    //     <ol>
    //       <ul>
    //         <li>
    //           <p>Type: {row["shipping_charge"]["type"] ? row["shipping_charge"]["type"] : ''}</p>
    //         </li>
    //         <li>
    //           <p>Amount: {row["shipping_charge"]["amount"] ? row["shipping_charge"]["amount"] : '0'}</p>
    //         </li>
    //       </ul>
    //     </ol>
    //   ),
    //   minWidth: '200px'
    // },
    // {
    //   name: "Product Charge",
    //   cell: (row) => (
    //     <ol>
    //       <ul>
    //         <li>
    //           <p>Type: {row["shipping_charge"]["type"] ? row["shipping_charge"]["type"] : ''}</p>
    //         </li>
    //         <li>
    //           <p>Amount: {row["shipping_charge"]["amount"] ? row["shipping_charge"]["amount"] : '0'}</p>
    //         </li>
    //       </ul>
    //     </ol>
    //   ),
    //   minWidth: '200px'
    // },
    {
      name: "Total",
      cell: (data) => (
        <p className='font-semibold'>₹ {data && data.order ? data.order.total : '0'}</p>
      ),
      sortable: true,
      sortField: 'order.total'
    },
    {
      name: "Actions",
      cell: (item) => {
        const j = item?.order?.status?.length - 1;    //j=last index
        //const phone = data.vendor_has_customer_id?.store_id.phone || ''; // Assuming the customer's phone number is available in the data

        return (
          <div className='d-flex align-items-start'>
            {item && item.order.status[j].type==="accepted" && (
            <OrdersDropdown
              status={"Accepted"}
              id={item.order._id}
              setOrders={this.updateOrders}
            />
          )}
          {item && item.order.status[j].type==="cancelled" && (
            <OrdersDropdown
              status={"Cancelled"}
              id={item.order._id}
              setOrders={this.updateOrders}
            />
          )}
          {item && item.order.status[j].type==="delivered" && (
            <OrdersDropdown
              status={"Delivered"}
              id={item.order._id}
              setOrders={this.updateOrders}
            />
          )}
          {item && item.order.status[j].type==="pending" && (
            <OrdersDropdown
              status={"Pending"}
              id={item.order._id}
              setOrders={this.updateOrders}
            />
          )}
          {item && item.order.status[j].type==="shipped" && (
            <OrdersDropdown
              status={"Shipped"}
              id={item.order._id}
              setOrders={this.updateOrders}
            />
          )}
          </div>
        );
      }
      
    },
    
   

    // {
    //   name: "Last Updated On",
    //   cell: (data) => {
    //     const date = new Date(data.updatedAt);
    //     const options = {
    //       weekday: 'short',
    //       month: 'short',
    //       day: '2-digit',
    //       year: 'numeric',
    //       hour: 'numeric',
    //       minute: '2-digit',
    //       hour12: true
    //     };
    //     const formattedDate = date.toLocaleString('en-US', options)
    //       .replace(",", "");
    //     return formattedDate;
    //   },
    //   minWidth: '200px',
    //   sortField: 'updatedAt',
    //   sortable: true,
    // },
    
  ];
  
  render() {
    const { orderData, fetched, currentPage, search, limit,animate} = this.state;
  
    const { history } = this.props;
    
    console.log("orderD:",orderData);
 

    

    return (
      <>
        {/* <div className="row px-2 mt-4">
          <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
            Total OVERVIEW :
          </h3>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-tale">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Store</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-dark-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Catalogue</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-danger">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Product</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total Order</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row px-2 mt-4">
          <h3 className="col-md-12 font-weight-bold mb-3 text-uppercase">
            TODAY'S ORDER :
          </h3>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-tale">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Total</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-dark-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Accepted</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-danger">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Shipped</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4 stretch-card transparent dashboard-visual-totals">
            <div className="card card-light-blue">
              <div className="card-body">
                <h5 className="mb-4 font-larger">Delivered</h5>
                <p style={{ fontSize: "30px" }}>
                  0
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mb-3 d-flex mt-4 justify-content-between text-center">
        <div
                      className="onSearch d-flex align-items-center"
                      style={{ gap: "5px", overflow: "visible" }}
                    >
                      <div className="search-container flex-grow" style={{width:"300px"}}>
                        <input
                          type="text"
                          className="search-input"
                          aria-label="Search Orders"
                          placeholder="Search Orders"
                          value={search}
                          onChange={this.handleSearchChange}
                        />
                        <BsSearch className="search-icon" size={20} />
                      </div>
                    </div>
          
          

          

        

        <div className="d-flex justify-content-between align-items-center ">
         
            <div className="btn-group hidden md:block" role="group" aria-label="Order Status Filters">
              <button
                type="button"
                className={`btn ${this.state.filterStatus === null ? 'btn-dark' : 'btn-outline-dark'} animate__animated ${this.state.filterStatus === null ? 'animate__fadeIn' : ''}`}
                onClick={() => this.setFilterStatus(null)}
              >
                All {`(${this.state.allCount})`}
              </button>
            <button
              type="button"
                className={`btn ${this.state.filterStatus === 'pending' ? 'btn-warning' : 'btn-outline-dark'}  animate__animated ${this.state.filterStatus === 'pending' ? 'animate__fadeIn' : ''}`}
              onClick={() => this.setFilterStatus('pending')}
            >
              Pending {`(${this.state.pendingCount})`}
            </button>
            <button
              type="button"
              className={`btn ${this.state.filterStatus === 'shipped' ? 'btn-dark' : 'btn-outline-dark'}  animate__animated ${this.state.filterStatus === 'shipped' ? 'animate__fadeIn' : ''}`}
              onClick={() => this.setFilterStatus('shipped')}
            >
              Shipped {`(${this.state.shippedCount})`}
            </button>
            <button
              type="button"
                className={`btn ${this.state.filterStatus === 'accepted' ? 'btn-primary' : 'btn-outline-dark'}  animate__animated ${this.state.filterStatus === 'accepted' ? 'animate__fadeIn' : ''}`}
              onClick={() => this.setFilterStatus('accepted')}
            >
              Accepted {`(${this.state.acceptedCount})`}
              </button>
              <button
                type="button"
                className={`btn ${this.state.filterStatus === 'delivered' ? 'btn-success' : 'btn-outline-dark'}  animate__animated ${this.state.filterStatus === 'delivered' ? 'animate__fadeIn' : ''}`}
                onClick={() => this.setFilterStatus('delivered')}
              >
                Delivered {`(${this.state.deliveredCount})`}
              </button>
              <button
                type="button"
                className={`btn ${this.state.filterStatus === 'cancelled' ? 'btn-danger' : 'btn-outline-dark'}  animate__animated ${this.state.filterStatus === 'cancelled' ? 'animate__fadeIn' : ''}`}
                onClick={() => this.setFilterStatus('cancelled')}
              >
                Cancelled {`(${this.state.cancelledCount})`}
              </button>
              
          </div>
          </div>
        </div>
        <div className={`animate__animated ${animate ? 'animate__fadeIn' : ''}`} >
        <DataTable
          title=""
        //  fixedHeaderScrollHeight="400px"
          // searchByList={[
          //   { label: "Order ID", value: "order_no" },
          //   {
          //     label: "Vendor Business Name",
          //     value: ({ vendor }) => (vendor ? vendor["business_name"] : ""),
          //   },
          //   {
          //     label: "Customer Name",
          //     value: ({ customer }) => (customer ? customer["full_name"] : ""),
          //   },
          //   {
          //     label: "Product Name",
          //     value: ({ product }) => (product ? product["title"] : ""),
          //   },
          //   {
          //     label: "Vendor Mobile Number",
          //     value: ({ vendor }) => (vendor ? vendor["phone"] : ""),
          //   },
          //   {
          //     label: "Customer Mobile No",
          //     value: ({ customer }) => (customer ? customer["phone"] : ""),
          //   },
          // ]}
          {...getOrderTableConfig(history)}
        
          customStyles={this.customStyles}
          data={orderData}
          columns={this.columns}
          pagination
          paginationServer
          paginationPerPage={limit} // Make sure this matches your 'limit' state
          paginationRowsPerPageOptions={[10, 15, 20, 30, 50]} // Options for rows per page
          paginationTotalRows={orderData.length} // Total rows count
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
          sortServer // Indicate that sorting should be handled on the server side
          onSort={(column, direction) => this.handleSortClick(column.sortField)}
        />
        </div>
        
      </>
    );
  }
}

export default withNavigation(Order);
