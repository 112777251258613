import firebase from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyAp19whtLU69zFSbjDhsreyviJOfaBcgcM',
  authDomain: 'lofaz-6da09.firebaseapp.com',
  databaseURL: 'https://lofaz-6da09.firebaseio.com',
  projectId: 'lofaz-6da09',
  storageBucket: 'lofaz-6da09.appspot.com',
  messagingSenderId: '733483038330',
  appId: '1:733483038330:web:ef8a3956db2330c8a8edc3',
  measurementId: 'G-WZHWQ1KG1K',
}

// Initialize Firebase
firebase.initializeApp(config)

export default firebase
