import React, { useEffect } from "react";
import "./EditProfileForm.css";
import { useState } from "react";
import ReactQuill from "react-quill";
import NavigationBoxSettings from "../NavigationBox/NavigationBoxSettings";
import axios from "axios";
import StoreStatusForm from "./OtherProfileForms/StoreStatusForm";
import PrivacyPolicyForm from "./OtherProfileForms/PrivacyPolicyForm";
import SocialMediaForm from "./OtherProfileForms/SocialMediaForm";
import StoreTimingForm from "./OtherProfileForms/StoreTimingForm";
import ShippingAndDeliveryForm from "./OtherProfileForms/ShippingAndDeliveryForm";
import ShippingAndDeliverySettings from "../../pages/Setting/ShippingandDeliverySetting";
import { BASE_URL, cookieFetcher, defaultFeatureImage } from "../../helper";
import { useRef } from "react";
import profileEditor from "./ProfileEditor";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import Swal from "sweetalert2";
import Coming_soon from "../../pages/Setting/Coming_soon";
import Social from "../../pages/Setting/Social";

function EditProfileForm({ type }) {
  const [profileImage, setProfileImage] = useState(
    "https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png"
  );
  const [profileImageLink, setProfileImageLink] = useState(
    "https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png"
  );

  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [categories, setCategories] = useState([]);
  const [about, setAbout] = useState("");
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [addLine1, setAddLine1] = useState("");
  const [addLine2, setAddLine2] = useState("");
  const [uNedit, setUNEdit] = useState(true);
  const [pNEdit, setPNEdit] = useState(true);
  const [bcEdit, setBCEdit] = useState(false);
  const [bNEdit, setBNEdit] = useState(true);
  const [sucess, setsucess] = useState(false);
  const [loading,setLoading]=useState(false);

  const inputFile = useRef();

  const generateAboutText = async (keywords, business) => {
    try {
      setLoading(true);
      const API_KEY = 'AIzaSyAMKjcnhLfkHbvBBYrvcuBngcizr0FLc8Q'
      const response = await axios({
        url: `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=${
          API_KEY
        }`,
        method: "post",
        data: {
          contents: [{ parts: [{ text: `Generate a shop description about ${keywords} in less than 100 characters. Vendor's business name: ${business}` }] }],
        },
      });
      console.log("gemini",response);
      return response.data.candidates[0].content.parts[0].text; 
    } catch (error) {
      console.error("Error generating about text:", error);
      return '';
    }
    finally {
      setLoading(false);
    }
  };

  const handleGenerateClick = async () => {
    
    const generatedText = await generateAboutText(selectedTitle, businessName);
    if (generatedText!='' ){
      setAbout(generatedText);
    }
  };

  // const inputFile = useRef(n ull);

  useEffect(() => {
    const fetcher = async () => {
      const response = await fetch(
        `${BASE_URL}/profile/shop`, {
        headers: {
          'Authorization':
            `Bearer ${cookieFetcher('token')}`,
        },
        mode: "cors",
        cache: "default",
      }
      );
      const res = await response.json();


      const responseCat = await fetch(`${BASE_URL}/shop_category/shop`);    //dropdown ke liye api
      const data = await responseCat.json();
      setCategories(data);
      console.log("categories", data);
      console.log("username", username);

      console.log("res", res);
      // console.log(res.data.photo.length)
      setUsername(res.username);
      setPhoneNumber(res.phone);
      setEmail(res.email)
      // Check if account_type is a string, parse it if necessary
      setAbout(res.about);
      //setSelectedId(res.data.shop_category_id);

      console.log("shop", res.shop_category_id);
      const selected = data
        .filter(cat => res.shop_category_id.includes(cat._id))
        .map(cat => cat.title);
      console.log("selected", selected);
      setSelectedTitle(selected);

      setBusinessName(res.business_name);

      const nameParts = res.full_name.split(' ');
      setFirstName(nameParts[0]); // First name
      setLastName(nameParts.slice(1).join(' ')); // Last name (handles middle names if any)

      setCountry(res.country);
      setZip(res.zip);
      setCity(res.city);
      setState(res.state);
      setAddLine1(res.address_line1);
      setAddLine2(res.address_line2);
      setProfileImage(res.photo);
      try {
        if (res.photo.length > 0) {
          setProfileImageLink(res.photo);
        }
        if (res.data.photo.length > 0) {
          setProfileImage(res.photo);
        }
      } catch (error) {
        console.log("no photo", error);
      }
    };



    fetcher();

  }, []);

  const onButtonClick = async () => {
    inputFile.current.click();
  };



  const handleCategorySelect = (categoryName) => {
    setSelectedTitle([...selectedTitle, categoryName]);
  };

  const handleRemoveCategory = (categoryName) => {
    console.log("cate", categoryName);
    setSelectedTitle(
      selectedTitle.filter((cat) => cat !== categoryName)
    );
  };


  return (
    <>
      <NavigationBoxSettings photo={profileImage}></NavigationBoxSettings>
      {type === "profile" && (
        <div className="editProfileForm">
          <div className="editProfileFormSection">
            <div className="editProfileSecTitle">Business Details</div>
            <div className="editProfileSecContent">
              <div
                className="profileImageAdder"
                onClick={() => {
                  onButtonClick();
                }}
              >
                <img
                  src={profileImageLink}
                  style={{
                    borderRadius: "2rem",
                    border: "1px solid #ebecf1",
                    objectFit: "cover",
                    height: "140px",
                    width: "140px",
                  }}
                ></img>
                <input
                  onChange={(e) => {
                    if (e.target.files) {
                      setProfileImage(e.target.files[0]);
                      setProfileImageLink(
                        URL.createObjectURL(e.target.files[0])
                      );
                    }
                  }}
                  type="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                ></input>
              </div>
              <div className="profileDetailsAdder">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    flexBasis: "100%",
                  }}
                >
                  <div className="usernameAdder">
                    <div className="profileInputLabel">
                      <p style={{ color: "#1e1e2d" }}>Username</p>
                      {/* <p
                        style={{ color: "#009ef7", cursor: "pointer" }}
                        onClick={() => {
                          setUNEdit(!uNedit);
                        }}
                      >
                        {!uNedit ? "Save" : "Edit"}
                      </p> */}
                    </div>
                    <input
                      // onChange={(e) => {
                      //   setUsername(e.target.value);
                      // }}
                      value={username}
                      placeholder="Username...."
                      disabled
                      readOnly
                    ></input>
                  </div>
                  <div className="phoneAdder">
                    <div className="profileInputLabel" >
                      <p style={{ color: "#1e1e2d" }}>Business Name</p>
                      <p
                        style={{ color: "#009ef7", cursor: "pointer" }}
                        onClick={() => {
                          setBNEdit(!bNEdit);
                        }}
                      >
                        {!bNEdit ? "Save" : "Edit"}
                      </p>
                    </div>
                    <input
                      onChange={(e) => {
                        setBusinessName(e.target.value);
                      }}
                      value={businessName}
                      placeholder="Business Name...."
                      disabled={bNEdit}
                    ></input>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    flexBasis: "100%",
                    width:"100%"
                  }}
                >
                  <div className="businessNameAdder">
                    <div className="profileInputLabel">
                      <p style={{ color: "#1e1e2d" }}>Phone Number</p>
                      {/* <p
                        style={{ color: '#009ef7', cursor: 'pointer' }}
                        onClick={() => {
                          setPNEdit(!pNEdit)
                        }}
                      >
                        {!pNEdit ? 'Save' : 'Edit'}
                      </p> */}
                    </div>
                    <input
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      value={phoneNumber}
                      placeholder="Phone Number...."
                      disabled={pNEdit}
                    ></input>
                  </div>
                  <div className="categoryAdder">
                    <div className="profileInputLabel">
                      <p style={{ color: "#1e1e2d" }}>Category</p>
                      <p
                        style={{ color: "#009ef7", cursor: "pointer" }}
                        onClick={() => {
                          if (bcEdit) {
                            // console.log(
                            //   "Selected Category:",
                            //   selectedId
                            // );
                          }
                          setBCEdit(!bcEdit);
                        }}
                      >
                        {!bcEdit ? "Edit" : "Save"}
                      </p>
                    </div>
                    {bcEdit ? (
                      // Dropdown when editing is enabled
                      <>
                        <select
                          onChange={(e) => {
                            handleCategorySelect(e.target.value);
                            console.log(
                              "Selected value:",
                              Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              )
                            );
                          }}
                          //value="Select Shop Category"
                          disabled={!bcEdit}
                          className="categorySelect"
                        >
                          {categories.map((cat) => (
                            <option key={cat.title} value={cat.title}>
                              {cat.title}
                            </option>
                          ))}
                        </select>
                        <div className="selectedId mt-2">
                          {selectedTitle.map((title, index) => (
                            <span
                              key={index}
                              className="inline-block mr-2 my-1 px-2 py-1 bg-gray-200 text-gray-800 rounded cursor-pointer"
                              onClick={() => handleRemoveCategory(title)}
                            >
                              {title}
                              <span
                                className="ml-2 text-red-600 font-bold"
                                style={{ cursor: "pointer" }}
                              >
                                &times;
                              </span>
                            </span>
                          ))}
                        </div>
                      </>
                    ) : (
                      // Input field when editing is disabled
                      <input
                        className="overflow-visible"
                        value={selectedTitle}
                        placeholder="Category..."
                        disabled={!bcEdit}
                        readOnly
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="editProfileFormSection">
            <div className="editProfileSecTitle">Information</div>
            <div className="editProfileSecContent2">
              <div
                className="line1EditSection"
                style={{ display: "flex", gap: "20px" }}
              >
                <div className="firstNameAdder">
                  <p>First Name</p>
                  <input placeholder="First Name...." value={firstName} onChange={(e) => {
                    setFirstName(e.target.value);

                  }}></input>
                </div>
                <div className="lasttNameAdder">
                  <p>Last Name</p>
                  <input
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    placeholder="Last Name...."
                  ></input>
                </div>
                <div className="mailAdder">
                  <p>Email Id</p>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Email Id...."
                  ></input>
                </div>
              </div>
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                <div className="aboutStoreTitle font-semibold">About Store</div>
                <button style={{color:'#009ef7'}} onClick={handleGenerateClick}>{loading ? 'Generating...' :'Generate Description through AI?'}</button>
                </div>
                <ReactQuill
                  theme="snow"
                  style={{
                    textAlign: "left",
                    color: "#000000",
                  }}
                  value={about}
                  placeholder="Type your text here........"
                  onChange={(content) => {
                    console.log("c", content);
                    setAbout(content)
                  }}
                ></ReactQuill>
                <div
                  style={{
                    fontSize: "12px",
                    color: "#a1a5b7",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Max 250 words
                </div>
              </div>
            </div>
          </div>
          <div className="editProfileFormSection">
            <div className="editProfileSecTitle">Address</div>
            <div className="editProfileSecContent2">
              <div className="line">
                <div className="inputAndLabel">
                  <div className="label">Address Line 1</div>
                  <input
                    value={addLine1}
                    onChange={(e) => {
                      setAddLine1(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="inputAndLabel">
                  <div className="label">Address Line 2</div>
                  <input
                    value={addLine2}
                    onChange={(e) => {
                      setAddLine2(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="inputAndLabel">
                  <div className="label">Country</div>
                  <CountryDropdown
                    value="India"
                    className="countryDropdown"
                    onChange={(e) => setCountry(e)}
                    disabled
                    readOnly
                  ></CountryDropdown>
                </div>
              </div>
              <div className="line">
                {/* <div className='inputAndLabel'>
                  <div className='label'>Country</div>
                  <CountryDropdown
                    value={country}
                    className='countryDropdown'
                    onChange={(e) => setCountry(e)}
                  ></CountryDropdown>
                </div> */}
                <div className="inputAndLabel">
                  <div className="label">State</div>
                  <RegionDropdown
                    className="stateDropdown"
                    country="India"
                    value={state}
                    onChange={(e) => {
                      setState(e);
                    }}
                  ></RegionDropdown>
                </div>
                <div className="inputAndLabel">
                  <div className="label">City</div>
                  <input
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    value={city}
                  ></input>
                </div>
                <div className="inputAndLabel">
                  <div className="label">Zip</div>
                  <input
                    onChange={(e) => {
                      setZip(e.target.value);
                    }}
                    value={zip}
                  ></input>
                </div>
              </div>
              {/* <div className='line'>
                <div className='inputAndLabel'>
                  <div className='label'>City</div>
                  <input
                    onChange={(e) => {
                      setCity(e.target.value)
                    }}
                    value={city}
                  ></input>
                </div>
                <div className='inputAndLabel'>
                  <div className='label'>Zip</div>
                  <input
                    onChange={(e) => {
                      setZip(e.target.value)
                    }}
                    value={zip}
                  ></input>
                </div>
              </div> */}
            </div>
          </div>
          <div className="cancelAndSaveButton">
            {/* <div className='cancelButton'>Cancel</div> */}
            <div
              className="saveButton"
              onClick={() => {
                console.log("selected", selectedTitle);
                console.log("naam", lastName);
                profileEditor(
                  profileImage,
                  username,
                  phoneNumber,
                  businessName,
                  firstName,
                  lastName,
                  email,
                  addLine1,
                  addLine2,
                  city,
                  state,
                  country,
                  zip,
                  categories,
                  selectedTitle,
                  about
                );
              }}
            >
              Save Changes
            </div>
          </div>
        </div>
      )}
      {type === "storeStatus" && <StoreStatusForm Form></StoreStatusForm>}
      {/* {type === "privacyPolicy" && <Coming_soon></Coming_soon>} */}
      {type === "socialMedia" && <Social></Social>}
      {type === "storeTiming" && <Coming_soon></Coming_soon>}
      {type === "shippingAndDelivery" && <ShippingAndDeliveryForm></ShippingAndDeliveryForm>}
      {type === "warehouse" && <Coming_soon></Coming_soon>}

      {type === 'privacyPolicy' && <PrivacyPolicyForm></PrivacyPolicyForm>}
      {/* {type === 'socialMedia' && <SocialMediaForm></SocialMediaForm>} */}
      {/* {type === 'storeTiming' && <StoreTimingForm></StoreTimingForm>} */}
      {/* {type === 'shippingAndDelivery' && (
        <ShippingAndDeliveryForm></ShippingAndDeliveryForm> */}
      {/* )} */}
    </>
  );
}

export default EditProfileForm;
