import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { redirect } from 'react-router-dom'
import axios from 'axios'


export const createProduct = async (
  featureImage,
  images,
  productCategory,
  productName,
  productStatus,
  unit,
  salesPrice,
  mrp,
  description,
  stock,
  label,
  setNameWarning,
  setPriceWarning,
  setLoading,
  setMrpAndPriceWarning
) => {
  if (productName.trim().length === 0 && mrp === 0) {
    setLoading(false)
    setNameWarning(true)
    setPriceWarning(true)
    return
  }
  // if (productName.trim().length === 0) {
  //   setLoading(false)
  //   setNameWarning(true)
  //   return
  // }
  if (mrp === 0) {
    setLoading(false)
    setNameWarning(true)
    return
  }
  if (mrp < salesPrice) {
    setLoading(false)
    setMrpAndPriceWarning(true)
    return
  }
  setLoading(true)
  const formdata = new FormData()
  if (featureImage) {
    formdata.append('photo', featureImage)
  }
  if (salesPrice === 0 && mrp != 0) {
    salesPrice = mrp
  }
  images.map((item) => {
    if (item) {
      formdata.append('photo', item)
    }
  })
  formdata.append('title', productName)
  formdata.append('status', productStatus)
  formdata.append('cat_id', productCategory)
  formdata.append('desc', description)
  formdata.append('mrp', mrp)
  formdata.append('sales_price', salesPrice)
  formdata.append('trends', label)
  formdata.append('unit', unit)
  formdata.append('unit_count', stock)
  console.log("formdata",formdata);
  try {
    const response = await axios.post(`${BASE_URL}/vendor/catalog/product/create`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${cookieFetcher('token')}`,
      },
    });

    if (response.status === 200 && response.data) {
      Swal.fire({
        icon: 'success',
        title: 'Product Created',
        text: `Successfully Created!`,
        confirmButtonText: 'Go to Products!',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/products';
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed, try again',
        text: `${response.status} : ${response.data?.message || 'Unknown error'}`,
      });
    }
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: `Internal Server Error: ${error.message}`,
    });
  } finally {
    setLoading(false);
  }
};