import React from 'react';
import './ComingSoon.css';

const Coming_soon = () => {
  return (
    <div className="coming-soon-container">
    <div className="coming-soon-box">
      <h1>Coming Soon</h1>
      <p>We're working hard to bring you the best coupon codes. Stay tuned!</p>
      {/* <img src="https://via.placeholder.com/400x200" alt="Coming Soon" class="mx-auto mb-6 rounded"> */}
      <p className="thank-you">Thank you for your patience.</p>
    </div>
  </div>
  );
}

export default Coming_soon;
