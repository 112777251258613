import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

function FourImageSelector({ images, setImages }) {
  const defaultImage1 =
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  const [firstCall, setFirstCall] = useState(true)
  const [featureImage1, setFeatureImage1] = useState(defaultImage1)
  const [featureImage2, setFeatureImage2] = useState(defaultImage1)
  const [featureImage3, setFeatureImage3] = useState(defaultImage1)
  const [featureImage4, setFeatureImage4] = useState(defaultImage1)

  const inputFile1 = useRef(null)
  const inputFile2 = useRef(null)
  const inputFile3 = useRef(null)
  const inputFile4 = useRef(null)

  const handleClick = (e, index) => {
    if (index === 1) {
      inputFile1.current.click()
    }
    if (index === 2) {
      inputFile2.current.click()
    }
    if (index === 3) {
      inputFile3.current.click()
    }
    if (index === 4) {
      inputFile4.current.click()
    }
  }

  const handleFileChange = (e, index) => {
    let arr = new Array(4)
    arr = images
    if (index === 1) {
      const fileObj = e.target.files && e.target.files[0]
      if (!fileObj) {
        return
      }
      setFeatureImage1(URL.createObjectURL(e.target.files[0]))
      arr[index] = e.target.files[0]
      console.log('First', arr)
      setImages(arr)
    }
    if (index === 2) {
      const fileObj = e.target.files && e.target.files[0]
      if (!fileObj) {
        return
      }
      setFeatureImage2(URL.createObjectURL(e.target.files[0]))
      arr[index] = e.target.files[0]
      console.log('Second', arr)
      setImages(arr)
    }
    if (index === 3) {
      const fileObj = e.target.files && e.target.files[0]
      if (!fileObj) {
        return
      }
      setFeatureImage3(URL.createObjectURL(e.target.files[0]))
      arr[index] = e.target.files[0]
      console.log('Third', arr)
      setImages(arr)
    }
    if (index === 4) {
      const fileObj = e.target.files && e.target.files[0]
      if (!fileObj) {
        return
      }
      setFeatureImage4(URL.createObjectURL(e.target.files[0]))
      arr[index] = e.target.files[0]
      console.log('Fourth', arr)
      setImages(arr)
    }
  }

  const handleFileDelete = (e, index) => {
    let arr = new Array(5)
    arr = images
    if (index === 1) {
      arr[1] = null
      setFeatureImage1(defaultImage1)
      console.log('First', arr)
      setImages(arr)
    }
    if (index === 2) {
      arr[2] = null
      setFeatureImage2(defaultImage1)
      console.log('Second', arr)
      setImages(arr)
    }
    if (index === 3) {
      arr[3] = null
      setFeatureImage3(defaultImage1)
      console.log('Third', arr)
      setImages(arr)
    }
    if (index == 4) {
      arr[4] = null
      setFeatureImage4(defaultImage1)
      console.log('Fourth', arr)
      setImages(arr)
    }
  }

  useEffect(() => {
    if (images && images.length > 0) {
      images.map((item, index) => {
        if (item) {
          if (index === 1 && item.location) {
            setFeatureImage1(item.location)
          }
          if (index === 2 && item.location) {
            setFeatureImage2(item.location)
          }
          if (index === 3 && item.location) {
            setFeatureImage3(item.location)
          }
          if (index === 4 && item.location) {
            setFeatureImage4(item.location)
          }
        }
      })
    }
  }, [images])

  useEffect(() => {
    console.log(`Printing images`, images)
  }, [setImages, images])

  return (
    <div className='fourImageSelector'>
      <div className='row1ImageSelector'>
        <div className='firstImage imageHolder4Select'>
          <input
            type='file'
            style={{ display: 'none' }}
            ref={inputFile1}
            onChange={(e) => {
              handleFileChange(e, 1)
            }}
          ></input>
          <img
            onClick={(e) => {
              handleClick(e, 1)
            }}
            src={featureImage1}
            style={{
              border: 'dashed #009ef7 1px',
              borderRadius: '5px',
              boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
              width: '100px',
              height: '100px',
            }}
          ></img>
          <div className='editorIcons'>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '009ef7', cursor: 'pointer' }}
                icon={faEdit}
                onClick={(e) => {
                  handleClick(e, 1)
                }}
              ></FontAwesomeIcon>
            </div>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '#D70040', cursor: 'pointer' }}
                icon={faTrash}
                onClick={(e) => handleFileDelete(e, 1)}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
        <div className='secondImage imageHolder4Select'>
          <input
            type='file'
            style={{ display: 'none' }}
            ref={inputFile2}
            onChange={(e) => {
              handleFileChange(e, 2)
            }}
          ></input>
          <img
            onClick={(e) => {
              handleClick(e, 2)
            }}
            src={featureImage2}
            style={{
              border: 'dashed #009ef7 1px',
              borderRadius: '5px',
              boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
              width: '100px',
              height: '100px',
            }}
          ></img>
          <div className='editorIcons'>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '009ef7', cursor: 'pointer' }}
                icon={faEdit}
                onClick={(e) => {
                  handleClick(e, 2)
                }}
              ></FontAwesomeIcon>
            </div>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '#D70040', cursor: 'pointer' }}
                icon={faTrash}
                onClick={(e) => handleFileDelete(e, 2)}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>
      <div className='row2ImageSelector'>
        <div className='thirdImage imageHolder4Select'>
          <input
            type='file'
            style={{ display: 'none' }}
            ref={inputFile3}
            onChange={(e) => {
              handleFileChange(e, 3)
            }}
          ></input>
          <img
            onClick={(e) => {
              handleClick(e, 3)
            }}
            src={featureImage3}
            style={{
              border: 'dashed #009ef7 1px',
              borderRadius: '5px',
              boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
              width: '100px',
              height: '100px',
            }}
          ></img>
          <div className='editorIcons'>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '009ef7', cursor: 'pointer' }}
                icon={faEdit}
                onClick={(e) => {
                  handleClick(e, 3)
                }}
              ></FontAwesomeIcon>
            </div>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '#D70040', cursor: 'pointer' }}
                icon={faTrash}
                onClick={(e) => handleFileDelete(e, 3)}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
        <div className='fourthImage imageHolder4Select'>
          <input
            type='file'
            style={{ display: 'none' }}
            ref={inputFile4}
            onChange={(e) => {
              handleFileChange(e, 4)
            }}
          ></input>
          <img
            onClick={(e) => {
              handleClick(e, 4)
            }}
            src={featureImage4}
            style={{
              border: 'dashed #009ef7 1px',
              borderRadius: '5px',
              boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
              width: '100px',
              height: '100px',
            }}
          ></img>
          <div className='editorIcons'>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '009ef7', cursor: 'pointer' }}
                icon={faEdit}
                onClick={(e) => {
                  handleClick(e, 4)
                }}
              ></FontAwesomeIcon>
            </div>
            <div className='surroundIcon'>
              {' '}
              <FontAwesomeIcon
                style={{ color: '#D70040', cursor: 'pointer' }}
                icon={faTrash}
                onClick={(e) => handleFileDelete(e, 4)}
              ></FontAwesomeIcon>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FourImageSelector
