import React, { useEffect, useState } from 'react'
import DayDropdown from '../../Dropdowns/DaysDropdown'
import TimePicker from 'react-time-picker'
import TimeField from 'react-simple-timefield'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import Condition from './Condtions'
// import { TimePicker } from '@mui/x-date-pickers'

function StoreTimingForm() {
  const [listOfConditions, setlistOfConditions] = useState([
    { day: '', toTime: '00:00', fromTime: '00:00' },
  ])

  const adder = () => {
    let copy = listOfConditions
    console.log(JSON.stringify(copy))
    setlistOfConditions(copy)
  }

  useEffect(() => {}, [listOfConditions])

  return (
    <div className='storeTimingForm'>
      <div className='storeTimingFormComponent'>
        <div className='storeTimingFormTitle'>Store Timings</div>
        <div className='storeTimingFormContent'>
          {listOfConditions.map((item, index) => {
            return (
              <Condition
                day={item.day}
                toTime={item.toTime}
                fromTime={item.fromTime}
              ></Condition>
            )
          })}
        </div>
        <div
          className='anotherConditions'
          onClick={() => {
            adder()
          }}
        >
          <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>Add another condition
        </div>
      </div>
    </div>
  )
}

export default StoreTimingForm
