import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { redirect } from 'react-router-dom'

export const editCatalog = async (
  featureImage,
  imgChanged,
  // arrayImages,
  catalogName,
  catalogStatus,
  id,
  catalogNameChanged
) => {
  if(catalogName.length==0){
    alert("Category Name cannot be empty");
    return;
  }
  const formdata = new FormData()
  console.log("name changed, id, name",catalogNameChanged,id,catalogName);
  if (featureImage && imgChanged===true) formdata.append('photos', featureImage);
  if (catalogNameChanged) formdata.append('title', catalogName)
  formdata.append('status', catalogStatus)

 try {
  const response = await fetch(`${BASE_URL}/category/shop/${id}`, {
    method: 'PATCH',
    headers: {
      'Authorization': `Bearer ${cookieFetcher('token')}`,
    },
    body: formdata, // Use the FormData object as the request body
  })
  const data = await response.json()
  console.log("updated",data);
    Swal.fire({
      icon: 'success',
      text: `catalog updated successfully!`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = '/category';
      }
    });
  
 } catch (error) {
  console.log("eror updating",error);
 } 
}
