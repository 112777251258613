import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import { BASE_URL, cookieFetcher, privacyPolicyTemp } from '../../../helper'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import {
  cancellationPolicyEditor,
  privacyPolicyEditor,
  refundPolicyEditor,
  shippingPolicyEditor,
  termsEditor,
} from '../ProfileEditor/PolicyEditors'

function PrivacyPolicyForm() {
  const scrollToDiv = (id) => {
    const targetDiv = document.getElementById(id)
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const fetcherPrivacy = async () => {
    const privacy = await fetch(`${BASE_URL}/vendor/policy/privacy`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const privacyRes = await privacy.json()
    if (privacyRes.status === 201) {
      if (!privacyRes.data) {
        setShowCreate(true)
      }
      if (privacyRes.data && privacyRes.data.privacy_policy) {
        console.log('privacyRes=========>', privacyRes)
        setPPText(privacyRes.data.privacy_policy)
        setPolicyId(privacyRes.data._id)
      }
    }
  }

  const fetcherCancel = async () => {
    const cancellation = await fetch(`${BASE_URL}/vendor/policy/cancellation`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const cancellationRes = await cancellation.json()
    if (cancellationRes.status === 201) {
      if (!cancellationRes.data) {
        setShowCreate(true)
      }
      console.log('cancellationRes=========>', cancellationRes)
      if (cancellationRes.data && cancellationRes.data.cancellation_policy) {
        setCancellationPolicy(cancellationRes.data.cancellation_policy)
        setPolicyId(cancellationRes.data._id)
      }
    }
  }

  const fetcherShipping = async () => {
    const shipping = await fetch(`${BASE_URL}/vendor/policy/shipping`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const shippingRes = await shipping.json()
    if (shippingRes.status === 201) {
      if (!shippingRes.data) {
        setShowCreate(true)
      }
      console.log('shippingRes=========>', shippingRes)
      if (shippingRes.data && shippingRes.data.shipping_policy) {
        setShippingPolicy(shippingRes.data.shipping_policy)
        setPolicyId(shippingRes.data._id)
      }
    }
  }
  const fetcherTerms = async () => {
    const termsAndConditions = await fetch(`${BASE_URL}/vendor/policy/terms`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const termsRes = await termsAndConditions.json()
    if (termsRes.status === 201) {
      if (!termsRes.data) {
        setShowCreate(true)
      }
      console.log('termsRes=========>', termsRes)
      if (termsRes.data && termsRes.data.terms_condition) {
        setTcText(termsRes.data.terms_condition)
        setPolicyId(termsRes.data._id)
      }
    }
  }

  const fetcherRefund = async () => {
    const refund = await fetch(`${BASE_URL}/vendor/policy/refund`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const refundRes = await refund.json()
    if (refundRes.status === 201) {
      if (!refundRes.data) {
        setShowCreate(true)
      }
      if (refundRes.data && refundRes.data.refund_policy) {
        console.log('refundRes=========>', refundRes)
        setReturnAndRefundText(refundRes.data.refund_policy)
        setPolicyId(refundRes.data._id)
      }
    }
  }

  useEffect(() => {
    fetcherRefund()
    fetcherCancel()
    fetcherPrivacy()
    fetcherShipping()
    fetcherTerms()
  }, [])

  const [returnAndRefundText, setReturnAndRefundText] = useState('')
  const [ppText, setPPText] = useState('')
  const [tcText, setTcText] = useState('')
  const [shippingPolicy, setShippingPolicy] = useState('')
  const [cancellationPolicy, setCancellationPolicy] = useState('')
  const [showCreate, setShowCreate] = useState(false)
  const [policyId, setPolicyId] = useState('')

  return (
    <div className='privacyPolicyForm'>
      <div className='privacyPolicyFormComponent privacyPolicyFormComponent1'>
        <div className='privacyPolicyInner'>
          <div
            className='scrollButton'
            onClick={() => {
              scrollToDiv('returnAndRefund')
            }}
          >
            Return & Refund Policy
          </div>
          <div
            className='scrollButton'
            onClick={() => {
              scrollToDiv('privacyPolicy')
            }}
          >
            Privacy Policy
          </div>
          <div
            className='scrollButton'
            onClick={() => {
              scrollToDiv('termsAndCondition')
            }}
          >
            Terms & Conditions
          </div>
          <div
            className='scrollButton'
            onClick={() => {
              scrollToDiv('shippingPolicy')
            }}
          >
            Shipping Policy
          </div>
          <div
            className='scrollButton'
            onClick={() => {
              scrollToDiv('cancellationPolicy')
            }}
          >
            Cancellation Policy
          </div>
        </div>
      </div>
      <div
        className='privacyPolicyFormComponent2 privacyPolicyFormComponent'
        id='returnAndRefund'
      >
        <div className='policiesTitle'>Policies</div>
        <div className='policiesSubtitle'>Return and Refund Policy</div>
        <ReactQuill
          value={returnAndRefundText}
          onChange={(e) => {
            setReturnAndRefundText(e)
          }}
          theme='snow'
          style={{
            textAlign: 'left',
            color: '#a1a5b7',
          }}
          placeholder='Type your text here........'
        ></ReactQuill>
        <div className='buttonHolders'>
          <div
            className='createFromTemplateButton'
            onClick={() => {
              setReturnAndRefundText(privacyPolicyTemp)
            }}
          >
            Create from template
          </div>
          {showCreate ? (
            <div className='createFromTemplateButton' onClick={() => {}}>
              Create Refund Policy
            </div>
          ) : (
            <div
              className='createFromTemplateButton'
              onClick={() => {
                refundPolicyEditor('refund', returnAndRefundText, policyId)
              }}
            >
              Edit Refund Policy
            </div>
          )}
        </div>
      </div>
      <div className='privacyPolicyFormComponent' id='privacyPolicy'>
        <div className='policiesSubtitle'>Privacy Policy</div>
        <ReactQuill
          value={ppText}
          onChange={(e) => {
            setPPText(e)
          }}
          theme='snow'
          style={{
            textAlign: 'left',
            color: '#a1a5b7',
          }}
          placeholder='Type your text here........'
        ></ReactQuill>
        <div className='buttonHolders'>
          <div
            className='createFromTemplateButton'
            onClick={() => {
              setPPText(privacyPolicyTemp)
            }}
          >
            Create from template
          </div>
          {showCreate ? (
            <div className='createFromTemplateButton' onClick={() => {}}>
              Create Privacy Policy
            </div>
          ) : (
            <div
              className='createFromTemplateButton'
              onClick={() => {
                privacyPolicyEditor('privacy', ppText, policyId)
              }}
            >
              Edit Privacy Policy
            </div>
          )}
        </div>
      </div>
      <div className='privacyPolicyFormComponent' id='termsAndCondition'>
        <div className='policiesSubtitle'>Terms and Conditions</div>
        <ReactQuill
          value={tcText}
          onChange={(e) => {
            setTcText(e)
          }}
          theme='snow'
          style={{
            textAlign: 'left',
            color: '#a1a5b7',
          }}
          placeholder='Type your text here........'
        ></ReactQuill>
        <div className='buttonHolders'>
          <div
            className='createFromTemplateButton'
            onClick={() => {
              setTcText(privacyPolicyTemp)
            }}
          >
            Create from template
          </div>
          {showCreate ? (
            <div className='createFromTemplateButton' onClick={() => {}}>
              Create Terms and Conditions
            </div>
          ) : (
            <div
              className='createFromTemplateButton'
              onClick={() => {
                termsEditor('terms', tcText, policyId)
              }}
            >
              Edit Terms and Conditions
            </div>
          )}
        </div>
      </div>
      <div className='privacyPolicyFormComponent' id='shippingPolicy'>
        <div className='policiesSubtitle'>Shipping Policy</div>
        <ReactQuill
          value={shippingPolicy}
          onChange={(e) => {
            setShippingPolicy(e)
          }}
          theme='snow'
          style={{
            textAlign: 'left',
            color: '#a1a5b7',
          }}
          placeholder='Type your text here........'
        ></ReactQuill>
        <div className='buttonHolders'>
          <div
            className='createFromTemplateButton'
            onClick={() => {
              setShippingPolicy(privacyPolicyTemp)
            }}
          >
            Create from template
          </div>
          <div
            className='createFromTemplateButton'
            onClick={() => {
              shippingPolicyEditor('shipping', shippingPolicy, policyId)
            }}
          >
            Edit Shipping Policy
          </div>
        </div>
      </div>
      <div className='privacyPolicyFormComponent' id='cancellationPolicy'>
        <div className='policiesSubtitle'>Cancellation Policy</div>
        <ReactQuill
          value={cancellationPolicy}
          onChange={(e) => {
            setCancellationPolicy(e)
          }}
          theme='snow'
          style={{
            textAlign: 'left',
            color: '#a1a5b7',
          }}
          placeholder='Type your text here........'
        ></ReactQuill>
        <div className='buttonHolders'>
          <div
            className='createFromTemplateButton'
            onClick={() => {
              setCancellationPolicy(privacyPolicyTemp)
            }}
          >
            Create from template
          </div>
          {showCreate ? (
            <div className='createFromTemplateButton' onClick={() => {}}>
              Create Cancellation Policy
            </div>
          ) : (
            <div
              className='createFromTemplateButton'
              onClick={() => {
                cancellationPolicyEditor(
                  'cancellation',
                  cancellationPolicy,
                  policyId
                )
              }}
            >
              Edit Cancellation Policy
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyForm
