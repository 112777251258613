import React, { useEffect, useState } from 'react'
import './OrderDetails.css'
import { BASE_URL, cookieFetcher, loginChecker } from '../../helper'
import { useParams } from 'react-router-dom'
import { Toast } from 'reactstrap'
import { toast } from 'react-hot-toast'
import Swal from 'sweetalert2'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrderDetailsMain from './OrderDetailsMain'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { APP_URL } from '../../helper'

function OrderDetails() {
  const { orderId } = useParams()
  const [order, setOrder] = useState({})
  const [subtotal, setSubtotal] = useState(0)
  const [shippingrate,setShippingrate]=useState(0)
  const [date,setDate]=useState(0)
  const [status,setStatus]=useState("")
  const [statusDate,setStatusDate]=useState(0)
  const [name,setName]=useState('')
  const [phone,setPhone]=useState('')
  const [quantity,setQuantity]=useState(0)
  const [mrp,setMrp]=useState(0)
  const [sprice,setSprice]=useState(0)
  const [productTitle,setProductTitle]=useState("")
  const [city,setCity]=useState("")
  const [state,setState]=useState("")
  const [country,setCountry]=useState("")
  const [zip,setZip]=useState("")
  const [add1,setAdd1]=useState("")
  const [add2,setAdd2]=useState("")
  const [prodImg,setProdImg]=useState("")
  const [url,setUrl]=useState("")
  


  useEffect(() => {
    loginChecker()
    const fetcher = async () => {
      console.log("token", cookieFetcher('token'));
      console.log("id", orderId);
      const res = await axios.get(
        `${BASE_URL}/order/vendor/${orderId}`,
        {
          headers: {
            'Authorization': `Bearer ${cookieFetcher('token')}`,
          },
          mode: "cors",
          cache: 'default'
        }
      )
      console.log("response order", res);
      if (res.status === 200) {
        setOrder(res.data[res.data.length - 1])   //last index
        console.log("orderdata", res.data[res.data.length - 1]);
        let value,amount = 0;
        const lastItem = res.data[res.data.length - 1];
        if (lastItem && lastItem.order_id) {
          value = lastItem.order_id.total;
        }
        setSubtotal(value)
        if (lastItem && lastItem.order_id && lastItem.order_id.shipping_charge) {
          amount = lastItem.order_id.shipping_charge.amount;
        }
        setShippingrate(amount)
        setDate(res.data[res.data.length - 1].createdAt)
        setStatus(res.data[res.data.length - 1].order_id.status[res.data[res.data.length - 1].order_id.status.length-1].type)
        setStatusDate(res.data[res.data.length - 1].order_id.status[res.data[res.data.length - 1].order_id.status.length-1].updatedAt)
        const fullName = `${res.data[res.data.length - 1].order_id.vendor_has_customer_id.customer_id.profile_id.name.first} ${res.data[res.data.length - 1].order_id.vendor_has_customer_id.customer_id.profile_id.name.last}`;
        setName(fullName)
        setPhone(res.data[res.data.length - 1].order_id.vendor_has_customer_id.customer_id.profile_id.phone)
        setQuantity(res.data[res.data.length - 1].quantity)
        setMrp(res.data[res.data.length - 1].product.mrp)
        setSprice(res.data[res.data.length - 1].product.sales_price)
        setProductTitle(res.data[res.data.length - 1].product.title)
        setAdd1(res.data[res.data.length - 1].order_id.shipping_location.address[0])
        setAdd2(res.data[res.data.length - 1].order_id.shipping_location.address[1])
        setCity(res.data[res.data.length - 1].order_id.shipping_location.city)
        setState(res.data[res.data.length - 1].order_id.shipping_location.state)
        setCountry(res.data[res.data.length - 1].order_id.shipping_location.country)
        setZip(res.data[res.data.length - 1].order_id.shipping_location.zip_code)
        setProdImg(res.data[res.data.length - 1].product.featured_img)
        const fullUrl = `${APP_URL}/${cookieFetcher('username')}/category/catlalogup/product/${res.data[res.data.length - 1].product.url}`;
        setUrl(fullUrl)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong!',
          text: `${res.status}: ${res.message}`,
        })
      }
    }
    fetcher()
  }, [])
  const [sidebar, setSidebar] = useState(true)
  
  return (
    <div className='orderDetailsPage'>
      <Helmet
        title='Order Details'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <OrderDetailsMain
        order={order}
        orderId={orderId}
        sidebar={sidebar}
        setSidebar={setSidebar}
        subtotal={subtotal}
        shippingrate={shippingrate}
        date={date}
        status={status}
        name={name}
        phone={phone}
        quantity={quantity}
        mrp={mrp}
        sprice={sprice}
        statusDate={statusDate}
        productTitle={productTitle}
        add1={add1}
        add2={add2}
        city={city}
        state={state}
        country={country}
        zip={zip}
    url={url}
      ></OrderDetailsMain>
    </div>
  )
}

export default OrderDetails
