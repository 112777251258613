import React from 'react'
import './Setting.css'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useState } from 'react'
import NavigationBoxSettings from '../../components/NavigationBox/NavigationBoxSettings'
import EditProfileForm from '../../components/EditProfileForm/EditProfileForm'
import { Helmet } from 'react-helmet'
import { cookieFetcher } from '../../helper'

function ShippingAndDeliverySettings() {
  const [sidebar, setSidebar] = useState(true)

  return (
    <div className='settingsPage'>
      <Helmet
        title='Shipping and Delivery'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar} page={'settings'}></Sidebar>
      <div className='settingsMain'>
        <div className='settingsMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          {/* <div>Settings Main Section</div> */}
          <div className='settingsMainSection'>
            {/* <NavigationBoxSettings></NavigationBoxSettings> */}
            <EditProfileForm type={'shippingAndDelivery'}></EditProfileForm>
          </div>
        </div>
        <div className='settingsMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default ShippingAndDeliverySettings
