import React, { useEffect, useRef, useState } from 'react'

import { components } from '../../pages/Orders/OrdersHelper'
import Select from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FourImageSelector from './FourImageSelector';
import { createCatalog } from './CatalogCreator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faL, faSpinner } from '@fortawesome/free-solid-svg-icons'
import NavigationBox from '../NavigationBox/NavigationBox'
import { encode } from 'base-64'
import { APP_URL, cookieFetcher, generateAboutText } from '../../helper'
import { useNavigate } from 'react-router-dom';
import './AddCatalogForm.css'

function AddCatalogForm() {
  const [sMTitle, setSMTitle] = useState('')
  const [sMSlug, setSMSlug] = useState('')
  const [sMDesc, setSMDesc] = useState('')
  const [imgalt, setImgalt] = useState('')
  const [banneralt, setBanneralt] = useState('')
  const [sMMetaTags, setSMMetaTags] = useState([])
  const [sMMetaTagsInterim, setSMMetaTagsInterim] = useState('')
  const [loadingGen, setLoadingGen] = useState(false);
  const [loadingGenforTitle, setLoadingGenforTitle] = useState(false);
  const navigate = useNavigate();

  const [featureImageLink, setFeatureImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [bannerImageLink, setBannerImageLink] = useState(
    '/images/ban.png'
  )
  const [featureImage, setFeatureImage] = useState(
    new Array(1)
  )
  const [bannerImage, setBannerImage] = useState(
    new Array(1)
  )
  const [filesSelected, setFilesSelected] = useState(false)
  const [images, setImages] = useState(new Array(4))
  const [catalogName, setCatalogName] = useState('')
  const [catalogStatus, setCatalogStatus] = useState(true)
  const [loading, setLoading] = useState(false)
  const [errorkeymsg, setErrorkeymsg] = useState(false)
  const maxLength = 60;       //max length for input
  const maxLengthDes = 160;

  const inputFile = useRef(null)
  const inputFile2 = useRef(null)
  const inputMultipleFile = useRef(null)

  const handleChangeInput = (e) => {
    const value = e.target.value;
    if (value.length <= maxLength) {
      setCatalogName(value);
    }
  };

  const getPlainTextLength = (htmlString) => {
    // Remove all HTML tags using a regular expression
    const plainText = htmlString.replace(/<[^>]*>/g, '');
    return plainText.length;
  };

  const handle5keywords = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newTag = e.target.value.trim();
      if (newTag.length > 0 && sMMetaTags.length < 5) {
        setSMMetaTags([...sMMetaTags, newTag]);
        setSMMetaTagsInterim('');
        setErrorkeymsg('');
      } else if (sMMetaTags.length >= 5) {
        setErrorkeymsg('You can add up to 5 keywords only.');
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission or any default behavior
      const trimmedTag = sMMetaTagsInterim.trim();
      if (trimmedTag.length > 0 && !sMMetaTags.includes(trimmedTag)) {
        setSMMetaTags([...sMMetaTags, trimmedTag]);
      }
      setSMMetaTagsInterim(''); // Clear the input after setting the tags
    }
  };

  useEffect(() => {
    if (sMMetaTags.length < 5) setErrorkeymsg(false);
  }, [sMMetaTags]);

  const handleChangeInputMetaDes = (e) => {
    const value = e;
    if (value.length <= maxLengthDes) {
      setSMDesc(value);
    }
  };

  const handleChangeInputMetaTitle = (e) => {
    const value = e.target.value;
    if (value.length <= maxLength) {
      setSMTitle(value)
    }
  }

  const handleChangeInputMetaUrl = (e) => {
    const value = e.target.value;
    if (value.length <= maxLength) {
      setSMSlug(value)
    }
  }

  function splitStringByComma(inputString) {
    // Use the split method to split the string by commas
    const wordsArray = inputString.split(',')

    // Trim whitespace from each word and remove empty strings
    const cleanedArray = wordsArray
      .map((word) => word.trim())
      .filter((word) => word !== '')

    return cleanedArray
  }

  const handleGenerateClick = async () => {
    if (!catalogName) {
      alert("Please enter a valid catalog name before generating meta description.")
      return
    }
    setLoadingGen(true);
    const generatedText = await generateAboutText(`Generate a SEO ranked Description for catalog : ${catalogName} in strictly less than 60 characters.`);
    if (generatedText != '') {
      setSMDesc(generatedText);
    }
    setLoadingGen(false);
  };

  const handleGenerateClickforTitle = async () => {
    if (!catalogName) {
      alert("Please enter a valid catalog name before generating meta description.")
      return
    }
    setLoadingGenforTitle(true);
    const generatedText = await generateAboutText(`Generate a SEO ranked title for catalog : ${catalogName} in strictly less than 25 characters.`);
    if (generatedText != '') {
      setSMTitle(generatedText);
    }
    setLoadingGenforTitle(false);
  };

  const checker = (s) => {
    if (s.includes(',')) {
      const arr = splitStringByComma(s)
      setSMMetaTags(arr)
    }
  }

  const removeTag = (tag) => {
    setSMMetaTags(sMMetaTags.filter(item => item !== tag));
  };



  const onButtonClick = (inputFiles) => inputFiles.current.click();

  const onButtonClickMultiple = () => inputMultipleFile.current.click();

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileChange1 = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    if (fileObj.size > 1048576) { // 1 MB
      alert("File size exceeds the 1 MB limit.");
      return;
    }
    const file = event.target.files[0]
    const myString = await convertBase64(file)

    var n = 23
    var substringToEnd = myString.substring(n)

    setFeatureImage(event.target.files[0])
    console.log("feature", event.target.files[0]);
    setFeatureImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleFileChange2 = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    if (fileObj.size > 1048576) { // 1 MB
      alert("File size exceeds the 1 MB limit.");
      return;
    }
    const file = event.target.files[0]
    const myString = await convertBase64(file)

    var n = 23
    var substringToEnd = myString.substring(n)

    setBannerImage(event.target.files[0])
    console.log("banner", event.target.files[0]);
    setBannerImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleMultipleFileChange = (e) => {
    if (e.target && e.target.files.length > 0) {
      let tempArr = new Array(4)
      if (e.target.files[0]) {
        tempArr[0] = e.target.files[0]
      }
      if (e.target.files[1]) {
        tempArr[1] = e.target.files[1]
      }
      if (e.target.files[2]) {
        tempArr[2] = e.target.files[2]
      }
      if (e.target.files[3]) {
        tempArr[3] = e.target.files[3]
      }
      setImages(tempArr)
      setFilesSelected(true)
    }
  }

  const handleCancelClick = () => {
    navigate('/category'); // Navigate to /categories
  };

  useEffect(() => {
    console.log("desc", sMDesc)
    console.log("metatags", sMMetaTags)
    console.log("title", sMTitle)
  }, [sMDesc, sMMetaTags, sMTitle])

  return (
    <div style={{ padding: '10px' }}>
      <NavigationBox
        catalogStatus={catalogStatus}
        setCatalogStatus={setCatalogStatus}
        type={'catalog'}
        addCatalogFeatureImage={featureImage}
        addCatalogName={catalogName}
        addCatalogImages={images}
        edit={false}
        catalogSeoTitle={sMTitle}
        catalogSeoDesc={sMDesc}
        catalogSeoTags={sMMetaTags}
      ></NavigationBox>
      <div className='addProductForm'>
        {/*Here begins the first part of the add product form where user inputs the media files related to the product */}
        <div
          className='addProductFormComponent productMediaForm'
          id='productMedia'
        >
          <div className='productMediaLeft flex-grow'>
            <div
              className='productMediaTitle'
              style={{
                textAlign: 'left',
                color: '#181c32',
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '23px',
                width: '150px',
                marginBottom: '0.3em'
              }}
            >
              Catalog Media
            </div>
            <div className='featureImageBottomText d-flex align-items-center' style={{ flexDirection: 'row' }}>
              <div
                style={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#181c32',
                  lineHeight: '17px',
                }}
              >
                Featured Image

              </div>
              <div className='tooltip-container'>
                <span class="material-symbols-outlined text-gray-500 ml-2">
                  info
                </span>
                <div className="tooltip">
                  1024 x 1024px <br />1:1 ratio<br />*less than 1MB
                </div>
              </div>

            </div>
            <div
              className='productMediaFeatureImage'
              onClick={() => onButtonClick(inputFile)}
            >
              <input
                type='file'
                style={{ display: 'none' }}
                ref={inputFile}
                onChange={(e) => {
                  handleFileChange1(e)
                }}

              ></input>
              <div className="tooltip-container">
                <img
                  src={featureImageLink}
                  style={{
                    border: 'dashed #009ef7 1px',
                    borderRadius: '5px',
                    width: '160px',
                    height: '160px',
                    marginTop: '-3px'
                  }}
                  className="shadow-sm"
                  alt={imgalt}
                />
                {/* <div className="tooltip">
                  1024 x 1024px <br />1:1 ratio<br />*less than 1MB
                </div> */}
              </div>
            </div>

            <div>
              <input type="text" style={{ padding: '5px', border: 'solid 1px #cccccc', width: '100%', background: 'transparent' }} placeholder='Img Alt' className='text-sm rounded-sm' value={imgalt} onChange={(e) => setImgalt(e.target.value)} />
            </div>


          </div>
          <div className='productMediaRight'>
            <div className='featureImageBottomText d-flex align-items-center' style={{ flexDirection: 'row' }}>
              <div
                style={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#181c32',
                  lineHeight: '17px',
                }}
              >
                Banner Image
              </div>
              <div className='tooltip-container'>
                <span class="material-symbols-outlined text-gray-500 ml-2">
                  info
                </span>
                <div className="tooltip">
                  1300 x 300px (recommended)
                </div>
              </div>

            </div>
            <div
              className='productMediaFeatureImage'
              onClick={() => onButtonClick(inputFile2)}
            >
              <input
                type='file'
                style={{ display: 'none' }}
                ref={inputFile2}
                onChange={(event) => {
                  console.log("b", event);
                  handleFileChange2(event)
                }}
              ></input>
              <div className="tooltip-container">
                <img
                  src={bannerImageLink}
                  style={{
                    display: 'block',
                    border: 'dashed #009ef7 1px',
                    borderRadius: '5px',
                    width: '2000px',
                    height: '160px',
                    marginTop: '-3px'
                  }}
                  className="shadow-sm"
                  alt={banneralt}
                />
                {/* <div className="tooltip">
                  1300 x 300px (recommended)
                </div> */}
              </div>
            </div>
            <div>
              {/* <span>Banner Img Alt</span> */}
              <input type="text" style={{ padding: '5px', border: 'solid 1px #cccccc', width: '100%', background: 'transparent' }} placeholder='Banner Alt' className='text-sm rounded-sm' value={banneralt} onChange={(e) => setBanneralt(e.target.value)} />
            </div>


          </div>
          {/* {!filesSelected ? (
            <div className='productMediaRight'>
              <input
                type='file'
                multiple
                style={{ display: 'none' }}
                ref={inputMultipleFile}
                onChange={(e) => {
                  handleMultipleFileChange(e)
                }}
              ></input>
              <div
                className='dropFilesSection'
                onClick={() => onButtonClickMultiple()}
              >
                <div className='dropFilesIcon'>
                  <img src='https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688971931/LofazUploadImage_bykfaq.png'></img>
                </div>
                <div className='dropFilesTexts'>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '17px',
                      fontWeight: '600',
                      color: '#181c32',
                    }}
                  >
                    Drop files here or click to upload.
                  </div>
                  <div
                    style={{
                      textAlign: 'left',
                      fontSize: '13px',
                      fontWeight: '500',
                      color: '#b5b5c3',
                    }}
                  >
                    Upload up to 4 files
                  </div>
                </div>
              </div>
              <div className='galleryImagesText'>
                <div
                  style={{
                    textAlign: 'left',
                    fontWeight: '700',
                    fontSize: '14px',
                    color: '#181c32',
                    lineHeight: '17px',
                  }}
                >
                  Gallery Images
                </div>
                <div
                  style={{
                    textAlign: 'left',

                    fontSize: '12px',
                    color: '#a1a5b7',
                    fontWeight: '400',
                  }}
                >
                  *1000x1000 size with size less than 1 mb
                </div>
              </div>
            </div>
          ) : (
            <FourImageSelector
              images={images}
              setImages={setImages}
            ></FourImageSelector>
          )} */}
        </div>

        {/* Here begins the second part of the form in which the user fills the product info */}

        <div
          className='addProductFormComponent productInfoComponent'
          id='productInfo'
        >
          <div className='productInfoTitle'> Category Information</div>
          <div className='productNameAndCategoryInputs'>
            <div className='inputName' style={{}}>
              <div className='d-flex justify-content-between'>
                <div
                  className='inputHeading'
                  style={{
                    textAlign: 'left',
                    color: '#3f4254',
                    fontWeight: '500',
                  }}
                >
                  Category Name
                </div>
                <div style={{ textAlign: 'right', marginTop: '5px', color: '#A1A5B7', fontSize: '14px' }}>
                  {catalogName.length}/{maxLength}
                </div>
              </div>
              <input
                placeholder='Enter Catalog Name'
                style={{
                  // width: '700px',
                  border: 'solid 1px #cccccc',
                  padding: '7px',
                  paddingLeft: '10px',
                  borderRadius: '5px',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontWeight: '500',
                }}
                value={catalogName}
                onChange={handleChangeInput}
              />

            </div>
          </div>
        </div>

        <div
          className='addProductFormComponent prodcutFormSEOComponent'
          id='seo'
        >
          <div className='seoFormComp seoTitle'>SEO</div>
          <div className=' seoFormComp metaTagTile'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-item-center'>
                  <div className='seoCompSubtitles baxa'>Meta Title</div>
                  <div className="baxa" style={{ textAlign: 'right', marginLeft: '15px', color: '#A1A5B7', fontSize: '12px' }}>
                    {sMTitle.length}/{maxLength}
                  </div>
                </div>
                <button style={{ color: '#009ef7' }} onClick={handleGenerateClickforTitle}>{loadingGenforTitle ? 'Generating...' : 'Generate title through AI?'}</button>
              </div>
              <input

                placeholder='Enter Meta tag name'
                onChange={(e) => {
                  handleChangeInputMetaTitle(e)
                }}
                value={sMTitle}
              />

            </div>


          </div>
          <div className='seoCompInfo'>
            Set a meta tag title. Recommended to be simple and precise
            keywords.
          </div>
          <div className=' seoFormComp metaTagDescription'>
            <div className='d-flex justify-content-between align-items-center ' style={{ flexDirection: 'row' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='seoCompSubtitles'>Meta Tag Description</div>
                <div style={{ textAlign: 'right', marginLeft: '15px', color: '#A1A5B7', fontSize: '12px' }}>
                  {getPlainTextLength(sMDesc)}/{maxLengthDes}
                </div>
              </div>
              <button style={{ color: '#009ef7' }} onClick={handleGenerateClick}>{loadingGen ? 'Generating...' : 'Generate Description through AI?'}</button>
            </div>
            <div className=''>

              <ReactQuill
                theme='snow'
                style={{
                  textAlign: 'left',
                  color: 'black',
                }}
                onChange={(e) => {
                  handleChangeInputMetaDes(e)
                }}
                value={sMDesc}
                placeholder='Set a meta tag description to the product for increased SEO ranking.'
              ></ReactQuill>
            </div>
            {/* <div className='seoCompInfo'>
              Set a meta tag description to the product for increased SEO
              ranking.
            </div> */}
          </div>
          <div className='seoFormComp'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='seoCompSubtitles baxa'>Meta Slug</div>
                <div className="baxa" style={{ textAlign: 'right', marginTop: '5px', color: '#A1A5B7', fontSize: '14px' }}>
                  {sMSlug.length}/{maxLength}
                </div>
              </div>
              <div className="input-container" style={{ width: 'auto' }}>
                <span className="fixed-text p-1">{APP_URL}/{cookieFetcher('username')}/category/</span>
                <input
                  type='text'
                  style={{ border: 'none' }}
                  className="dynamic-input"
                  placeholder=""
                  onChange={(e) => {
                    handleChangeInputMetaUrl(e)
                  }}
                  value={sMSlug}
                />
              </div>

            </div>
          </div>
          <div className=' seoFormComp tags'>
            <div className='seoCompSubtitles tagsSubtitle'>
              Tags <span style={{ color: 'red' }}>*</span>
            </div>
            <input
              placeholder='Important, Urgent'
              style={{
                background: '#f9f9f9',
                border: 'none',
                fontFamily: 'Inter',
                fontSize: '14px',
                paddingLeft: '10px',
                paddingTop: '7px',
                paddingBottom: '7px',
                color: '#5e6278',
                fontWeight: 500,
              }}
              onChange={(e) => {
                if (e.target.value.trim().length === 0) {
                  setSMMetaTags([])
                } else {
                  setSMMetaTagsInterim(e.target.value)
                  // checker(e.target.value)

                }
              }}
              onKeyDown={handle5keywords}
              value={sMMetaTagsInterim}
            ></input>
            <div className='seoCompInfo'>
              Set a list of keywords that the product is related to. Press enter to add keywords
            </div>

          </div>
          <div className='metaTagsListHolder'>
            {sMMetaTags.length > 0 && sMMetaTags.map((item, index) => (
              <div className='tagHolderHere' key={index} onClick={() => removeTag(item)}>
                {item} <span style={{ marginLeft: '5px', cursor: 'pointer', color: 'red' }}>x</span>
              </div>
            ))}
          </div>
          {errorkeymsg && <p className='text-sm' style={{ color: 'red' }}>You can only enter 5 keywords.</p>}
          {/* <div className=' seoFormComp metaTagKeywords'>
            <div className='seoCompSubtitles'>Meta Tag Keywords</div>
            <input placeholder=''></input>
            <div className='seoCompInfo'>
              Set a list of keywords that the product is related to. Separate
              the keywords by adding a comma{' '}
              <span
                style={{
                  background: '#f1f3f8',
                  paddingTop: '2px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  borderRadius: '3px',
                  paddingBottom: '5px',
                  color: 'purple',
                }}
              >
                ,
              </span>{' '}
              between each keyword.
            </div>
          </div> */}
        </div>
        <div className='addProductFormComponent saveButtonComponent'>
          <div className='cancelButton' onClick={handleCancelClick}>Cancel</div>
          <button
            className='productSubmitButton p-2 px-4 rounded-lg text-sm text-white'
            style={{ backgroundColor: '#009EF7' }}
            onClick={() => {
              setLoading(true);
              console.log("hi", catalogName, catalogStatus, sMTitle, sMDesc, sMMetaTags);
              createCatalog(
                featureImage,
                images,
                imgalt,
                banneralt,
                catalogName,
                catalogStatus,
                sMTitle,
                sMDesc,
                sMMetaTags,
                sMSlug
              );
              setLoading(false);
            }}
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Create Catalog'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default AddCatalogForm
