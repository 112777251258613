import React from 'react';
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, FacebookIcon, WhatsappIcon, TwitterIcon } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy } from 'react-icons/fi';
import './ShareModal.css'; // Make sure to add the corresponding CSS

const ShareModal = ({ shareUrl, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content bg-white m-20 rounded-lg">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Share Options</h2>
        <div className="share-buttons rounded-lg">
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={40} />
          </FacebookShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={40} />
          </WhatsappShareButton>
          <TwitterShareButton url={shareUrl}>
            <TwitterIcon size={40} />
          </TwitterShareButton>
          <CopyToClipboard text={shareUrl}>
            <button
              className="copy-button"
              onClick={() => alert('Link copied to clipboard!')}
            >
              <FiCopy size={35} />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;
