import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import OrdersHeader from "../Orders/OrdersHeader";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { cookieFetcher, BASE_URL } from "../../helper";
import axios from "axios";
import { Line, Doughnut, Radar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import './Analytics.css'

function Analytics() {
    const [sidebar, setSidebar] = useState(true);
    const [data, setData] = useState({});
    const [fromDate, setFromDate] = useState(() => {
        const today = new Date();
        today.setDate(today.getDate() - 31); // 31 days ago
        return today.toISOString().split('T')[0];
    });
    const [toDate, setToDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    });
    const [weeklyData, setWeeklyData] = useState({ labels: [], data: [] });


    const getNextDay = (date) => {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay.toISOString().split('T')[0];
    };


    const fetchDailyData = async (date) => {
        const nextDay = getNextDay(date)
        try {
            const response = await axios.get(`${BASE_URL}/dashboard/shop`, {
                params: { fromDate: date, toDate: nextDay },
                headers: { 'Authorization': `Bearer ${cookieFetcher('token')}` },
                mode: "cors",
                cache: 'default'
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching daily data", error);
            return { total_order: 0 }; // Default value if error occurs
        }
    };

    const fetchData = async () => {
        const today = new Date();
        const labels = [];
        const dataPoints = [];

        for (let i = 7; i >= 0; i--) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const dateString = date.toISOString().split('T')[0];

            // Add the date to the labels array
            labels.push(dateString);

            // Fetch data for each day
            const dailyData = await fetchDailyData(dateString);
            console.log("daily", dailyData);

            dataPoints.push(dailyData.total_order);
        }

        setWeeklyData({
            labels: labels.reverse(), // reverse to have the earliest date first
            data: dataPoints.reverse()
        });
        console.log("data points", dataPoints);


        // Fetch data for the selected range
        try {
            const response = await axios.get(`${BASE_URL}/dashboard/shop`, {
                params: { fromDate, toDate },
                headers: { 'Authorization': `Bearer ${cookieFetcher('token')}` },
                mode: "cors",
                cache: 'default'
            });
            setData(response.data);
            console.log("Main data", response.data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fromDate, toDate]);

    const lineChartData = {
        labels: ['Orders', 'Amount', 'Cart', 'Wishlist'],
        datasets: [{
            label: 'Metrics Over Time',
            data: [data.total_order, data.total_amount, data.total_cart, data.total_wishlist],
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
        }]
    };

    const doughnutChartData = {
        labels: ['Total Orders', 'Total Cart', 'Total Wishlist'],
        datasets: [{
            data: [data.total_order, data.total_cart, data.total_wishlist],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
        }]
    };

    const radarChartData = {
        labels: ['Orders', 'Amount', 'Cart', 'Wishlist'],
        datasets: [{
            label: 'Performance Metrics',
            data: [data.total_order, data.total_amount, data.total_cart, data.total_wishlist],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        }]
    };

    const weeklyOrdersChartData = {
        labels: weeklyData.labels,
        datasets: [{
            label: 'Orders',
            data: weeklyData.data,
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1
        }]
    };

    const commonChartOptions = {
        scales: {
            y: {
                beginAtZero: true, // Ensures that the Y-axis starts at 0
            }
        }
    };

    return (
        <div className="couponCodePage">
            <Helmet
                title="Dashboard Analytics"
                link={{ rel: "icon", type: "image/png", href: cookieFetcher("photo") }}
            />
            <Sidebar sidebar={sidebar} page={"analytics"} />
            <div className="couponCodeMain" style={{ background: '#ebecf1' }}>
                <OrdersHeader sidebar={sidebar} setSidebar={setSidebar} />
                <div className="coming-soon-container m-4" style={{ background: '#ebecf1' }}>
                    <div className="mb-6">
                        <div className="d-flex align-items-center justify-content-between">
                            <h4 className="mb-4 font-semibold">Dashboard Analytics</h4>
                            <div className="flex gap-4 mb-4">
                                <input
                                    type="date"
                                    name="fromDate"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    className="p-2 border border-gray-300 rounded-lg"
                                />
                                <input
                                    type="date"
                                    name="toDate"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    className="p-2 border border-gray-300 rounded-lg"
                                />
                            </div>
                        </div>
                        <div className="summaryCards grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                            <div className="card p-2 bg-white shadow rounded-lg">
                               <div className="d-flex align-items-center justify-content-center">
                               <div>
                                    <h4 className="text-lg font-semibold">Total Orders</h4>
                                </div>
                                <div className='tooltip-container'>
                                    <span class="material-symbols-outlined text-gray-500 ml-2">
                                        info
                                    </span>
                                    <div className="tooltip">
                                        <span className="text-sm">Total number of orders received in selected duration of time</span>
                                    </div>
                                </div>
                               </div>
                                <p className="text-xl">{data.total_order}</p>
                            </div>
                            <div className="card p-2 bg-white shadow rounded-lg">
                            <div className="d-flex align-items-center justify-content-center">
                               <div>
                                    <h4 className="text-lg font-semibold">Total Amount</h4>
                                </div>
                                <div className='tooltip-container'>
                                    <span class="material-symbols-outlined text-gray-500 ml-2">
                                        info
                                    </span>
                                    <div className="tooltip">
                                        <span className="text-sm">Total amount received by customers in selected duration of time</span>
                                    </div>
                                </div>
                               </div>
                                <p className="text-xl">₹{data.total_amount}</p>
                            </div>
                            <div className="card p-2 bg-white shadow rounded-lg">
                            <div className="d-flex align-items-center justify-content-center">
                               <div>
                                    <h4 className="text-lg font-semibold">Total Carts</h4>
                                </div>
                                <div className='tooltip-container'>
                                    <span class="material-symbols-outlined text-gray-500 ml-2">
                                        info
                                    </span>
                                    <div className="tooltip">
                                        <span className="text-sm">Total carts in selected duration of time</span>
                                    </div>
                                </div>
                               </div>
                                <p className="text-xl">{data.total_cart}</p>
                            </div>
                            <div className="card p-2 bg-white shadow rounded-lg">
                            <div className="d-flex align-items-center justify-content-center">
                               <div>
                                    <h4 className="text-lg font-semibold">Wishlists</h4>
                                </div>
                                <div className='tooltip-container'>
                                    <span class="material-symbols-outlined text-gray-500 ml-2">
                                        info
                                    </span>
                                    <div className="tooltip">
                                        <span className="text-sm">Total number of wishlists in selected duration of time</span>
                                    </div>
                                </div>
                               </div>
                                <p className="text-xl">{data.total_wishlist}</p>
                            </div>
                        </div>

                        <div className="chartContainer grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            <div className="bg-white shadow rounded-lg p-4">
                                <h4 className="text-xl font-bold mb-4">Metrics Over Time</h4>
                                <Line data={lineChartData} style={{height:'200px', marginTop:'50px'}}/>
                            </div>
                            <div className="bg-white shadow rounded-lg p-4">
                                <h4 className="text-xl font-bold mb-4">Distribution of Metrics</h4>
                                <Doughnut data={doughnutChartData} />
                            </div>
                            <div className="bg-white shadow rounded-lg p-4">
                                <h4 className="text-xl font-bold mb-4">Performance Metrics</h4>
                                <Radar data={radarChartData} />
                            </div>

                        </div>
                        <div className="mt-8">
                            <h4 style={{ textAlign: 'start' }} className="mb-4 font-semibold">Sales Analytics</h4>
                            <div className="bg-white shadow rounded-lg p-4">
                                <h4 className="text-xl font-bold mb-4">Your Orders Over The Week</h4>
                                <Line data={weeklyOrdersChartData} options={commonChartOptions} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default Analytics;
