import React from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import NavigationBox from '../../components/NavigationBox/NavigationBox'
import AddProductForm from '../../components/AddProductForm/AddProductForm'
import Footer from '../../components/Footer/Footer'
import { useEffect } from 'react'
import { BASE_URL, cookieFetcher, loginChecker } from '../../helper'
import { useState } from 'react'
import EditProductForm from '../../components/EditProductForm/EditProductForm'
import { Helmet } from 'react-helmet'

function EditProduct() {
  const { id } = useParams()
  console.log(id)
  const [sidebar, setSidebar] = useState(true)

  function mapper(idToFind, arrayOfObjects) {
    return arrayOfObjects.find((obj) => obj._id === idToFind)
  }
  const [products, setProducts] = useState([])

  const [product, setProduct] = useState({})
  // console.log(product,products)
  useEffect(() => {
    const fetcher = async () => {
      const token = cookieFetcher('token')
  
      const res = await fetch(
        `${BASE_URL}/product/shop/v1`,
        {
          method: 'GET',
          headers: {  
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      const response = await res.json()
      console.log("product by id:",response);
      setProducts(response.data)
      const product = mapper(id, response.data)
      console.log("pro",product);
      setProduct(product)
      // const product = mapper(id, response.data)
      // console.log("pro",product);
      // setProduct(product)
      // response.then((val) => {
      //   if (val.status === 200) {
      //     setProducts(val.data)
      //     const product = mapper(id, val.data)
      //     setProduct(product)
      //   } else {
      //     console.log(val.message)
      //   }
      // })
    }
    loginChecker()
    fetcher()
  }, [])

  return (
    <div className='addProductPage'>
      <Helmet
        title='Edit Product'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <div className='addProductPageMain'>
        <div className='addProductPageMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          <div className='addProductSection'>
            {/* <NavigationBox type={'product'}></NavigationBox> */}
            <EditProductForm product={product} id={id}></EditProductForm>
          </div>
        </div>
        <div className='addProductPageMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default EditProduct
