import React, { useState } from 'react';
import '../Bannercomp/AddSlider.css'
const AddSlider = () => {
  const [type, setType] = useState('category');
  const [category, setCategory] = useState('');
  const [product, setProduct] = useState('');
  const [bannerName, setBannerName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isActive, setIsActive] = useState(true);

  const handleSave = () => {
    // Save logic here
  };

  return (
    <div className="add-slider">
      <header className="add-slider-header">Add Category</header>
      <div className="upload-image">
        <input type="file" accept="image/*" />
        <p>Add banner image (Recommended Size 800x800)</p>
      </div>
      <input
        type="text"
        placeholder="Banner Name"
        value={bannerName}
        onChange={(e) => setBannerName(e.target.value)}
      />
      <div className="select-type">
        <label>
          <input
            type="radio"
            name="type"
            value="category"
            checked={type === 'category'}
            onChange={() => setType('category')}
          />
          Category
        </label>
        <label>
          <input
            type="radio"
            name="type"
            value="product"
            checked={type === 'product'}
            onChange={() => setType('product')}
          />
          Product
        </label>
      </div>
      {type === 'category' && (
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="" disabled>Select Category</option>
          {/* Add category options here */}
        </select>
      )}
      {type === 'product' && (
        <>
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="" disabled>Select Category</option>
            {/* Add category options here */}
          </select>
          <select value={product} onChange={(e) => setProduct(e.target.value)}>
            <option value="" disabled>Select Product</option>
            {/* Add product options here */}
          </select>
        </>
      )}
      <div className="date-range">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      <div className="active-switch">
        <label>
          Active
          <input
            type="checkbox"
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          />
        </label>
      </div>
      <button className="save-slider-btn" onClick={handleSave}>
        Save Slider
      </button>
    </div>
  );
};

export default AddSlider;
