import React from 'react'
import './Profile.css'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import Sidebar from '../../components/Sidebar/Sidebar'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { cookieFetcher } from '../../helper'

function Profile() {
  const [sidebar, setSidebar] = useState(true)

  return (
    <div className='profilePage'>
      <Helmet
        title='Profile'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <div className='profileMain'>
        <OrdersHeader sidebar={sidebar} setSidebar={setSidebar}></OrdersHeader>
        <div>Profile Main Section</div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Profile
