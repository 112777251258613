import React, { useEffect, useState } from 'react'
import { BASE_URL, cookieFetcher, logout } from '../../helper'
import Swal from 'sweetalert2'
import OrdersMain from '../Orders/OrdersMain'
import Homeorder from './Homeorder'
import axios from 'axios'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fa4, faCheck } from '@fortawesome/free-solid-svg-icons'
import TimelineComponent from './TimelineComponent'
import HomeLoader from '../../components/HomeLoader/HomeLoader'

function HomePageForm() {
  const earningFetcher = async () => {
    //setHomeLoader(true)
    
     try {
      const response = await fetch(
        `${BASE_URL}/vendor/count/earning/${cookieFetcher('vendorId')}`
      )
      const res = await response.json()
      if (res.status === 200) {
        setEarning(res.total_earning)
      }
     } catch (error) {
      console.log("error earning",error);
     }
  }
  const storeViewFetcher = async () => {
    const username = cookieFetcher('username');
    console.log("username",username);
    if (!username) {
      console.error('Username not found in cookies.');
      return;
    }
  
    const apiUrl = `${BASE_URL}/vendor/count/store/${username}`;
    console.log('API URL:', apiUrl); // Log the API URL for debugging
  
    try {
      const response = await fetch(apiUrl);
      const res = await response.json();
  
      console.log('API Response store views:', res); // Log the API response for debugging
  
      if (res.views) {
        setStoreView(res.views);
        // setCustomer(res.data);
      } 
    } catch (error) {
      console.error('Failed to fetch store view:', error);
      setStoreView(0)
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Failed to fetch store view',
      //   text: error.message,
      // });
    }
  };
  
  
  // const productViewFetcher = async () => {
  //   // console.log("vendorid",vendorId);
  //   const response = await fetch(
  //     `${BASE_URL}/vendor/count/product/${cookieFetcher('vendorId')}`
  //   )
  //   const res = await response.json()
  //   console.log("prtodu",res);
  //   if (res.views) {
  //     // setProductView(res.views)
      
  //   }
  //    else {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Something went wrong in Product Views!',
  //       text: `${res.message}`,
  //     })
  //   }
  // }

  const orderFetcher = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/order/vendor/list`,{
          headers: {  
            'Authorization': `Bearer ${cookieFetcher('token')}`,
          }
        }
      )
      //const response = await res.json()
      console.log("order data",response.data)
      if (response.data) {
        setOrdersCount(response.data.length)
      } 
    } catch (error) {
      console.log("error fetching orders",error);
      setOrdersCount(0)
    }
  }

  const productFetcher = async () => {
    const token = cookieFetcher('token') // Replace with your actual token
    console.log("token",token);
    const res = await fetch(
      `${BASE_URL}/product/shop/v1`,
      {
        method: 'GET',
        headers: {  
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    try {
      const response = await res.json()
      console.log("response prod",response);
    if (res.status === 200) {
      setShowDashboardProduct(true)
      setProductView(response.total)
    } else {
      setShowDashboardProduct(false)
    }
    } catch (error) {
      console.log("error fetching products",error); 
    }
  }

  const catalogFetcher = async () => {
    

    try {
      const response = await fetch(
        `${BASE_URL}/vendor/catalog/${cookieFetcher('vendorId')}`
      )
      const res = await response.json()
      console.log("cat",res)
      if (res.status === 200) {
        setShowDashboardCatalog(true)
        setHomeLoader(false)
        setCatalogs(res.data.length)
      } 
    } catch (error) {
      console.log("error fetching catalog",error);
      setCatalogs(0)
    }
  }

  const [earning, setEarning] = useState(0)
  const [storeView, setStoreView] = useState(0)
  const [productView, setProductView] = useState(0)
  const [ordersCount, setOrdersCount] = useState(0)
  const [catalogs,setCatalogs]=useState(0)
  const [showDashboardProduct, setShowDashboardProduct] = useState(false)
  const [showDashboardCatalog, setShowDashboardCatalog] = useState(false)
  const [homeLoader, setHomeLoader] = useState(false)

  useEffect(() => {
  earningFetcher()
  storeViewFetcher()
  //  // productViewFetcher()
  orderFetcher()
   productFetcher()
  catalogFetcher()
    //window.location.reload()
  }, [])

  return (
    <div>
            <div className='homePageMain'>
              <div className='homePageComponent'>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Total Earning</div>
                  <div className='homePageSquareContent'>₹{earning}</div>
                </div>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Store Views</div>
                  <div className='homePageSquareContent'>{storeView}</div>
                </div>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Total Products</div>
                  <div className='homePageSquareContent'>{productView}</div>
                </div>
                <div className='homePageSquare'>
                  <div className='homePageSquareTitle'>Total Orders</div>
                  <div className='homePageSquareContent'>{ordersCount}</div>
                </div>
              </div>
            </div>
            <div className='homePageMain'>
              <TimelineComponent catalogs={catalogs} productView={productView}></TimelineComponent>
              {/* <VerticalTimeline>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
            <VerticalTimelineElement
              className='vertical-timeline-element--work'
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            ></VerticalTimelineElement>
          </VerticalTimeline> */}
            </div>

        {/* <HomeLoader></HomeLoader> */}
    
    </div>
  )
}

export default HomePageForm
