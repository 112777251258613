import { faList, faStore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa'
import ReactSwitch from 'react-switch'
import { BASE_URL, cookieFetcher } from '../../../helper'
import Swal from 'sweetalert2'
import StoreStatusModal from '../EditProfileModals/StoreStatusModal'

function StoreStatusForm() {
  const [store, setStore] = useState(false)
  const [whatsapp, setWhatsapp] = useState(false)
  const [order, setOrder] = useState(false)
  const [firstcall, setFirstCall] = useState()
  const [modalOpen, setModalOpen] = useState(false)


  
  const fetcher = async () => {
    try {
      const response = await fetch(`${BASE_URL}/setting/shop`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${cookieFetcher('token')}`,
        },
      });
      const res = await response.json();
      if (response.ok) {
        setStore(res.shop_status);
        setOrder(res.order_status);
        setWhatsapp(res.whatsapp_status);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed to fetch status',
          text: res.message || 'An error occurred while fetching the status.',
        });
      }
    } catch (error) {
      console.error('Error fetching shop status:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while fetching the status.',
      });
    }
  };


  const storeStatusToggle = async (type, value) => {
    try {
      let statusField = '';
      switch (type) {
        case 'store':
          statusField = 'shop_status';
          break;
        case 'whatsapp':
          statusField = 'whatsapp_status';
          break;
        case 'order':
          statusField = 'order_status';
          break;
        default:
          return;
      }

      const response = await fetch(`${BASE_URL}/setting`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${cookieFetcher('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ [statusField]: value }),
      });

      const res = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Status updated successfully!',
          showConfirmButton: false,
              timer: 1000,
              showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
              },
              hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
              },
        });
        // Update the state based on the type
        if (type === 'store') setStore(value);
        if (type === 'whatsapp') setWhatsapp(value);
        if (type === 'order') setOrder(value);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: res.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      console.error('Error updating status:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating the status.',
      });
    }
  };


  useEffect(() => {
    fetcher();
  }, [])

  

  return (
    <div className='storeStatusForm'>
      <div className='storeStatusTitleForm'>Store Status</div>
      <div className='storeStatusFormComponent'>
        <div className='storeStatusFormCompLeft'>
          <FontAwesomeIcon
            color='gray'
            icon={faStore}
            style={{ fontSize: '25px' }}
          />
          <div className='storeStatusTitle'>
            <div className='subscetionTitle'>Store Status</div>
            <div className='subsectionContent'>
              Hide/Show your store to user
            </div>
          </div>
        </div>
        <div className='storeStatusFormCompRight'>
            <ReactSwitch
              onChange={(e) => {
                console.log(e)
                // if (e === false) {
                //   setModalOpen(true)
                // }
                
                storeStatusToggle('store', e)
              }}
              checked={store}
              uncheckedIcon=''
              checkedIcon=''
              onColor='#00D907'
              offColor='#ff0000'
            ></ReactSwitch>
        </div>
      </div>
      <div className='storeStatusFormComponent'>
        <div className='storeStatusFormCompLeft'>
          <FontAwesomeIcon
            color='gray'
            style={{ fontSize: '25px' }}
            icon={faList}
          />
          <div className='storeStatusTitle'>
            <div className='subscetionTitle'>Order Online/Offline</div>
            <div className='subsectionContent'>
              {' '}
              Allow users to order online/offline
            </div>
          </div>
        </div>
        <div className='storeStatusFormCompRight'>
          <ReactSwitch
            onChange={(e) => {
              console.log(e)
              setOrder(e)
              storeStatusToggle('order', e)
            }}
            checked={order}
            uncheckedIcon=''
            checkedIcon=''
            onColor='#00D907'
            offColor='#ff0000'
          ></ReactSwitch>
        </div>
      </div>
      <div className='storeStatusFormComponent'>
        <div className='storeStatusFormCompLeft'>
          <FaWhatsapp color='gray' size={25}></FaWhatsapp>
          <div className='storeStatusTitle'>
            <div className='subscetionTitle'>Show WhatsApp Number</div>
            <div className='subsectionContent'>
              Hide/Show WhatsApp on website
            </div>
          </div>
        </div>
        <div className='storeStatusFormCompRight'>
          <ReactSwitch
            onChange={(e) => {
              console.log(e)
              setWhatsapp(e)
              storeStatusToggle('whatsapp', e)
            }}
            checked={whatsapp}
            uncheckedIcon=''
            checkedIcon=''
            onColor='#00D907'
            offColor='#ff0000'
          ></ReactSwitch>
        </div>
      </div>
      {/* <div className='cancelAndSaveButton'>
        <div className='cancelButton'>Cancel</div>
        <div className='saveButton'>Save Changes</div>
      </div> */}
      <StoreStatusModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      ></StoreStatusModal>
    </div>
  )
}

export default StoreStatusForm
