import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'

export const primary_color = '#047BD5'

export const defaultLogo =
  'https://lofaz-files.s3.ap-south-1.amazonaws.com/default/logo.png'

//export const BASE_URL = 'https://betabackend.lofaz.in/api'
export const BASE_URL = 'https://api.lofaz.com/api'
export const APP_URL='https://lofaz.com'

export const cookieCreator = (n, value) => {
  Cookies.set(n, value, { expires: 30 })
}



export const cookieFetcher = (n) => {
  const val = Cookies.get(n)
  return val
}

export const checkPresence = async (mobile) => {
  const res = await fetch(
    `${BASE_URL}/vendor/check_number/${mobile}`
  );
  const value = await res.json();
  if (value.message === "Already Vendor Exits !!") {
    console.log("Vendor Exists!!");
    cookieCreator("token", value.token);
    cookieCreator("number", value.data.phone);
    cookieCreator("vendorId", value.data._id);
    return true;
  }
  return false;
};

export const loginChecker = () => {
  const token = cookieFetcher('token')
  if (!token) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Looks like you have been logged out!',
    })
    document.location.href = '/signin'
  }
}

export const logout = () => {
  Cookies.remove('token')
  Cookies.remove('number')
  Cookies.remove('vendorId')
  Cookies.remove('username')
  Cookies.remove('photo')
  document.location.href = '/signin'
}

export const generateAboutText = async (prompt) => {
  try {
    // setLoadingGen(true);
    const API_KEY = 'AIzaSyAMKjcnhLfkHbvBBYrvcuBngcizr0FLc8Q'
    const response = await axios({
      url: `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=${
        API_KEY
      }`,
      method: "post",
      data: {
        contents: [{ parts: [{ text: prompt }] }],
      },
    });
    console.log("gemini",response);
    return response.data.candidates[0].content.parts[0].text; 
  } catch (error) {
    console.error("Error generating about text:", error);
    return '';
  }
  // finally {
  //   setLoadingGen(false);
  // }
};

export const softgray = 'b5b5c3'

export const defaultFeatureImage =
  'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'

export const defaultFourImage =
  'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688971931/LofazUploadImage_bykfaq.png'

export const privacyPolicyTemp =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat, ante at luctus condimentum, nisl ligula rhoncus nunc, vel gravida elit est eu ipsum. Integer ac sem a libero lacinia tempor. Proin vitae justo nec elit consectetur ultricies. In hac habitasse platea dictumst. Etiam lacinia orci vel quam interdum, a scelerisque quam consectetur. Quisque dignissim ex in bibendum vehicula. Nulla facilisi. Curabitur varius quam vel massa dapibus, eu ultrices ante sollicitudin. Nullam nec condimentum orci, vel cursus sapien. Fusce id aliquam orci, non tempus justo. Vestibulum ac neque sit amet nunc fringilla dapibus id a velit. Integer euismod bibendum risus, quis lacinia ipsum dignissim ut. Nullam ullamcorper, libero eu facilisis ultrices, neque elit viverra odio, eget bibendum eros massa sit amet odio. Suspendisse potenti. Vivamus ac libero nec metus sollicitudin facilisis ut vel libero. Fusce vulputate tellus in fringilla congue. Cras hendrerit est ut odio tristique, a fermentum elit pellentesque. In vitae ex a velit congue cursus. Aenean aliquam elit a erat cursus, sit amet volutpat tellus tristique. Aliquam laoreet lacus vel ipsum iaculis aliquam. Donec bibendum ultrices ligula, eget fermentum arcu tincidunt nec. Suspendisse potenti. Suspendisse sit amet nisl nec enim euismod malesuada. Pellentesque sodales quam a lectus laoreet, in facilisis neque lacinia. Duis eget consequat sapien. Nullam eleifend efficitur libero, id dignissim elit pharetra nec. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nunc ac efficitur dui, ut dictum eros. Nulla vel ante nec tortor congue convallis. Nulla facilisi. Nullam eget mi quis justo laoreet iaculis. Morbi feugiat velit ac auctor suscipit. Quisque ut ligula quis velit cursus bibendum vel a felis. Nulla facilisi. Sed nec lacinia massa, a rhoncus tortor.'
