import React, { useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import OrdersHeader from "../Orders/OrdersHeader";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { cookieFetcher } from "../../helper";
import "./CouponCode.css";

function Customer() {
  const [sidebar, setSidebar] = useState(true);
  return (
    <div className="couponCodePage">
      <Helmet
        title="Coupon Code"
        link={{ rel: "icon", type: "image/png", href: cookieFetcher("photo") }}
      />
      <Sidebar sidebar={sidebar} page={"couponCode"} />
      <div className="couponCodeMain">
        <OrdersHeader sidebar={sidebar} setSidebar={setSidebar} />
        <div className="coming-soon-container">
          <div className="coming-soon-box">
            <h1>Coming Soon</h1>
            <p>We're working hard to bring you the best coupon codes. Stay tuned!</p>
            {/* <img src="https://via.placeholder.com/400x200" alt="Coming Soon" class="mx-auto mb-6 rounded"> */}
            <p className="thank-you">Thank you for your patience.</p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Customer;
