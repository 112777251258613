// import React from 'react'
// import './FormLoader.css'
// import {
//   ShimmerCircularImage,
//   ShimmerThumbnail,
//   ShimmerTitle,
//   ShimmerText,
// } from 'react-shimmer-effects'

// function FormLoader() {
//   return (
//     <div className='formLoader'>
//       <div className='formLoaderComponent formLoaderComponent1'>
//         <ShimmerCircularImage
//           className='circularShimmer'
//           size={150}
//         ></ShimmerCircularImage>
//         <ShimmerThumbnail
//           className='thumbnailShimmer'
//           height={150}
//           width={300}
//         ></ShimmerThumbnail>
//       </div>
//       <div className='formLoaderComponent  formLoaderComponent2'>
//         <ShimmerTitle></ShimmerTitle>
//         <ShimmerText></ShimmerText>
//       </div>
//       <div className='formLoaderComponent  formLoaderComponent3'>
//         <ShimmerTitle></ShimmerTitle>
//         <ShimmerText></ShimmerText>
//       </div>
//       <div className='formLoaderComponent  formLoaderComponent4'>
//         <ShimmerTitle></ShimmerTitle>
//         <ShimmerText></ShimmerText>
//       </div>
//     </div>
//   )
// }

// export default FormLoader

import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import './FormLoader.css'
import 'react-loading-skeleton/dist/skeleton.css'

function FormLoader() {
  return (
    <div className='formLoader'>
      <div
        className='formLoaderComp'
        style={{ display: 'flex', gap: '20px', justifyContent: 'space-around' }}
      >
        <Skeleton circle style={{ width: '150px', height: '150px' }}></Skeleton>
        <SkeletonTheme>
          <Skeleton width='200px' height='150px' style={{ marginTop: '' }} />
        </SkeletonTheme>
      </div>
      <div className='formLoaderComp'>
        <Skeleton width='' height='120px' />
      </div>
      <div className='formLoaderComp'>
        <Skeleton width='' height='120px' />
      </div>
      <div className='formLoaderComp'>
        <Skeleton width='' height='120px' />
      </div>
    </div>
  )
}

export default FormLoader
