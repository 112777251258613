import React, { useState,useEffect } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import './QrCode.css'
import QRCode from 'react-qr-code'
import { BASE_URL,cookieCreator, cookieFetcher } from '../../helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faShare } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'
import html2canvas from 'html2canvas'; 
import { useNavigate } from 'react-router-dom'

function QrCode() {const [sidebar, setSidebar] = useState(true);
  const navigate=useNavigate();
  const [photo, setPhoto] = useState(
    'https://ipfs.filebase.io/ipfs/QmZ6mAp25xPqcmpFrhS4zg5UFvwDDnbAGPrS1NZUrQ11UH/QmcES1BYBhrtw2YU1CWPkbY1JDZbVtLcLvzfjQmo4yF2Ja'
  );
  const [storeName, setStoreName] = useState('');
  const [userlink, setUserlink] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [username, setUsername] = useState('');
  const [qrCodeValue, setQrCodeValue] = useState('');
  useEffect(() => {
    const fetcher = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/vendor/visitor/${cookieFetcher('username')}`
        );
        const res = await response.json();
        console.log(res.data);
        setUsername(res.data.username);
        setPhoneNumber(res.data.phone);
        setUserlink(res.data.username);
        setStoreName(res.data.business_name);
        
        if (res.data.photo && res.data.photo.length > 0) {
          setPhoto(res.data.photo);
        } else {
          setPhoto('https://ipfs.filebase.io/ipfs/QmZ6mAp25xPqcmpFrhS4zg5UFvwDDnbAGPrS1NZUrQ11UH/QmcES1BYBhrtw2YU1CWPkbY1JDZbVtLcLvzfjQmo4yF2Ja');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error state if needed
      }
    };
    fetcher();
  }, []);
  
  const handleDownloadClick = () => {
    // Using html2canvas to capture the QR code and initiate download
    html2canvas(document.getElementById('qrCodeHolder')).then(function(canvas) {
      let link = document.createElement('a');
      link.download = 'qr-code.png';
      link.href = canvas.toDataURL();
      link.click();
    }).catch(function(error) {
      console.error('Error creating QR code image:', error);
    });
  };
  // Render your component with the photo state now containing the appropriate value

  const handleWhatsAppShare = () => {
    const message = encodeURIComponent(`Scan the above QR code to order online at ${storeName}. https://lofaz.com/${username}`);
    const whatsappURL = `https://wa.me/?text=${message}`;
    window.open(whatsappURL, '_blank');
  };
  
  return (
    <div className="qrCodePage">
    <Helmet
      title="QR Code"
      link={{ rel: "icon", type: "image/png", href: cookieFetcher("photo") }}
    />
    <Sidebar sidebar={sidebar} />
    <div className="qrCodeMain">
      <div className="qrCodeMainTop">
        <OrdersHeader sidebar={sidebar} setSidebar={setSidebar} />
        <div className="qrCodeMainSec">
          <div className="qrCard">
            <div className="qrCodeHolder">
              
                <div className="qrCodeImage" id="qrCodeHolder">
                  <img src={photo} style={{
                    width:'90px',
                    height:'80px',
                    borderRadius:'10px'
                  }} alt="User" />
                </div>
                {/* <div className="qrCodeTitleText">{storeName}</div> */}
              
              <div className="qrDetails">
                <div className="qrCodeStoreName">{storeName}</div>
                <div className="qrCodeCustomerLink">{userlink}</div>
                <div className="qrCodeNumber">{phoneNumber}</div>
              </div>

              <QRCode
                height="150px"
                width="150px"
                style={{ width: "150px", height: "150px" }}
                value={qrCodeValue}
              />
              <div className="qrCallForAction">
                Scan the above QR code to shop online.
              </div>
              <div className="qrCodeButtons">
                <div
                  className="qrButton"
                  style={{ cursor: "pointer" }}
                  onClick={handleDownloadClick}
                >
                  Download{" "}
                  <FontAwesomeIcon icon={faDownload} />
                </div>
                <div
                  className="qrButton"
                  style={{ cursor: "pointer" }}
                  onClick={handleWhatsAppShare}
                >
                  Share <FontAwesomeIcon icon={faShare} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="qrCodeMainBottom">
        <Footer />
      </div>
    </div>
  </div>
  );
}

export default QrCode
