import React from 'react'
import './AddProduct.css'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import NavigationBox from '../../components/NavigationBox/NavigationBox'
import AddProductForm from '../../components/AddProductForm/AddProductForm'
import { useState } from 'react'
import { useEffect } from 'react'
import { cookieFetcher, loginChecker } from '../../helper'
import { Helmet } from 'react-helmet'

function AddProduct() {
  const [sidebar, setSidebar] = useState(true)
  useEffect(() => {
    loginChecker()
  }, [])
  return (
    <div className='addProductPage'>
      <Helmet
        title='Add Product'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <div className='addProductPageMain'>
        <div className='addProductPageMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          <div className='addProductSection'>
            <NavigationBox type={'product'}></NavigationBox>
            <AddProductForm></AddProductForm>
          </div>
        </div>
        <div className='addProductPageMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default AddProduct
