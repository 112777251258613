import React, { useEffect } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import NavigationBox from '../../components/NavigationBox/NavigationBox'
import AddProductForm from '../../components/AddProductForm/AddProductForm'
import AddCatalogForm from '../../components/AddCatalogForm/AddCatalogForm'
import Footer from '../../components/Footer/Footer'
import { useParams } from 'react-router-dom'
import { BASE_URL, cookieFetcher, loginChecker } from '../../helper'
import { useState } from 'react'
import EditCatalogForm from '../../components/EditCatalogForm/EditCatalogForm'
import { Helmet } from 'react-helmet'

function EditCatalog() {
  const { id } = useParams()
  const [catalog, setCatalog] = useState('')
  const [sidebar, setSidebar] = useState(true)

  function mapper(array, idToFind) {
    let ans
    array.map((item) => {
      if (item._id === id) {
        ans = item
      }
    })
    return ans
  }

  useEffect(() => {
    const fetcher = async () => {
      const res = await fetch(
        `${BASE_URL}/vendor/catalog/${cookieFetcher('vendorId')}`
      )
      const response = await res.json()
      console.log('From Edit Catalog ======>', response)
      const catalog = mapper(response.data, id)
      setCatalog(catalog)
    }
    loginChecker()
    fetcher()
  }, [])

  return (
    <div className='addProductPage'>
      <Helmet
        title='Edit Catalog'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <div className='addProductPageMain'>
        <div className='addProductPageMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          <div className='addProductSection'>
            <EditCatalogForm catalog={catalog}></EditCatalogForm>
          </div>
        </div>
        <div className='addProductPageMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default EditCatalog
