import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../../helper'

export const refundPolicyEditor = async (type, value, policyId) => {
  const response = await fetch(
    `${BASE_URL}/vendor/policy/${type}/${policyId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
        'Content-Type': 'Application/JSON',
      },
      body: JSON.stringify({ refund_policy: value }),
    }
  )
  const res = await response.json()
  if (res.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Updated',
      text: `Successfully Updated ${type} policy!`,
      showConfirmButton: false,
      timer: 1000,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    })
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Failed',
      text: `${res.message}`,
    })
  }
}

export const privacyPolicyEditor = async (type, value, policyId) => {
  const response = await fetch(
    `${BASE_URL}/vendor/policy/${type}/${policyId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
        'Content-Type': 'Application/JSON',
      },
      body: JSON.stringify({ privacy_policy: value }),
    }
  )
  const res = await response.json()
  if (res.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Updated',
      text: `Successfully Updated ${type} policy!`,
      showConfirmButton: false,
      timer: 1000,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    })
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Failed',
      text: `${res.message}`,
    })
  }
}

export const cancellationPolicyEditor = async (type, value, policyId) => {
  const response = await fetch(
    `${BASE_URL}/vendor/policy/${type}/${policyId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
        'Content-Type': 'Application/JSON',
      },
      body: JSON.stringify({ cancellation_policy: value }),
    }
  )
  const res = await response.json()
  if (res.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Updated',
      text: `Successfully Updated ${type} policy!`,
      showConfirmButton: false,
      timer: 1000,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    })
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Failed',
      text: `${res.message}`,
    })
  }
}

export const termsEditor = async (type, value, policyId) => {
  const response = await fetch(
    `${BASE_URL}/vendor/policy/${type}/${policyId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
        'Content-Type': 'Application/JSON',
      },
      body: JSON.stringify({ terms_condition: value }),
    }
  )
  const res = await response.json()
  if (res.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Updated',
      text: `Successfully Updated ${type} policy!`,
      showConfirmButton: false,
      timer: 1000,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    })
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Failed',
      text: `${res.message}`,

    })
  }
}

export const shippingPolicyEditor = async (type, value, policyId) => {
  const response = await fetch(
    `${BASE_URL}/vendor/policy/${type}/${policyId}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
        'Content-Type': 'Application/JSON',
      },
      body: JSON.stringify({ shipping_policy: value }),
    }
  )
  const res = await response.json()
  if (res.status === 200) {
    Swal.fire({
      icon: 'success',
      title: 'Updated',
      text: `Successfully Updated ${type} policy!`,
      showConfirmButton: false,
      timer: 1000,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    })
  } else {
    Swal.fire({
      icon: 'error',
      title: 'Failed',
      text: `${res.message}`,
    })
  }
}
