import { faEdit } from '@fortawesome/free-regular-svg-icons'
import {
  faPenToSquare,
  faShare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { BASE_URL, cookieFetcher } from '../../helper'
import { useNavigate } from 'react-router-dom'
import ShareModal from './ShareModal'
import axios from 'axios'
import { APP_URL } from '../../helper'

function CatalogsDropDown({ catalog, fetchStatus, setFetchStatus }) {
  const navigate = useNavigate()
  const [showShareModal, setShowShareModal] = useState(false);
  const shareUrl = `https://lofaz.com/${cookieFetcher('username')}/category/${catalog.url}`;
  console.log("catalog id", catalog);

  const handleShareClick = () => {
    setShowShareModal(true);
  };

  const closeModal = () => {
    setShowShareModal(false);
  };

  const deleting = async (catalog) => {
    console.log("catalog", catalog);
    try {
      const response = await axios.delete(`${BASE_URL}/vendor/catalog/${catalog._id}`, {
        headers: {
          'Authorization': `Bearer ${cookieFetcher('token')}`,
        },
      });
      console.log("del",response);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Catalog Status updated successfully!',
          showConfirmButton: false,
          timer: 1000,
          // timerProgressBar: true,
          
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            window.location.reload();
          }
        });
      }
        else {
        // Handle non-200 responses
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data.message || 'An unexpected error occurred.',
        });
      }
    } catch (error) {
      // Handle errors during the request
      console.error('Error deleting product:', error);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.message || 'An error occurred while deleting the product. Please try again.',
      });
    }
  };


  const catalogDeleter = async (catalog) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: `Are you sure you want to delete ${catalog.title}?`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleting(catalog)
      }
    })
  }

  return (
    <div className="d-flex align-items-center">
      <div className="ml-2 text-gray-700 hover:text-black" style={{ cursor: 'pointer' }}  onClick={() => {
              navigate(`/catalog/edit/${catalog._id}`)
            }}>
      <span class="material-symbols-outlined" style={{ cursor: 'pointer' }}>
edit
</span>
      </div>
      <div className="ml-2" >
        <a href={catalog ? `${APP_URL}/${cookieFetcher('username')}/category/${catalog.url}` : ""} target="_blank" className="font-semibold text-center text-gray-700 hover:text-black">
          <span class="material-symbols-outlined">
            visibility
          </span>
        </a>
      </div>
      <div className="ml-2 text-gray-700 hover:text-black" style={{ cursor: 'pointer' }} onClick={handleShareClick}>
        <span class="material-symbols-outlined">
          share_windows
        </span>
      </div>
      <div className="ml-2 text-gray-700 hover:text-black" style={{ cursor: 'pointer' }} onClick={() => {
              catalogDeleter(catalog)
            }}>
        <span class="material-symbols-outlined" >
          delete
        </span>
      </div>
      {/* <UncontrolledDropdown className='catalogActionsDropdown'> */}

        {/* <DropdownToggle
          style={{ background: 'white', color: '#7e8299', border: 'none' }}
        > */}
          {/* <h4>&#x22EE; </h4> */}
        {/* </DropdownToggle> */}
        {/* <DropdownMenu className='custom-dropdown-menu'>
          <DropdownItem
            style={{ display: 'flex', gap: '10px' }}
            onClick={() => {
              navigate(`/catalog/edit/${catalog._id}`)
            }}
          >
            <FontAwesomeIcon
              style={{ marginLeft: '15px', marginTop: '3px' }}
              icon={faPenToSquare}
            ></FontAwesomeIcon>
            Edit
          </DropdownItem>
          <DropdownItem style={{ display: 'flex', gap: '10px' }}
            onClick={handleShareClick}>
            {' '}
            <FontAwesomeIcon
              style={{ marginLeft: '15px', marginTop: '3px' }}
              icon={faShare}

            ></FontAwesomeIcon>
            Share
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              catalogDeleter(catalog)
            }}
            style={{ display: 'flex', gap: '10px' }}
          >
            {' '}
            <FontAwesomeIcon
              style={{ marginLeft: '15px', marginTop: '3px' }}
              icon={faTrash}
            ></FontAwesomeIcon>
            Delete
          </DropdownItem>
        </DropdownMenu> */}

        {showShareModal && (
          <ShareModal shareUrl={shareUrl} onClose={closeModal} />
        )}
      {/* </UncontrolledDropdown> */}
    </div>

    // <div style={{ display: 'flex', gap: '6px' }}>
    //   <FontAwesomeIcon
    //     style={{ cursor: 'pointer', color: '#009ef7' }}
    //     icon={faPenToSquare}
    //   ></FontAwesomeIcon>
    //   <FontAwesomeIcon
    //     style={{ cursor: 'pointer', color: '#215C54' }}
    //     icon={faShare}
    //   ></FontAwesomeIcon>
    //   <FontAwesomeIcon
    //     style={{ cursor: 'pointer', color: '#B22222' }}
    //     icon={faTrash}
    //     onClick={() => {
    //       catalogDeleter(catalog)
    //     }}
    //   ></FontAwesomeIcon>
    // </div>

  )
}

export default CatalogsDropDown
