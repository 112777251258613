import React, { useRef, useState } from 'react'
import '../AddCatalogForm/AddCatalogForm.css'
import { components } from '../../pages/Orders/OrdersHelper'
import Select from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FourImageSelector from './FourImageSelector'
import { createCatalog } from '../AddCatalogForm/CatalogCreator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import { editCatalog } from './CatalogEditor'
import NavigationBox from '../NavigationBox/NavigationBox'
import FormLoader from '../FormLoader/FormLoader'
import { useNavigate } from 'react-router-dom';

function EditCatalogForm({ catalog }) {
  const [featureImageLink, setFeatureImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [featureImage, setFeatureImage] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [bannerImageLink, setBannerImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [bannerImage, setBannerImage] = useState(
    new Array(1)
  )
  const [filesSelected, setFilesSelected] = useState(false)
  const [images, setImages] = useState(new Array(4))
  const [catalogName, setCatalogName] = useState('')
  const [catalogNameChanged,setCatalogNameChanged]=useState(false)
  const [catalogStatus, setCatalogStatus] = useState(true)
  const [loading, setLoading] = useState(false)
  const [formLoader, setFormLoader] = useState(true)
  const [imgChanged,setImgChanged]=useState(false)

  const navigate = useNavigate();
  const inputFile = useRef(null)
  const inputFile2 = useRef(null)
  const inputMultipleFile = useRef(null)
  const maxLength = 50;       //max length for input

  const onButtonClick = (inputFiles) => inputFiles.current.click();


  const onButtonClickMultiple = () => {
    inputMultipleFile.current.click()
  }

  const handleChangeInput = (e) => {
    const value = e.target.value;
    if (value.length <= maxLength) {
      setCatalogName(value);
      setCatalogNameChanged(true)
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleFileChange1 = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    if (fileObj.size > 1048576) { // 1 MB
      alert("File size exceeds the 1 MB limit.");
      return;
    }
    const file = event.target.files[0]
    const myString = await convertBase64(file)

    var n = 23
    var substringToEnd = myString.substring(n)

    setFeatureImage(event.target.files[0])
    console.log("feature",event.target.files[0]);
    setFeatureImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleFileChange2 = async (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    if (fileObj.size > 1048576) { // 1 MB
      alert("File size exceeds the 1 MB limit.");
      return;
    }
    const file = event.target.files[0]
    const myString = await convertBase64(file)

    var n = 23
    var substringToEnd = myString.substring(n)

    setBannerImage(event.target.files[0])
    console.log("banner",event.target.files[0]);
    setBannerImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleCancel = () => {
    navigate('/category');
  };
  
  const handleMultipleFileChange = (e) => {
    if (e.target && e.target.files.length > 0) {
      let tempArr = new Array(4)
      if (e.target.files[0]) {
        tempArr[0] = e.target.files[0]
      }
      if (e.target.files[1]) {
        tempArr[1] = e.target.files[1]
      }
      if (e.target.files[2]) {
        tempArr[2] = e.target.files[2]
      }
      if (e.target.files[3]) {
        tempArr[3] = e.target.files[3]
      }
      setImages(tempArr)
      setFilesSelected(true)
    }
  }

  useEffect(() => {
    setFormLoader(true)
    if (catalog && catalog.photos && catalog.photos[0] && catalog.photos[0].location) {
      setFeatureImageLink(catalog.photos[0].location)
      setFeatureImage(catalog.photos[0])
    }
    if (catalog && catalog.title) {
      setCatalogName(catalog.title)
    }
    if (catalog && catalog.status) {
      setCatalogStatus(catalog.status)
    } else {
      setCatalogStatus(false)
    }
    setFormLoader(false)
  }, [catalog])

  return (
    <>
      <>
        <NavigationBox
          edit={true}
          id={catalog?._id}
          type={'catalog'}
          catalogStatus={catalogStatus}
          setCatalogStatus={setCatalogStatus}
          editCatalogName={catalogName}
          editCatalogImages={images}
          editCatalogFeatureImage={featureImage}
        ></NavigationBox>
        {!formLoader ? (
          <div className='addProductForm'>
            {/*Here begins the first part of the add product form where user inputs the media files related to the product */}
            <div
              className='addProductFormComponent productMediaForm'
              id='productMedia'
            >
              <div className='productMediaLeft'>
                <div
                  className='productMediaTitle'
                  style={{
                    textAlign: 'left',
                    color: '#181c32',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '23px',
                  }}
                >
                  Media
                </div>

                <div
                  className='productMediaFeatureImage'
                  onClick={() => onButtonClick(inputFile)}
                >
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={inputFile}
                    onChange={(e) => {
                      handleFileChange1(e)
                    }}
                  ></input>
                  <img
                    src={featureImageLink}
                    style={{
                      border: 'dashed #009ef7 1px',
                      borderRadius: '5px',
                      boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
                      width: '160px',
                      height: '160px',
                    }}
                  ></img>
                </div>

                <div className='featureImageBottomText'>
                  <div
                    style={{
                      fontWeight: '700',
                      fontSize: '14px',
                      color: '#181c32',
                      lineHeight: '17px',
                    }}
                  >
                    Featured Image
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *512 × 512 size
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *less than 1 mb
                  </div>
                  <div></div>
                </div>
              </div>
              <div className='productMediaRight'>
          <div
              className='productMediaFeatureImage'
              onClick={() => onButtonClick(inputFile2)}
            >
              <input
                type='file'
                style={{ display: 'none' }}
                ref={inputFile2}
                onChange={(event) => {
                  console.log("b",event);
                  handleFileChange2(event)
                }}
              ></input>
              <img
                src={bannerImageLink}
                style={{
                  border: 'dashed #009ef7 1px',
                  borderRadius: '5px',
                  boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
                  width: '160px',
                  height: '160px',
                  marginTop: '-3px'
                }}
                alt='Banner'
              ></img>
            </div>

            <div className='featureImageBottomText'>
              <div
                style={{
                  fontWeight: '700',
                  fontSize: '14px',
                  color: '#181c32',
                  lineHeight: '17px',
                }}
              >
                Banner Image
              </div>
              <div
                style={{
                  fontSize: '12px',
                  color: '#a1a5b7',
                  fontWeight: '400',
                }}
              >
                *512 × 512 size
              </div>
              <div
                style={{
                  fontSize: '12px',
                  color: '#a1a5b7',
                  fontWeight: '400',
                }}
              >
                *less than 1 mb
              </div>
              <div></div>
            </div>
            </div>
              {/* <FourImageSelector
            images={images}
            setImages={setImages}
          ></FourImageSelector> */}
            </div>

            {/* Here begins the second part of the form in which the user fills the product info */}

            <div
              className='addProductFormComponent productInfoComponent'
              id='productInfo'
            >
              <div className='productInfoTitle'>Catalog Information</div>
              <div className='productNameAndCategoryInputs'>
                <div className='inputName'>
                  <div
                    className='inputHeading'
                    style={{
                      textAlign: 'left',
                      color: '#3f4254',
                      fontWeight: '400',
                    }}
                  >
                    Enter Category Name
                  </div>
                  <input
                placeholder='Enter Catalog Name'
                style={{
                  width: '100%',
                  border: 'solid 1px #cccccc',
                  padding: '7px',
                  paddingLeft: '10px',
                  borderRadius: '5px',
                  fontFamily: 'Inter',
                  fontSize: '15px',
                  fontWeight: '500',
                }}
                value={catalogName}
                onChange={handleChangeInput
                  
                }
              />
              <div style={{ textAlign: 'right', marginTop: '5px', color: '#A1A5B7', fontSize: '14px' }}>
                {catalogName.length}/{maxLength}
              </div>
                </div>
              </div>
            </div>

            
            <div className='addProductFormComponent saveButtonComponent'>
              <div className='cancelButton' onClick={handleCancel} >Cancel</div>
              {!loading ? (
                <div
                  className='saveButton'
                  onClick={(e) => {
                    setLoading(true)
                    editCatalog(
                      featureImage,
                      imgChanged,
                      catalogName,
                      catalogStatus,
                      catalog?._id,
                      catalogNameChanged
                    )
                    setLoading(false)
                  }}
                >
                  Save Changes
                </div>
              ) : (
                <div className='saveLoading' style={{ background: '' }}>
                  <FontAwesomeIcon icon={faSpinner} spin>
                    {' '}
                  </FontAwesomeIcon>
                </div>
              )}
            </div>
          </div>
        ) : (
          <FormLoader></FormLoader>
        )}
      </>
    </>
  )
}

export default EditCatalogForm
