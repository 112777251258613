import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import axios from 'axios';
import { BASE_URL, cookieFetcher } from '../../helper';
import { redirect } from 'react-router-dom';

// Validation schema
const validationSchema = Yup.object({
  catalogName: Yup.string().required('Required'),
  catalogSeoTitle: Yup.string().required('Required'),
  catalogSeoDesc: Yup.string().required('Required'),
  catalogSeoTags: Yup.string().required('Required'),
  featureImage: Yup.mixed().required('Required'),
  images: Yup.array().of(Yup.mixed()).required('Required')
});

const initialValues = {
  catalogName: '',
  catalogStatus: '',
  catalogSeoTitle: '',
  catalogSeoDesc: '',
  catalogSeoTags: '',
  featureImage: null,
  images: []
};

const handleSubmit = async (values, { setSubmitting }) => {
  await createCatalog(
    values.featureImage,
    values.images,
    values.catalogName,
    values.catalogStatus,
    values.catalogSeoTitle,
    values.catalogSeoDesc,
    values.catalogSeoTags
  );
  setSubmitting(false);
};

// export const CreateCatalog = () => {
//   return (
//     <Formik
//       initialValues={initialValues}
//       validationSchema={validationSchema}
//       onSubmit={handleSubmit}
//     >
//       {({ setFieldValue, isSubmitting }) => (
//         <Form>
//           <div>
//             <label htmlFor="catalogName">Catalog Name</label>
//             <Field name="catalogName" type="text" />
//             <ErrorMessage name="catalogName" component="div" />
//           </div>
//           <div>
//             <label htmlFor="catalogStatus">Catalog Status</label>
//             <Field name="catalogStatus" type="text" />
//             <ErrorMessage name="catalogStatus" component="div" />
//           </div>
//           <div>
//             <label htmlFor="catalogSeoTitle">SEO Title</label>
//             <Field name="catalogSeoTitle" type="text" />
//             <ErrorMessage name="catalogSeoTitle" component="div" />
//           </div>
//           <div>
//             <label htmlFor="catalogSeoDesc">SEO Description</label>
//             <Field name="catalogSeoDesc" type="text" />
//             <ErrorMessage name="catalogSeoDesc" component="div" />
//           </div>
//           <div>
//             <label htmlFor="catalogSeoTags">SEO Tags</label>
//             <Field name="catalogSeoTags" type="text" />
//             <ErrorMessage name="catalogSeoTags" component="div" />
//           </div>
//           <div>
//             <label htmlFor="featureImage">Feature Image</label>
//             <input
//               name="featureImage"
//               type="file"
//               onChange={(event) => {
//                 setFieldValue("featureImage", event.currentTarget.files[0]);
//               }}
//             />
//             <ErrorMessage name="featureImage" component="div" />
//           </div>
//           <div>
//             <label htmlFor="images">Additional Images</label>
//             <input
//               name="images"
//               type="file"
//               multiple
//               onChange={(event) => {
//                 setFieldValue("images", Array.from(event.currentTarget.files));
//               }}
//             />
//             <ErrorMessage name="images" component="div" />
//           </div>
//           <button type="submit" disabled={isSubmitting}>
//             Submit
//           </button>
//         </Form>
//       )}
//     </Formik>
//   );
// };

const wrapInQuotes = (text) => `"${text}"`;

function containsOnlyAlphanumeric(str) {
  return /^[a-zA-Z0-9]+$/.test(str);
}

export const createCatalog = async (
  featureImage,
  images,
  imgalt,
  banneralt,
  catalogName,
  catalogStatus,
  sMTitle,
  sMDesc,
  sMMetaTags,
  sMSlug
) => {
  

  // Basic client-side validation
  if (!catalogName || !sMTitle || !sMDesc || !sMMetaTags || !sMSlug) {
    Swal.fire({
      icon: 'info',
      // title: 'Please',
      text: 'Please fill in all required fields.',
    });
    return;
  }

  if(!containsOnlyAlphanumeric(imgalt)) {
    alert("Image alt can have only alphanumeric values!");
    return;
  }
  if(!containsOnlyAlphanumeric(banneralt)) {
    alert("Banner alt can have only alphanumeric values!");
    return;
  }
  if(!containsOnlyAlphanumeric(sMSlug)) {
    alert("Meta url can have only alphanumeric values!");
    return;
  }

  console.log("img", featureImage);

  const formData = new FormData();



  formData.append('photos', featureImage);

  images.forEach((item, index) => {
    if (item) {
      formData.append('photos', item);
    }
  });

  let formattedcatalogName = wrapInQuotes(catalogName)
  formData.append('title', formattedcatalogName);
  formData.append('status', catalogStatus);

  // formData.append('title', catalogName);
  formData.append('userId', cookieFetcher('vendorId'));
  formData.append('url', sMSlug);

  let formattedseotitle = wrapInQuotes(sMTitle)
  formData.append('seo_meta_title', formattedseotitle);
  //formData.append('seo_meta_desc', catalogSeoDesc);
  //formData.append('seo_meta_tags', catalogSeoTags);

  try {
    const response = await axios.post(`${BASE_URL}/vendor/catalog/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${cookieFetcher('token')}`,
      },
      mode: "cors",
      cache: 'default'
    });
    console.log("response", response);

    if (response.status === 200 && response.data) {
      Swal.fire({
        icon: 'success',
        title: 'Category Created',
        text: `Successfully Created!`,
        confirmButtonText: 'Go to Categories!',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/category';
        }
      });
    } 
    else {
      Swal.fire({
        icon: 'info',
        title: 'Failed, try again',
        text: `${response.status} : ${response.data?.message || 'Unknown error'}`,
      });
    }
  } catch (error) {
    console.log("err", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: `${error}: ${error.message}, Please try again after sometime`,
    });
  }
};