import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from '../Orders/OrdersHeader'
import NavigationBox from '../../components/NavigationBox/NavigationBox'
import AddProductForm from '../../components/AddProductForm/AddProductForm'
import AddCatalogForm from '../../components/AddCatalogForm/AddCatalogForm'
import Footer from '../../components/Footer/Footer'
import { useState } from 'react'
import { useEffect } from 'react'
import { cookieFetcher, loginChecker } from '../../helper'
import { Helmet } from 'react-helmet'
import { CreateCatalog } from '../../components/AddCatalogForm/CatalogCreator'

function AddCatalog() {
  const [sidebar, setSidebar] = useState(true)

  useEffect(() => {
    loginChecker()
  }, [])

  return (
    <div className='addProductPage'>
      <Helmet
        title='Add Catalog'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar}></Sidebar>
      <div className='addProductPageMain'>
        <div className='addProductPageMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          <div className='addProductSection'>
          
            <AddCatalogForm></AddCatalogForm>
          </div>
        </div>
        <div className='addProductPageMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default AddCatalog
