import React, { useState } from 'react'
import '../SignIn/SignIn.css'
import ReactFlagsSelect from 'react-flags-select'

function SignIn() {
  const [select, setSelect] = useState('IN')

  return (
    <div className='signInPage'>
      <div className='signInPopUp'>
        <div className='signInimageHolder'>
          <img src='https://res.cloudinary.com/nikhil-patil-web/image/upload/v1689078419/SignIn_bvdsux.png'></img>
        </div>
        <div className='signInTitle'>Sign In / Login</div>
        <div className='inputHolder' style={{ display: 'flex', gap: '10px' }}>
          <ReactFlagsSelect
            selected={select}
            onSelect={(e) => {
              setSelect(e)
            }}
            showSelectedLabel={false}
          ></ReactFlagsSelect>
          <input placeholder='Phone'></input>
        </div>
        <div className='verifyButton'>Verify</div>
        <div className='agreeButton'>
          <input type='checkBox'></input>
          <div>
            I Agree to the{' '}
            <span style={{ color: '#009ef7' }}> Terms & Conditions</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
