import React, { useState } from 'react'
import '../OTP/OTP.css'
import OTPInput from 'react-otp-input'
import { useNavigate } from 'react-router-dom'

function OTP() {
  const navigate = useNavigate()

  const [otp, setOtp] = useState('')

  return (
    <div className='otpPage'>
      <div className='otpPanel'>
        <div className='OTPTitle otpPanelComp'>
          <div className='OTPTitleText'>OTP Verification</div>
          <div className='OTPInstructionText'>
            Enter the verification code we sent to
          </div>
          <div className='OTPNumberText'>******9875</div>
        </div>
        <div className='OTPInput otpPanelComp'>
          <div className='OTPInputLabel'>Type your 6 digit security code</div>
          <OTPInput
            value={otp}
            onChange={setOtp}
            // style={{ display: 'flex !important', gap: '20px !important' }}
            inputStyle='inputStyle'
            numInputs={6}
            renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
          ></OTPInput>
        </div>
        <div className='OTPSubmit otpPanelComp'>Submit</div>
        <div className='OTPNumberUpdate otpPanelComp'>
          <div className='OTPNumberUpdate1 OTPNumberUpdateComp '>
            {' '}
            Didn’t get the code ?
            <span style={{ color: '#009ef7' }} onClick={() => {}}>
              {' '}
              Resend{' '}
            </span>
          </div>
          <div className='OTPNumberUpdate2 OTPNumberUpdateComp'>
            Wrong Number ?
            <span
              style={{ color: '#009ef7' }}
              onClick={() => {
                navigate('/signIn')
              }}
            >
              {' '}
              Edit{' '}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OTP
