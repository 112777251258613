import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import OrdersHeader from './OrdersHeader'
import OrdersMain from './OrdersMain'
import '../Orders/Orders.css'
import Footer from '../../components/Footer/Footer'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { cookieFetcher } from '../../helper'

function Orders() {
  const [sidebar, setSidebar] = useState(true)

  return (
    <div className='orderPage'>
      <Helmet
        title='Orders'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar} page={'orders'}></Sidebar>
      <div className='orders'>
        <div>
        <OrdersHeader
          sidebar={sidebar}
          setSidebar={setSidebar}
        >

        </OrdersHeader>
        <div className='orders_box'>

          <OrdersMain></OrdersMain>
        </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}

export default Orders
