import React from 'react'
import './Footer.css'

const getCurrentYear = () => {
  return new Date().getFullYear();
};


function Footer() {
  return (
    <div className='lofazFooter'>
      <div className='lofazFooterLeft'>
        <div
          className='footerYear'
          style={{ color: '#7e8299', fontSize: '14px', fontWeight: '500' }}
        >
          {getCurrentYear()}&copy;
        </div>
        <div style={{ fontSize: '14px', fontWeight: '500' }}>Lofazs</div>
        <div style={{ fontSize: '14px', fontWeight: '500' }}>V-1.06</div>
       
      </div>
      {/* <div
        className='lofazFooterRight'
        style={{ color: '#7e8299', fontSize: '14px', fontWeight: '500' }}
      >
        <div className='about'>About</div>
        <div className='support'>Support</div>
        <div className='purchase'>Purchase</div>
      </div> */}
    </div>
  )
}
<script>


</script>
export default Footer
