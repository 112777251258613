import React from "react";
import Footer from "../../components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import OrdersHeader from "../Orders/OrdersHeader";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { cookieFetcher } from "../../helper";

export default function CustomerDetails(){
    const { custId } = useParams()
    const [sidebar, setSidebar] = useState(true);
  return (
    <div className="couponCodePage">
      <Helmet
        title="Customer Details"
        link={{ rel: "icon", type: "image/png", href: cookieFetcher("photo") }}
      />
      <Sidebar sidebar={sidebar} page={"customer"} />
      <div className="couponCodeMain">
        <OrdersHeader sidebar={sidebar} setSidebar={setSidebar} />
        <div className="coming-soon-container">
        <div className="container mx-auto p-4">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-xl font-semibold mb-2" style={{textAlign:'start'}}>Customer details</h2>
        <p className="text-gray-600"  style={{textAlign:'start'}}>Customer ID: #{custId}</p>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <p className="text-gray-600 mb-1" style={{textAlign:'start'}}>Name</p>
            <p className="font-semibold" style={{textAlign:'start'}}>Test Cutsomer</p>
          </div>
          <div>
            <p className="text-gray-600 mb-1" style={{textAlign:'start'}}>Mobile number</p>
            <p className="font-semibold" style={{textAlign:'start'}}>+91-9999999999</p>
          </div>
          <div>
            <p className="text-gray-600 mb-1" style={{textAlign:'start'}}>Email address</p>
            <p className="font-semibold" style={{textAlign:'start'}}>piyanshugehani@gmail.com</p>
          </div>
          <div>
            <p className="text-gray-600 mb-1" style={{textAlign:'start'}}>Address</p>
            <p className="font-semibold" style={{textAlign:'start'}}>-</p>
          </div>
        </div>
      </div>

      <div className="p-4">
        <h2 className="text-xl font-semibold mb-4" style={{textAlign:'start'}}>All orders</h2>
        <div className="flex space-x-4 mb-4">
          <button className="px-4 py-2 bg-gray-200 rounded">All</button>
          <button className="px-4 py-2 bg-gray-200 rounded">Pending</button>
          <button className="px-4 py-2 bg-gray-200 rounded">Accepted</button>
          <button className="px-4 py-2 bg-gray-200 rounded">Shipped</button>
          <button className="px-4 py-2 bg-gray-200 rounded">Delivered</button>
          <button className="px-4 py-2 bg-gray-200 rounded">Others</button>
        </div>
        <div className="text-center mt-8">
          <img
            src="https://via.placeholder.com/150"
            alt="Placeholder"
            className="mx-auto mb-4"
          />
          <p className="text-gray-600">Abandoned orders will appear here</p>
        </div>
      </div>
    </div>
        </div>
        <Footer />
      </div>
    </div>
    );
}