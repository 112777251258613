// import React from 'react'
import React, { useEffect } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import 'bootstrap/dist/css/bootstrap.css'
import HomePageForm from './HomePageForm'
import HomeHeader from './HomeHeader'
import '../Home/Home.css'
import OrdersHeader from '../Orders/OrdersHeader'
import Footer from '../../components/Footer/Footer'
import { cookieFetcher, loginChecker } from '../../helper'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useState } from 'react'
import SmallSidebar from '../../components/SmallSidebar/SmallSidebar'
import { Helmet } from 'react-helmet'
import AddSlider from './Bannercomp/AddSlider'
const Homebanner = () => {
    const navigate = useNavigate()
    const [sidebar, setSidebar] = useState(true)
  return (
    <div className='homePage'>
      <Helmet
        title='Home'
        link={{ rel: 'icon', type: 'image/png', href: cookieFetcher('photo') }}
      ></Helmet>
      <Sidebar sidebar={sidebar} page={'home'}></Sidebar>
      <div className='homeMain'>
        <div className='homeMainTop'>
          <OrdersHeader
            sidebar={sidebar}
            setSidebar={setSidebar}
          ></OrdersHeader>
          </div>
          <div>
            <AddSlider></AddSlider>
          </div>

        <div className='homeMainBottom'>
          <Footer></Footer>
        </div>
      </div>
    </div>
  )
}

export default Homebanner