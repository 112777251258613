import React, { useState } from 'react'
import { Button, Collapse } from 'reactstrap'
import Sidebar from '../Sidebar/Sidebar'

const CustomCollapse = () => {
  const [isOpen, setIsOpen] = useState(true)

  const toggleCollapse = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Button color='primary' onClick={toggleCollapse}>
        Toggle Collapse
      </Button>
      <div>
        <Collapse horizontal isOpen={isOpen}>
          Hi Nikhil Patil
        </Collapse>
      </div>
    </>
  )
}

export default CustomCollapse
