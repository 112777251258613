import React from "react";
import { useNavigate } from "react-router-dom";

const withNavigation = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    // Function to handle navigation
    const handleRedirect = (path, state = {}) => {
      navigate(path, { state });
    };
    
    // Render the wrapped component with navigation props
    return <WrappedComponent {...props} handleRedirect={handleRedirect} />;
  };
};

export default withNavigation;
