import React, { useEffect, useState } from 'react'
import { BASE_URL, cookieFetcher } from '../../../helper'
import Swal from 'sweetalert2'
import { logDOM } from '@testing-library/react'

function ShippingAndDeliveryForm() {
  const [shippingCost, setShippingCost] = useState(0)
  const [freeDeliveryAfter, setFreeDeliveryAfter] = useState(0)

  const fetcher = async () => {
   try {
    const response = await fetch(`${BASE_URL}/shipping`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookieFetcher('token')}`,
      },
    })
    const res = await response.json()
    console.log("response",res);
    
    
      if (res && res.shipping_cost) {
        setShippingCost(res.shipping_cost)
      }
      if (res && res.free_shipping_after) {
        setFreeDeliveryAfter(res.free_shipping_after)
      }
   } catch (error) {
    console.log("error fetching",error);
    
   }
    
  }

  const submitter = async () => {
    console.log("hi");
    
      try {
        const response = await fetch(`${BASE_URL}/shipping`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${cookieFetcher('token')}`,
        'Content-Type': 'Application/JSON',
      },
      body: JSON.stringify({
        shipping_cost: shippingCost,
        free_shipping_after: freeDeliveryAfter,
      }),
    })
    const res = await response.json()
    console.log("response",res);
    
    
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Catalog Status updated successfully!',
      showConfirmButton: false,
      timer: 1000,
      // timerProgressBar: true,
      
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    })
      fetcher()
    
    } catch (error) {
      console.log("hi");
      
        console.log("error updating",error);
        
      }
    }
  

  useEffect(() => {
    fetcher()
  }, [])

  return (
    <div className='shippingAndDeliveryForm'>
      <div className='shippingAndDeliveryFormComponent'>
        <div className='shippingAndDeliveryTitle'>Shipping & Delivery</div>
        <div className='shippingAndDeliveryContent'>
          <div className='sAndDinput'>
            <div className='inputLabel'>Delivery Charge Per Order ₹:</div>
            <input
              value={shippingCost}
              onChange={(e) => {
                setShippingCost(e.target.value)
              }}
              placeholder='Delivery Charge Per Order ₹:'
            ></input>
          </div>
          <div className='sAndDinput'>
            <div className='inputLabel'>Free Delivery Above ₹:</div>
            <input
              value={freeDeliveryAfter}
              onChange={(e) => {
                setFreeDeliveryAfter(e.target.value)
              }}
              placeholder='Free Delivery Above ₹:'
            ></input>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-end' style={{gap:'5px'}}>
        <div
          className='cancelButton'
          onClick={() => {
            window.location.href="/settings"
          }}
        >
          Cancel
        </div>
        <div
          className='saveButton'
          onClick={() => {
            submitter()
          }}
          style={{backgroundColor:'#009EF7'}}
        >
          Save Changes
        </div>
      </div>
      </div>
      
    </div>
  )
}

export default ShippingAndDeliveryForm
