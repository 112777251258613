import React from "react";
import { BsSearch } from "react-icons/bs";
import { MdNotificationsActive } from "react-icons/md";
import { LuClipboardList } from "react-icons/lu";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { CiLight } from "react-icons/ci";
import { PiSquaresFour } from "react-icons/pi";
import { useState, useEffect } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { APP_URL, BASE_URL, cookieFetcher, logout } from "../../helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import ReactSwitch from "react-switch";
import Swal from "sweetalert2";
import "./Orders.css";
import StoreStatusModal from "../../components/EditProfileForm/EditProfileModals/StoreStatusModal";

function OrdersHeader({ sidebar, setSidebar }) {
  const user = cookieFetcher("username");
  console.log("username", user);

  const [modalOpen, setModalOpen] = useState(false);

  // State to manage the switch status
  const [store, setStore] = useState(false);

  const fetcher = async () => {
    try {
      const response = await fetch(`${BASE_URL}/setting/shop`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${cookieFetcher("token")}`,
        },
      });
      const res = await response.json();
      
      if (response.ok) {
        setStore(res.shop_status);
        // setOrder(res.order_status);
        // setWhatsapp(res.whatsapp_status);
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed to fetch status",
          text: res.message || "An error occurred while fetching the status.",
        });
      }
    } catch (error) {
      console.error("Error fetching shop status:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while fetching the status.",
      });
    }
  };

  const storeStatusToggle = async (type, value) => {
    try {
      let statusField = "";
      switch (type) {
        case "store":
          statusField = "shop_status";
          break;
        case "whatsapp":
          statusField = "whatsapp_status";
          break;
        case "order":
          statusField = "order_status";
          break;
        default:
          return;
      }

      const response = await fetch(`${BASE_URL}/setting`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${cookieFetcher("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ [statusField]: value }),
      });

      const res = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Store Status updated successfully!",
          showConfirmButton: false,
          timer: 1000,
          showClass: {
            popup: `
              animate__animated
              animate__fadeInUp
              animate__faster
            `,
          },
          hideClass: {
            popup: `
              animate__animated
              animate__fadeOutDown
              animate__faster
            `,
          },
        });
        // Update the state based on the type
        if (type === "store") setStore(value);
        // if (type === 'whatsapp') setWhatsapp(value);
        // if (type === 'order') setOrder(value);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.message || "Something went wrong!",
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);
      // Swal.fire({
      //   icon: "error",
      //   title: "Error",
      //   text: "An error occurred while updating the status.",
      // });
    }
  };

  useEffect(() => {
    fetcher();
  }, []);

  const handleCopy = (e) => {
    // Prevent the onClick of the parent div from triggering
    e.stopPropagation();
    const url = `${APP_URL}/${user}`;
    // Copy the URL to the clipboard
    navigator.clipboard
      .writeText(url)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "URL Copied to clipboard",
          text: "You can forward this copied link to your customer.",
        });
        // Optionally, show a success message or tooltip
      })
      .catch((err) => {
        console.error("Failed to copy URL:", err);
      });
  };

  return (
    <div className="ordersHeader">
      {sidebar ? (
        <div
          className="ordersHeaderLeft"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSidebar(!sidebar);
          }}
        >
          <FontAwesomeIcon icon={faAnglesLeft} />
        </div>
      ) : (
        <div
          className="ordersHeaderLeft closedIcon"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSidebar(!sidebar);
          }}
        >
          <FontAwesomeIcon icon={faAnglesRight}></FontAwesomeIcon>
        </div>
      )}

      <div className="ordersHeaderRight">
        <div className="profileImage">
          <UncontrolledDropdown>
            <DropdownToggle
              style={{ background: "white", border: "none", padding: "0px" }}
            >
              <img
                className="profile_toggle"
                border-radius="5px"
                src="https://ik.imagekit.io/kotharitech/Lofaz/Lofaz-admin/lofaz_logo.png?updatedAt=1716464379919"
                alt="Profile"
              ></img>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <div className="custom-dropdown-item flex items-center space-x-2">
                  <a href={`${APP_URL}/${user}`} target="_blank">
                    <span
                      className="text-gray-700 focus:text-white text-sm"
                      style={{ textDecoration: "none" }}
                    >{`${APP_URL}/${user}`}</span>
                  </a>
                  <span class="material-symbols-outlined" onClick={handleCopy}>
                    content_copy
                  </span>
                </div>
              </DropdownItem>
              <DropdownItem>
                <div className="custom-dropdown-item flex items-center space-x-2">
                  <ReactSwitch
                    onChange={(e) => {
                      console.log(e);
                      if (e === false) {
                        // setModalOpen(true);
                      }
                      storeStatusToggle("store", e);
                    }}
                    checked={store}
                    uncheckedIcon=""
                    checkedIcon=""
                    onColor="#00D907"
                    offColor="#ff0000"
                    height={15} // Adjust height
                    width={30}  // Adjust width
                    handleDiameter={15} // Adjust handle size
                  ></ReactSwitch>

                  <label
                    htmlFor="store-status"
                    className="mx-2 text-center"
                  >
                    <span className="text-sm">Store Status</span>
                  </label>
                </div>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  logout();
                  // Cookies.remove('token')
                  // Cookies.remove('number')
                  // Cookies.remove('vendorId')
                  // document.location.href = '/signin'
                }}
              >
                <label className="custom-dropdown-item flex items-center space-x-2 text-md cursor-pointer text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 -960 960 960"
                    width="24px"
                    fill="#5f6368"
                    className="mr-2"
                  >
                    <path d="M224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 224.62-800h256.15v40H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 9.24 7.69 16.93 7.69 7.69 16.93 7.69h256.15v40H224.62Zm433.84-178.46-28.08-28.77L723.15-460H367.69v-40h355.46l-92.77-92.77 28.08-28.77L800-480 658.46-338.46Z" />
                  </svg>
                  <span className="text-sm">Sign Out</span>
                </label>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <StoreStatusModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
      </div>
    </div>
  );
}

export default OrdersHeader;
