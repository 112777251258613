import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { faShare, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap'
import { BASE_URL, cookieFetcher } from '../../../helper'
import Swal from 'sweetalert2'
import ReactSwitch from 'react-switch'

function SliderDropdown({ product, fetchStatus, setFetchStatus }) {
  const deleting = async (product) => {
    const res = await fetch(
      `${BASE_URL}/vendor/catalog/product/${product._id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${cookieFetcher('token')}`,
        },
      }
    )
    const response = await res.json()
    if (response.status === 200) {
      setFetchStatus(!fetchStatus)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${response.message}`,
      })
    }
  }

  const productDeleter = async (product) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: `Are you sure you want to delete ${product.title}?`,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleting(product)
      }
    })
  }

  return (
    <UncontrolledDropdown >
      <DropdownToggle
        style={{
          background: 'rgb(249, 249, 249)',
          color: 'rgb(126, 130, 153)',
          border: 'none',
          fontSize: '12px',
          fontWeight: '600',
        }}
        caret
      >
        Actions
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() => {
            window.location.href = `/products/edit/${product._id}`
          }}
          style={{ display: 'flex', gap: '7px' }}
        >
          <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
          Edit
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            productDeleter(product)
          }}
          style={{ display: 'flex', gap: '7px' }}
        >
          <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>Delete
        </DropdownItem>
        <DropdownItem style={{ display: 'flex', gap: '7px' }}>
            Active
        <ReactSwitch
            onChange={(e) => {
              // console.log(e)
              // if (e === false) {
                // setModalOpen(true)
              // }
              // setStore(e)
              // storeStatusToggle('store', e)
            }}
            // checked={store}
            uncheckedIcon=''
            checkedIcon=''
            onColor='#009ef7'
            offColor='#ff0000'
          ></ReactSwitch>
</DropdownItem>
        
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default SliderDropdown
