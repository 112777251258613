import React, { useEffect, useRef, useState } from 'react'
import './AddProductForm.css'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FourImageSelector from './FourImageSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faSpinner } from '@fortawesome/free-solid-svg-icons'
import NavigationBox from '../NavigationBox/NavigationBox'
import { createProduct } from './CreateProduct'
import Select from 'react-select'
import { BASE_URL, cookieFetcher } from '../../helper'
import Warning from '../Warning/Warning'
import axios from 'axios'
import FormLoader from '../FormLoader/FormLoader'
import './AddProductForm.css'
import { useNavigate } from 'react-router-dom'
import { generateAboutText } from '../../helper'
// import FormLoader from '../FormLoader/FormLoader'

function AddProductForm() {
  const [featureImageLink, setFeatureImageLink] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [featureImage, setFeatureImage] = useState(
    'https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688966827/LofazInputImage_narqjv.png'
  )
  const [filesSelected, setFilesSelected] = useState(false)
  const [images, setImages] = useState(new Array(4))
  const [productName, setProductName] = useState('')
  const [productStatus, setProductStatus] = useState(true)
  const [productCategory, setProductCategory] = useState('')
  const [salesPrice, setSalesPrice] = useState(0)
  const [mrp, setMrp] = useState(0)
  const [description, setDescription] = useState('')
  const [stock, setStock] = useState(0)
  const [label, setLabel] = useState('new')
  const [unit, setUnit] = useState('607560c37b363921bc960299')
  const [listOfCatalogs, setListOfCatalogs] = useState([])
  const [listOfUnits, setListOfUnits] = useState([])
  const [nameWarning, setNameWarning] = useState(false)
  const [priceWarning, setPriceWarning] = useState(false)
  const [mrpAndPriceWarning, setMrpAndPriceWarning] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingGen,setLoadingGen]=useState(false)    //prod desc
  const [formLoader, setFormLoader] = useState(false)
  const [seoTitle,setSeoTitle]=useState('')
  const [seoDesc,setSeoDesc]=useState('')
  const [loadingGenforTitle, setLoadingGenforTitle] = useState(false);
  const [loadingGenforDesc, setLoadingGenforDesc] = useState(false);    //meta desc
  const maxLength=50;
  const maxLengthDes=250;
  const navigate=useNavigate();

  const inputFile = useRef(null)
  const inputMultipleFile = useRef(null)

  const onButtonClick = () => {
    inputFile.current.click()
  }

  const onButtonClickMultiple = () => {
    inputMultipleFile.current.click()
  }

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    setFeatureImage(event.target.files[0])
    setFeatureImageLink(URL.createObjectURL(event.target.files[0]))
  }

  const handleMultipleFileChange = (e) => {
    if (e.target && e.target.files.length > 0) {
      let tempArr = new Array(4)
      if (e.target.files[0]) {
        tempArr[0] = e.target.files[0]
      }
      if (e.target.files[1]) {
        tempArr[1] = e.target.files[1]
      }
      if (e.target.files[2]) {
        tempArr[2] = e.target.files[2]
      }
      if (e.target.files[3]) {
        tempArr[3] = e.target.files[3]
      }
      setImages(tempArr)
      setFilesSelected(true)
    }
  }



  useEffect(() => {
    const fetcherUnit = async () => {
      setFormLoader(true)
      const res = await fetch(`${BASE_URL}/unit`)
      const response = await res.json()
      if (response.status === 201) {
        let arr = []
        if (response.data.length > 0) {
          response.data.map((item) => {
            arr.push({ value: item._id, label: item.unit })
          })
        }
        setListOfUnits(arr)
      }
    }

    const fetcherCatalog = async () => {
      const res = await fetch(
        `${BASE_URL}/vendor/catalog/${cookieFetcher('vendorId')}`
      )
      const response = await res.json()
      if (response.status === 200) {
        let arr = []
        if (response.data.length > 0) {
          response.data.map((item, index) => {
            arr.push({ value: item._id, label: item.title })
          })
        }
        setListOfCatalogs(arr)
        setFormLoader(false)
      }
    }

    fetcherUnit()
    fetcherCatalog()
  }, [])

  const handleChangeInputMetaDes = (e) => {
    const value = e;
    if (value.length <= maxLengthDes) {
      setSeoDesc(value);
    }
  };

  const generateAboutTextforProd = async (keywords) => {
    try {
      setLoadingGen(true);
      const API_KEY = 'AIzaSyAMKjcnhLfkHbvBBYrvcuBngcizr0FLc8Q'
      const response = await axios({
        url: `https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key=${
          API_KEY
        }`,
        method: "post",
        data: {
          contents: [{ parts: [{ text: `Generate a product description for ${keywords} in less than 100 characters.` }] }],
        },
      });
      console.log("gemini",response);
      return response.data.candidates[0].content.parts[0].text; 
    } catch (error) {
      console.error("Error generating about text:", error);
      return '';
    }
    finally {
      setLoadingGen(false);
    }
  };

  const getPlainTextLength = (htmlString) => {
    // Remove all HTML tags using a regular expression
    const plainText = htmlString.replace(/<[^>]*>/g, '');
    return plainText.length;
  };

  const handleGenerateClick = async () => {
    if(!productName){
      alert("Please enter some valid Product Name and Category before generating.")
      return
    }
    const generatedText = await generateAboutTextforProd(productName);
    if (generatedText!='' ){
      setDescription(generatedText);
    }
  };


  const handleGenerateClickforTitle = async () => {
    if (!productName) {
      alert("Please enter a valid catalog name before generating meta info.")
      return
    }
    setLoadingGenforTitle(true);
    const generatedText = await generateAboutText(`Generate a SEO ranked title for product : ${productName} in strictly less than 25 characters.`);
    if (generatedText != '') {
      setSeoTitle(generatedText);
    }
    setLoadingGenforTitle(false);
  };

  const handleGenerateClickforDes = async () => {
    if (!productName) {
      alert("Please enter a valid catalog name before generating meta info.")
      return
    }
    setLoadingGenforDesc(true);
    const generatedText = await generateAboutText(`Generate a SEO ranked description for product : ${productName} in strictly less than 100 characters.`);
    if (generatedText != '') {
      setSeoDesc(generatedText);
    }
    setLoadingGenforDesc(false);
  };

  const handleChangeInputMetaTitle = (e) => {
    const value = e.target.value;
    if (value.length <= maxLength) {
      setSeoTitle(value)
    }
  }


  const handleSave = () => {
    console.log("hi");
    let isValid = true;
    if (productName.trim() === '') {
      setNameWarning(true);
      isValid = false;
    }
    if (mrp <= 0 || salesPrice <= 0 || salesPrice > mrp) {
      setPriceWarning(true);
      setMrpAndPriceWarning(mrp < salesPrice);
      isValid = false;
    }
    if (isValid) {
      setLoading(true);
      createProduct(
        featureImage,
        images,
        productCategory,
        productName,
        productStatus,
        unit,
        salesPrice,
        mrp,
        description,
        stock,
        label,
        setNameWarning,
        setPriceWarning,
        setLoading,
        setMrpAndPriceWarning
      );
    }
  };


  useEffect(() => {
    console.log('featureImage=======>', featureImage)
    console.log('images============>', images)
    console.log('productCategory==========>', productCategory)
    console.log('productName============>', productName)
    console.log('productStatus==========>', productStatus)
    console.log('unit=============>', unit)
    console.log('salesPrice==============>', salesPrice)
    console.log('mrp==========>', mrp)
    console.log('description===========>', description)
    console.log('stock===========>', stock)
    console.log('label============>', label)
    console.log('productStatus=============>', productStatus)
  }, [
    featureImage,
    images,
    productCategory,
    productName,
    unit,
    salesPrice,
    mrp,
    productStatus,
    description,
    stock,
    label,
  ])

  return (
    <>
      
        <>
          <NavigationBox
            productStatus={productStatus}
            setProductStatus={setProductStatus}
            type={'product'}
            addProductFeatureImage={featureImage}
            addProductName={productName}
            addProductImages={images}
            edit={false}
            addProductUnit={unit}
            addProductSalesPrice={salesPrice}
            addProductCategory={productCategory}
            addProductMrp={mrp}
            addProductDescription={description}
            addProductStock={stock}
            addProductLabel={label}
            setNameWarning={setNameWarning}
            setPriceWarning={setPriceWarning}
            setLoading={setLoading}
            setMrpAndPriceWarning={setMrpAndPriceWarning}
            nameWarning={nameWarning}
            priceWarning={priceWarning}
            mrpAndPriceWarning={mrpAndPriceWarning}
          ></NavigationBox>
          <div className='addProductForm'>
            {/*Here begins the first part of the add product form where user inputs the media files related to the product */}
            <div
              className='addProductFormComponent productMediaForm'
              id='productMedia'
            >
              <div className='productMediaLeft'>
                <div
                  className='productMediaTitle'
                  style={{
                    textAlign: 'left',
                    color: '#181c32',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '23px',
                  }}
                >
                  Media
                </div>

                <div
                  className='productMediaFeatureImage'
                  onClick={() => onButtonClick()}
                >
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    ref={inputFile}
                    onChange={(e) => {
                      handleFileChange(e)
                    }}
                  ></input>
                  <img
                    src={featureImageLink}
                    style={{
                      border: 'dashed #009ef7 1px',
                      borderRadius: '5px',
                      // boxShadow: '0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075)',
                      boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)',
                      width: '170px',
                      height: '155px',
                    }}
                  ></img>
                </div>

                <div className='featureImageBottomText'>
                  <div
                    style={{
                      fontWeight: '700',
                      fontSize: '14px',
                      color: '#181c32',
                      lineHeight: '17px',
                    }}
                  >
                    Featured Image
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *1000 × 1000 size
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                      color: '#a1a5b7',
                      fontWeight: '400',
                    }}
                  >
                    *less than 1 mb
                  </div>
                  <div></div>
                </div>
              </div>
              {!filesSelected ? (
                <div className='productMediaRight'>
                  <input
                    type='file'
                    multiple
                    style={{ display: 'none' }}
                    ref={inputMultipleFile}
                    onChange={(e) => {
                      handleMultipleFileChange(e)
                    }}
                  ></input>
                  <div
                    className='dropFilesSection'
                    onClick={() => onButtonClickMultiple()}
                  >
                    <div className='dropFilesIcon'>
                      <img src='https://res.cloudinary.com/nikhil-patil-web/image/upload/v1688971931/LofazUploadImage_bykfaq.png'></img>
                    </div>
                    <div className='dropFilesTexts'>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '17px',
                          fontWeight: '600',
                          color: '#181c32',
                        }}
                      >
                        Drop files here or click to upload.
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '13px',
                          fontWeight: '500',
                          color: '#b5b5c3',
                        }}
                      >
                        Upload up to 4 files
                      </div>
                    </div>
                  </div>
                  <div className='galleryImagesText'>
                    <div
                      style={{
                        textAlign: 'left',
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#181c32',
                        lineHeight: '17px',
                      }}
                    >
                      Gallery Images
                    </div>
                    <div
                      style={{
                        textAlign: 'left',

                        fontSize: '12px',
                        color: '#a1a5b7',
                        fontWeight: '400',
                      }}
                    >
                      *1000x1000 size with size less than 1 mb
                    </div>
                  </div>
                </div>
              ) : (
                <FourImageSelector
                  images={images}
                  setImages={setImages}
                ></FourImageSelector>
              )}
            </div>

            {/* Here begins the second part of the form in which the user fills the product info */}

            <div
              className='addProductFormComponent productInfoComponent'
              id='productInfo'
            >
              <div className='productInfoTitle'>Product Information</div>
              <div className='productNameAndCategoryInputs'>
                <div className='inputName'>
                  <div
                    className='inputHeading'
                    style={{
                      textAlign: 'left',
                      color: '#3f4254',
                      fontWeight: '500',
                    }}
                  >
                    Product Name
                  </div>
                  <input
                    placeholder='Product Name'
                    style={{
                      width: '100%',
                      border: ' solid 1px #cccccc',
                      padding: '7px',
                      paddingLeft: '10px',
                      borderRadius: '5px',
                      fontFamily: 'Inter',
                      fontSize: '15px',
                      fontWeight: '500',
                    }}
                    value={productName}
                    onChange={(e) => {
                      setNameWarning(false)
                      setProductName(e.target.value)
                    }}
                  ></input>
                  {nameWarning && <Warning type={'name'}></Warning>}
                </div>
                <div className='inputCategory'>
                  <div className='d-flex align-tems-center justify-content-between'>
                  <div
                    className='inputHeading'
                    style={{
                      textAlign: 'left',
                      color: '#3f4254',
                      fontWeight: '500',
                    }}
                  >
                    Product Category
                  </div>
                  <a href='/addCatalog' style={{color:'#009EF7'}} className='no-underline hover:underline'><span>Add New Category?</span></a>
                  </div>
                  <Select
                    options={listOfCatalogs}
                    placeholder='Select an option'
                    onChange={(e) => {
                      setProductCategory(e.value)
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  ></Select>
                </div>
              </div>
              <div
                className='productDescriptionInput'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                  <div className="d-flex justify-content-between align-items-center">
                <div
                  className='productDescriptionTitle'
                  style={{
                    textAlign: 'left',
                    color: '#181c32',
                    fontWeight: '500',
                  }}
                >
                  Description
                </div>
                <button style={{color:'#009ef7'}}  className='no-underline hover:underline' onClick={handleGenerateClick}>{loadingGen ? 'Generating...' :'Generate Description through AI?'}</button>
                </div>
                <div className='productDescriptionTE'>
                  <ReactQuill
                    theme='snow'
                    style={{
                      textAlign: 'left',
                      color: 'black',
                    }}
                    onChange={(e) => {
                      setDescription(e)
                    }}
                    placeholder='Type your text here........'
                    value={description}
                  ></ReactQuill>
                </div>
              </div>
              <div className='productMSC'>
                <div className='productMSCcomponent productMRP'>
                  <div className='inputHeading'>MRP</div>
                  <input
                  type='number'
                  className={`inputField ${
                    mrpAndPriceWarning ? 'inputWarning' : ''
                  }`}
                  placeholder='MRP'
                  onChange={(e) => setMrp(parseFloat(e.target.value))}
                  onFocus={() => setMrpAndPriceWarning(false)}
                ></input>
                  {mrpAndPriceWarning ? (
                  <div className='warningText'>
                    MRP should not be less than Sales price
                  </div>
                ) : (
                  ''
                )}
                </div>
                <div className=' productMSCcomponent productSalePrice'>
                  <div className='inputHeading'>Sale Price</div>
                  <input
                  type='number'
                  className={`inputField ${priceWarning ? 'inputWarning' : ''}`}
                  placeholder='Sales Price'
                  onChange={(e) => setSalesPrice(parseFloat(e.target.value))}
                  onFocus={() => setPriceWarning(false)}
                ></input>
                {priceWarning ? (
                  <div className='warningText'>
                    Sales price should not be greater than MRP
                  </div>
                ) : (
                  ''
                )}
                </div>
                <div className=' productMSCcomponent productCostPrice'>
                  <div className='inputHeading'>Cost Per Item</div>
                  <input disabled placeholder='Cost Per Item'></input>
                  <div className='costText'>
                    This will not be visible to user
                  </div>
                </div>
              </div>
            </div>

            {/* Here begins the second part of the form in which the user fills the inventory info of the product */}

            <div
              className='addProductFormComponent productFormInventoryComponent'
              id='inventory'
            >
              <div className='inventoryTitle'>Inventory</div>
              <div className='inventoryInputs'>
                <div className='inputSKU inputSKUAdd'>
                  <div className='inputInventoryTitle'>
                    SKU <span>*</span>
                  </div>
                  <input disabled placeholder='SKU Number'></input>
                </div>
                <div className='inputQuantity inputQuantityAdd'>
                  <div className='inputInventoryTitle'>Quantity</div>
                  <input
                    value={stock}
                    onChange={(e) => {
                      setStock(e.target.value)
                    }}
                    placeholder='Quantity'
                  ></input>
                </div>
                <div className='inputQuantity inputQuantityAdd'>
                  <div className='inputInventoryTitle'>Unit</div>
                  <Select
                    options={listOfUnits}
                    placeholder='PIECE'
                    // styles={}
                    onChange={(e) => {
                      setUnit(e.value)
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  ></Select>
                </div>
              </div>
            </div>

            <div
              className='addProductFormComponent prodcutFormSEOComponent'
              id='seo'
            >
              <div className='seoFormComp seoTitle'>SEO</div>
              <div className=' seoFormComp metaTagTile'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex align-item-center'>
                  <div className='seoCompSubtitles baxa'>Meta Tag Title</div>
                  <div className="baxa" style={{ textAlign: 'right', marginLeft: '15px', color: '#A1A5B7', fontSize: '12px' }}>
                    {seoTitle.length}/{maxLength}
                  </div>
                </div>
                <button style={{ color: '#009ef7' }} className='no-underline hover:underline' onClick={handleGenerateClickforTitle}>{loadingGenforTitle ? 'Generating...' : 'Generate title through AI?'}</button>
              </div>
                <input placeholder='Meta tag name' value={seoTitle} onChange={(e) => {
                  handleChangeInputMetaTitle(e)
                }}></input>
                <div className='seoCompInfo'>
                  Set a meta tag title. Recommended to be simple and precise
                  keywords.
                </div>
              </div>
              <div className=' seoFormComp metaTagDescription'>
              <div className='d-flex justify-content-between align-items-center ' style={{ flexDirection: 'row' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='seoCompSubtitles'>Meta Tag Description</div>
                <div style={{ textAlign: 'right', marginLeft: '15px', color: '#A1A5B7', fontSize: '12px' }}>
                  {getPlainTextLength(seoDesc)}/{maxLengthDes}
                </div>
              </div>
              <button style={{ color: '#009ef7' }}  className='no-underline hover:underline' onClick={handleGenerateClickforDes}>{loadingGenforDesc ? 'Generating...' : 'Generate Description through AI?'}</button>
            </div>
                <div className=''>
                  <ReactQuill
                    theme='snow'
                    style={{
                      textAlign: 'left',
                      color: 'black',
                    }}
                    placeholder='Type your text here........'
                    value={seoDesc}
                    onChange={(e) => {
                      handleChangeInputMetaDes(e)
                    }}
                  ></ReactQuill>
                </div>
                <div className='seoCompInfo'>
                  Set a meta tag description to the product for increased SEO
                  ranking.
                </div>
              </div>
              <div className=' seoFormComp tags'>
                <div className='seoCompSubtitles tagsSubtitle'>
                  Tags <span style={{ color: 'red' }}>*</span>
                </div>
                <input
                  placeholder=''
                  style={{
                    background: '#f9f9f9',
                    border: 'none',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    paddingLeft: '10px',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    color: '#5e6278',
                    fontWeight: 500,
                  }}
                  defaultValue='Important, Urgent'
                ></input>
              </div>
              <div className=' seoFormComp metaTagKeywords'>
                <div className='seoCompSubtitles'>Meta Tag Keywords</div>
                <input placeholder=''></input>
                <div className='seoCompInfo'>
                  Set a list of keywords that the product is related to.
                  Separate the keywords by adding a comma{' '}
                  <span
                    style={{
                      background: '#f1f3f8',
                      paddingTop: '2px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      borderRadius: '3px',
                      paddingBottom: '5px',
                      color: 'purple',
                    }}
                  >
                    ,
                  </span>{' '}
                  between each keyword.
                </div>
              </div>
            </div>
            <div className='addProductFormComponent saveButtonComponent'>
              <div style={{ display: 'flex', gap: '20px' }}>
                <div className='cancelButton' onClick={()=>{
                  navigate('/products');
                }}>Cancel</div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <div className='submitButton p-2 rounded-lg text-white' onClick={handleSave} style={{background:'#009EF7'}}>
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Save Product'
                )}
              </div>
                  {(nameWarning || priceWarning || mrpAndPriceWarning) && (
                    <div
                      style={{
                        color: 'red',
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '10px',
                        padding: '',
                      }}
                    >
                      <span>
                        {' '}
                        *A few mandatory fields are empty or have an error.
                        <br /> Please check above.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      
        {/* <FormLoader></FormLoader>
     */}
    </>
  )
}

export default AddProductForm
